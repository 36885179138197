import Link from '@mui/material/Link';

export default function ForgotPasswordLink() {
  return (
    <Link
      underline="none"
      href="/forgot_password"
      style={{ textAlign: 'center', textTransform: 'uppercase' }}
    >
      Forgot Password?
    </Link>
  );
}
