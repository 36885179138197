import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { PropsWithChildren } from 'react';

type LoadingSkeletonProps = {
  isLoading: boolean;
} & SkeletonProps;

export default function LoadingSkeleton({
  width,
  height,
  variant,
  sx,
  isLoading,
  children,
}: PropsWithChildren<LoadingSkeletonProps>) {
  if (isLoading) {
    return <Skeleton width={width} height={height} variant={variant} sx={sx} />;
  }
  return <>{children}</>;
}
