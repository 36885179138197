/* eslint-disable @typescript-eslint/no-explicit-any */
import { OrderEndpoint } from '../common/endpoints';
import { Order, Product, ServerResponse, StaffOrder } from '../common/types';
import { getRequest, patchRequest, postRequest } from './requests';

type UpdateOrderStation = {
  orderId: number;
  newStationId: number;
  productCategories: string[];
};

type ChangeQueueOrderOrderItem = {
  id: number;
  queueOrder: number | null;
};

type ChangeQueueOrderOrder = {
  id: number;
  orderItems: ChangeQueueOrderOrderItem[];
};

type ChangeQueueOrders = {
  orderDtos: ChangeQueueOrderOrder[];
  productCategories: string[];
};

type StartStopFinishOrderOrderItem = {
  id: number;
  status: string;
};

type StartStopFinishOrder = {
  id: number;
  startTime: Date | null;
  finishTime: Date | null;
  paymentType: string | null;
  softDelete: boolean;
  orderItems: StartStopFinishOrderOrderItem[];
};

async function create(order: Order) {
  return await postRequest(OrderEndpoint, order);
}

async function getOrders(includeHistory: boolean = false) {
  const param = includeHistory ? '?includeHistory=true' : '';
  const result: ServerResponse<StaffOrder[]> = await getRequest(
    `${OrderEndpoint}${param}`,
  );
  return result;
}

async function startStopFinishOrder(payload: StartStopFinishOrder) {
  const result: ServerResponse<null> = await patchRequest(
    `${OrderEndpoint}/${payload.id}/startstopfinish`,
    payload,
  );
  return result;
}

async function changeQueueOrders(payload: ChangeQueueOrders) {
  const result: ServerResponse<null> = await patchRequest(
    `${OrderEndpoint}/queues`,
    payload,
  );
  return result;
}

async function updateStation(payload: UpdateOrderStation) {
  const result: ServerResponse<null> = await patchRequest(
    `${OrderEndpoint}/${payload.orderId}/station`,
    payload,
  );
  return result;
}

export function convertOrderProps(
  orderDTO: any,
  products: Product[] = [],
): Order {
  return {
    id: orderDTO.id,
    customerId: orderDTO.customerId,
    customerName: orderDTO.customerName,
    finishTime: orderDTO.finishTime ? new Date(orderDTO.finishTime) : null,
    startTime: orderDTO.startTime ? new Date(orderDTO.startTime) : null,
    isPaid: orderDTO.isPaid,
    paymentType: orderDTO.paymentType,
    siteId: orderDTO.siteId,
    requestedPickupTime: orderDTO.requestedPickupTime
      ? new Date(orderDTO.requestedPickupTime)
      : null,
    orderItems: orderDTO.orderItems.map((orderItemDTO: any) => {
      const product = products.find(
        (product) => product.id === orderItemDTO.productId,
      );
      const productSize = product?.productSizes.find(
        (productSize) => productSize.id === orderItemDTO.productSizeId,
      );
      return {
        id: orderItemDTO.id,
        notes: orderItemDTO.notes,
        orderId: orderItemDTO.orderId,
        queueOrder: orderItemDTO.queueOrder || null,
        productName: orderItemDTO.productName,
        productCategoryType: orderItemDTO.productCategoryType,
        productSizeId: orderItemDTO.productSizeId,
        productSizeName: productSize?.sizeName ?? '',
        productId: orderItemDTO.productId,
        stationId: orderItemDTO.stationId,
        status: orderItemDTO.status,
        selectedVariants: orderItemDTO.selectedVariants.map((variant: any) => {
          const baseVariant = product?.productVariants.find(
            (productVariant) => productVariant.id === variant.id,
          );
          const sizeVolume = baseVariant?.productVariantSizeVolumes?.find(
            (f) => f.productSizeId === orderItemDTO.productSizeId,
          );
          return {
            ...variant,
            ...baseVariant,
            priceAdjustment: sizeVolume?.priceAdjustment ?? 0,
          };
        }),
      };
    }),
  };
}

export default {
  create,
  getOrders,
  startStopFinishOrder,
  changeQueueOrders,
  updateStation,
  convertOrderProps,
};
