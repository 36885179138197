import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Site } from '../../../../common/types';
import SiteSelectionModal from './SiteSelectionModal';

type SiteSelectorProps = {
  onSiteSelected: (selected: Site | null) => void;
  sites: Site[];
  selectedSiteId: number;
};

export default function SiteSelector({
  sites,
  onSiteSelected,
  selectedSiteId = 0,
}: SiteSelectorProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const theme = useTheme();

  let selectedSite = null;
  if (sites.length === 1) {
    selectedSite = sites[0];
    onSiteSelected(selectedSite);
  } else if (sites.length > 1 && selectedSiteId) {
    selectedSite = sites.find((f) => f.id === selectedSiteId);
  }

  const closeModal = () => setOpenModal(false);
  const handleSiteSelected = (site: Site | null) => {
    onSiteSelected(site);
    closeModal();
  };

  return (
    <>
      <Button sx={{ padding: 1 }} onClick={() => setOpenModal(true)}>
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.common.babyBlue }}
        >
          {selectedSite?.name || 'Select store'}
        </Typography>
        <LocationOnOutlined
          fontSize="small"
          sx={{ color: theme.palette.common.babyBlue }}
        />
      </Button>

      {openModal && (
        <SiteSelectionModal
          {...{
            sites,
            selectedSiteId,
            close: closeModal,
            onSiteSelected: handleSiteSelected,
          }}
        />
      )}
    </>
  );
}
