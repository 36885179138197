import Decimal from 'decimal.js';
import { GST } from './enums';
import { OrderItem, Product, ProductSize, ProductVariant } from './types';

export function calculatePriceDifferenceWithSign(
  priceAdjustment: number | string,
): string {
  const difference = new Decimal(priceAdjustment).toFixed(2);
  const sign = new Decimal(difference).gte(0) ? '+' : '-';
  return `${sign}$${new Decimal(difference).abs().toFixed(2)}`;
}

export const productBasePrice = (product: Product): Decimal => {
  const activeDefault = product.productSizes.find(
    (f) => f.isActive && f.isDefault,
  );
  const fallback = product.productSizes.find((f) => f.isActive);
  return new Decimal(activeDefault?.basePrice || fallback?.basePrice || 0);
};

export const productVariantPriceAdjustment = (
  productVariant: ProductVariant,
  productSize: ProductSize,
): Decimal => {
  const sizeVolume = productVariant.productVariantSizeVolumes.find(
    (f) => f.productSizeId === productSize.id,
  );
  return new Decimal(sizeVolume?.priceAdjustment || 0);
};

export function calculateFinalPrice(item: OrderItem): Decimal {
  const productSize = item.sizeOptions.find((f) => f.id === item.productSizeId);
  if (!productSize) {
    return new Decimal(0);
  }
  const totalAdjustment = item.selectedVariants.reduce((acc, variant) => {
    const pvsv = variant.productVariantSizeVolumes.find(
      (f) => f.productSizeId === productSize.id,
    );
    if (!pvsv) return acc;
    return acc.plus(pvsv.priceAdjustment);
  }, new Decimal(0));
  return new Decimal(productSize.basePrice).plus(totalAdjustment);
}

export function calculateTotalPrice(orderItems: OrderItem[]): Decimal {
  return orderItems.reduce((acc, item) => {
    const productSize = item.sizeOptions.find(
      (f) => f.id === item.productSizeId,
    );
    if (!productSize) {
      return acc;
    }
    const itemPrice = calculateFinalPrice(item);
    return acc.plus(itemPrice);
  }, new Decimal(0));
}

export function displayPrice(price: Decimal | number | string): string {
  return `$${new Decimal(price).toFixed(2)}`;
}

export function calculateGSTBreakdown(orderItems: OrderItem[]): {
  total: Decimal;
  gst: Decimal;
  subtotal: Decimal;
} {
  const total = calculateTotalPrice(orderItems);
  const subtotal = total.times(new Decimal(1 - GST.TenPercent));
  const gst = total.minus(subtotal);
  return { total, subtotal, gst };
}

export const formatCurrency = (value: string | number | Decimal): string => {
  return new Decimal(value).toFixed(2);
};

export const calculateProductInternalCost = (
  product: Product,
  productSizeId: number,
): Decimal => {
  const coreVariants = product.productVariants.filter((f) => f.isCore);

  let total = new Decimal(0);
  for (const cv of coreVariants) {
    total = total.plus(calculateProductVariantInternalCost(cv, productSizeId));
  }
  return total;
};

export const calculateProductVariantInternalCost = (
  productVariant: ProductVariant,
  productSizeId: number,
): Decimal => {
  const pvsv = productVariant.productVariantSizeVolumes.find(
    (f) => f.productSizeId === productSizeId,
  );
  if (pvsv && productVariant.component) {
    return new Decimal(pvsv.volume).times(
      new Decimal(productVariant.component.costPerUnit),
    );
  }
  return new Decimal(0);
};
