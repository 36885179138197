import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import StyledSwitch from '../../../../components/switch/StyledSwitch';

interface PosHeaderProps {
  searchCustomer: string;
  setSearchCustomer: (value: string) => void;
  hidePaid: boolean;
  setHidePaid: (value: boolean) => void;
}

export default function PosHeader({
  searchCustomer,
  setSearchCustomer,
  hidePaid,
  setHidePaid,
}: PosHeaderProps) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 1,
        paddingRight: 2,
      }}
    >
      <Stack sx={{ flexDirection: 'row' }}>
        <PointOfSaleIcon fontSize="large" />
        <Typography variant="h2" fontWeight="600" sx={{ paddingLeft: 1 }}>
          Point of Sale
        </Typography>
      </Stack>
      <Stack sx={{ flexDirection: 'row' }}>
        <TextField
          size="small"
          sx={{ paddingRight: 5, width: 350 }}
          label="Search Customer Name"
          value={searchCustomer}
          onChange={(e) => setSearchCustomer(e.target.value)}
        />
        <StyledSwitch
          value={hidePaid}
          onChange={setHidePaid}
          labelColor={theme.palette.common.deepblue}
          label="Hide Paid"
        />
      </Stack>
    </Stack>
  );
}
