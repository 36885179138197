import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SxProps } from '@mui/system';
import { PropsWithChildren } from 'react';
import { TabletSize } from '../../../../common/enums';

interface StyledTableContainerProps {
  maxWidth?: number | string;
  additionalSx?: SxProps<Theme>;
}

export default function StyledTableContainer({
  children,
  maxWidth = 650,
  additionalSx = {},
}: PropsWithChildren<StyledTableContainerProps>) {
  const isSmallScreen = useMediaQuery(TabletSize.Small);
  const isMediumScreen = useMediaQuery(TabletSize.Medium);
  const isLargeScreen = useMediaQuery(TabletSize.Large);

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: 4,
        maxWidth: maxWidth,
        margin: 'auto',
        maxHeight: isSmallScreen
          ? '50vh'
          : isMediumScreen
            ? '55vh'
            : isLargeScreen
              ? '63vh'
              : '80vh',
        overflow: 'auto',
        ...additionalSx,
      }}
    >
      {children}
    </TableContainer>
  );
}
