import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import AdminPageFilter from '../../components/AdminPageFilter';

interface FilterSectionProps {
  filterText: string;
  setFilterText: (value: string) => void;
  selectedCategory: string;
  setSelectedCategory: (value: string) => void;
  showAllProducts: boolean;
  setShowAllProducts: (value: boolean) => void;
  getUniqueProductCategories: () => string[];
}

export function PopularProductFilter({
  filterText,
  setFilterText,
  selectedCategory,
  setSelectedCategory,
  showAllProducts,
  setShowAllProducts,
  getUniqueProductCategories,
}: FilterSectionProps) {
  return (
    <AdminPageFilter filterText={filterText} setFilterText={setFilterText}>
      <TextField
        size="small"
        select
        label="Filter by Category"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
        variant="outlined"
        sx={{ minWidth: 200, paddingRight: 2 }}
      >
        {getUniqueProductCategories().map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </TextField>
      <FormControlLabel
        control={
          <Switch
            checked={showAllProducts}
            onChange={(e) => setShowAllProducts(e.target.checked)}
          />
        }
        label="Show all products"
      />
    </AdminPageFilter>
  );
}
