export const USER_DATA_KEY = 'userData';
export const JWT_KEY = 'jwtToken';
export const SELECTED_SITE_KEY = 'SELECTED_SITE';

export default class LocalStorageSvc {
  public static save = <T>(key: string, value: T): void => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  public static load = <T>(key: string): T | null => {
    const json = localStorage.getItem(key);
    if (!json) {
      return null;
    }
    return JSON.parse(json) as T;
  };
  public static remove = (key: string): void => {
    localStorage.removeItem(key);
  };
}
