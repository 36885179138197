import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { formatCurrency } from '../../../common/moneyFunctions';

type SizePrice = {
  sizeName: string;
  cost: number;
};

type Props = {
  sizePrices: SizePrice[];
};

export default function SizePricesTableCell({ sizePrices = [] }: Props) {
  return (
    <TableCell sx={{ height: '100%' }}>
      <Stack sx={{ justifyContent: 'space-between', height: '100%' }}>
        {sizePrices
          .sort((a, b) => b.sizeName.localeCompare(a.sizeName))
          .map((sv) => (
            <Typography
              key={sv.sizeName}
              variant="caption"
              sx={{ padding: 0, lineHeight: 1.5 }}
            >
              {sv.sizeName}:&nbsp;${formatCurrency(sv.cost)}
            </Typography>
          ))}
      </Stack>
    </TableCell>
  );
}
