import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import React from 'react';

type ProductSearchProps = {
  handleClose: () => void;
  onSearchChanged: (value: string) => void;
  searchText: string;
};

export default function ProductSearch({
  handleClose,
  onSearchChanged,
  searchText,
}: ProductSearchProps) {
  const theme = useTheme();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChanged(event.target.value ?? '');
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="search-dialog-title"
    >
      <DialogContent>
        <Stack
          flexDirection="row"
          flexGrow={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: theme.palette.common.white,
          }}
        >
          <Search fontSize="small" sx={{ marginRight: 1.5 }} />

          <InputBase
            sx={{ flex: 1 }}
            placeholder="Enter search terms"
            inputProps={{ 'aria-labelledby': 'search dialog' }}
            autoFocus={true}
            value={searchText}
            onChange={onChange}
          />
          <IconButton
            onClick={handleClose}
            edge="end"
            aria-label="close search"
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
