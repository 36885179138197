import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import useSelectedSite from '../../../hooks/useSelectedSite';
import CustomerNavbar from '../components/CustomerNavbar';

export default function CustomerLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { startPolling, stopPolling } = useSelectedSite((state) => ({
    startPolling: state.startPolling,
    stopPolling: state.stopPolling,
  }));

  useEffect(() => {
    startPolling();
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return (
    <Stack sx={{ flex: 1, height: '100vh' }}>
      <CustomerNavbar />
      <Stack
        sx={{
          flexGrow: 1,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}
