import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns/format';
import { CustomerInfo } from '../../../../common/types';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';

interface TableProps {
  isLoading: boolean;
  paginatedCustomers: CustomerInfo[];
}

export default function CustomerManagementTable({
  isLoading,
  paginatedCustomers,
}: TableProps) {
  return (
    <Table sx={{ maxWidth: '100%', overflowX: 'auto' }} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Contact Number</TableCell>
          <TableCell>Sign-Up Date</TableCell>
          <TableCell>Number of Orders</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <LoadingSpinner isLoading={isLoading} isTable colSpan={5}>
          {paginatedCustomers.map((customer) => {
            const createdDate = customer.createdAt
              ? format(customer.createdAt, 'dd/MM/yy')
              : '';
            return (
              <TableRow
                key={customer.email}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <TableCell>
                  {customer.firstName} {customer.lastName}
                </TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.phoneNumber}</TableCell>
                <TableCell>{createdDate}</TableCell>
                <TableCell>{customer.orderCount ?? 0}</TableCell>
              </TableRow>
            );
          })}
        </LoadingSpinner>
      </TableBody>
    </Table>
  );
}
