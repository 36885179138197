import { TpgeComponentEndpoint } from '../common/endpoints';
import { ServerResponse, TpgeComponent } from '../common/types';
import { getRequest, postRequest, putRequest } from './requests';

export async function getComponents(includeDeleted = false) {
  const queryParams = includeDeleted ? '?includeDeleted=true' : '';
  const result: ServerResponse<TpgeComponent[]> = await getRequest(
    `${TpgeComponentEndpoint}${queryParams}`,
  );
  return result;
}
export async function updateComponent(obj: TpgeComponent) {
  const result: ServerResponse<TpgeComponent> = await putRequest(
    `${TpgeComponentEndpoint}/${obj.id}`,
    obj,
  );
  return result;
}
export async function createComponent(obj: TpgeComponent) {
  const result: ServerResponse<TpgeComponent> = await postRequest(
    TpgeComponentEndpoint,
    obj,
  );
  return result;
}

export default { getComponents, updateComponent, createComponent };
