import { createBrowserRouter } from 'react-router-dom';
import StaffLayout from './pages/staff/layout/StaffLayout';
import StaffHomePage from './pages/staff/home/StaffHomePage';
import StaffSettings from './pages/staff/settings/StaffSettings';
import OrderPage from './pages/staff/order/OrderPage';
import PosPage from './pages/staff/pos/PosPage';
import WalkUpPage from './pages/staff/walkup/WalkUpPage';
import CustomerLayout from './pages/customer/layout/CustomerLayout';
import CustomerHomePage from './pages/customer/home/CustomerHomePage';
import CustomerCheckoutPage from './pages/customer/checkout/CustomerCheckoutPage';
import CustomerProfilePage from './pages/customer/profile/CutomerProfilePage';
import ReportsPage from './pages/Admin/reports/ReportsPage.tsx';
import CustomerManagementPage from './pages/Admin/customers/CustomerManagementPage.tsx';
import AccountsPage from './pages/Admin/accounts/AccountsPage.tsx';
import PopularProductsPage from './pages/Admin/popular/PopularProductsPage.tsx';
import ProductsPage from './pages/Admin/products/ProductPage.tsx';
import VariationPage from './pages/Admin/products/variations/VariationPage.tsx';
import PasswordResetPage from './pages/passwordReset/PasswordResetPage.tsx';
import OrderConfirmed from './pages/customer/orderConfirmed/OrderConfirmed.tsx';
import ComponentsPage from './pages/Admin/componentManagement/ComponentsPage.tsx';
import ComponentTypesPage from './pages/Admin/componentTypes/ComponentTypesPage.tsx';

export const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        path: '',
        element: (
          <CustomerLayout>
            <CustomerHomePage />
          </CustomerLayout>
        ),
      },
      {
        path: 'checkout',
        element: (
          <CustomerLayout>
            <CustomerCheckoutPage />
          </CustomerLayout>
        ),
      },
      {
        path: 'profile',
        element: (
          <CustomerLayout>
            <CustomerProfilePage />
          </CustomerLayout>
        ),
      },
      {
        path: 'forgot_password',
        element: (
          <CustomerLayout>
            <PasswordResetPage mode="forgot" />
          </CustomerLayout>
        ),
      },
      {
        path: 'reset_password',
        element: (
          <CustomerLayout>
            <PasswordResetPage mode="reset" />
          </CustomerLayout>
        ),
      },
      {
        path: 'orderconfirmed/:orderId',
        element: (
          <CustomerLayout>
            <OrderConfirmed />
          </CustomerLayout>
        ),
      },
    ],
  },
  {
    path: '/admin',
    children: [
      {
        path: 'reports',
        element: (
          <StaffLayout>
            <ReportsPage />
          </StaffLayout>
        ),
      },
      {
        path: 'products',
        element: (
          <StaffLayout>
            <ProductsPage />
          </StaffLayout>
        ),
      },
      {
        path: '/admin/products/:productId',
        element: (
          <StaffLayout>
            <VariationPage />
          </StaffLayout>
        ),
      },
      {
        path: 'components',
        element: (
          <StaffLayout>
            <ComponentsPage />
          </StaffLayout>
        ),
      },
      {
        path: 'componenttypes',
        element: (
          <StaffLayout>
            <ComponentTypesPage />
          </StaffLayout>
        ),
      },
      {
        path: 'popular',
        element: (
          <StaffLayout>
            <PopularProductsPage />
          </StaffLayout>
        ),
      },
      {
        path: 'customers',
        element: (
          <StaffLayout>
            <CustomerManagementPage />
          </StaffLayout>
        ),
      },
      {
        path: 'accounts',
        element: (
          <StaffLayout>
            <AccountsPage />
          </StaffLayout>
        ),
      },
    ],
  },
  {
    path: '/staff',
    children: [
      {
        path: 'home',
        element: (
          <StaffLayout>
            <StaffHomePage />
          </StaffLayout>
        ),
      },
      {
        path: 'order',
        element: (
          <StaffLayout>
            <OrderPage />
          </StaffLayout>
        ),
      },
      {
        path: 'pos',
        element: (
          <StaffLayout>
            <PosPage />
          </StaffLayout>
        ),
      },
      {
        path: 'settings',
        element: (
          <StaffLayout>
            <StaffSettings />
          </StaffLayout>
        ),
      },
      {
        path: 'walkup',
        element: (
          <StaffLayout>
            <WalkUpPage />
          </StaffLayout>
        ),
      },
    ],
  },
]);
