import GradeIcon from '@mui/icons-material/Grade';
import { useState } from 'react';
import popularProductsApi from '../../../api/popularProduct.tsx';
import { allOption } from '../../../common/constants.tsx';
import { hasAdminRole } from '../../../common/roleFunctions.tsx';
import { isStaff } from '../../../common/typeAssertionFunctions.tsx';
import {
  PopularProduct,
  Product,
  Staff,
  TPGEMediaQuery,
} from '../../../common/types';
import usePopularProducts from '../../../datastore/usePopularProducts.tsx';
import useProducts from '../../../datastore/useProducts.tsx';
import useAlert from '../../../hooks/useAlert.tsx';
import useAPI from '../../../hooks/useAPI.tsx';
import useTPGEMediaQuery from '../../../hooks/useTPGEMediaQuery.tsx';
import { AdminHeader } from '../components/AdminHeader.tsx';
import AdminPageContent from '../components/AdminPageContent.tsx';
import AdminPagination from '../components/AdminPagination.tsx';
import { PopularProductFilter } from './components/PopularProductFilter.tsx';
import { PopularProductTable } from './components/PopularProductTable.tsx';

const ProductsPerPage: { [key in TPGEMediaQuery]: number } = {
  xlarge: 12,
  large: 7,
  medium: 5,
  small: 4,
};

const addPopularProduct = (
  product: Product,
  popularProducts: PopularProduct[],
  newDisplayOrder: number,
  currentUser: Staff | null,
): PopularProduct[] => {
  const updatedData = [...popularProducts];
  const foundProductIndex = updatedData.findIndex(
    (p) => p.productId === product.id,
  );

  if (foundProductIndex !== -1) {
    updatedData[foundProductIndex] = {
      ...updatedData[foundProductIndex],
      isActive: true,
      displayOrder: newDisplayOrder,
    };
  } else {
    const newPopularProduct: PopularProduct = {
      id: 0,
      displayOrder: newDisplayOrder,
      productId: product.id,
      siteId: isStaff(currentUser) ? currentUser.siteId : 0,
      product: product,
      isActive: true,
    };
    updatedData.push(newPopularProduct);
  }
  return updatedData;
};

const changeOrder = (
  popularProducts: PopularProduct[],
  productId: number,
  newOrder: number,
): PopularProduct[] => {
  const activeProducts = popularProducts.filter((p) => p.isActive);

  const finalNewOrder = Math.max(1, Math.min(newOrder, activeProducts.length));

  const productToMove = activeProducts.find((p) => p.productId === productId);
  if (!productToMove) {
    return [];
  }

  const oldOrder = productToMove.displayOrder;

  const updatedProducts = activeProducts.map((p) => {
    if (p.productId === productId) {
      return { ...p, displayOrder: finalNewOrder };
    } else if (
      oldOrder < finalNewOrder &&
      p.displayOrder > oldOrder &&
      p.displayOrder <= finalNewOrder
    ) {
      return { ...p, displayOrder: p.displayOrder - 1 };
    } else if (
      oldOrder > finalNewOrder &&
      p.displayOrder >= finalNewOrder &&
      p.displayOrder < oldOrder
    ) {
      return { ...p, displayOrder: p.displayOrder + 1 };
    }
    return p;
  });

  const sortedProducts = updatedProducts
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map((p, index) => ({ ...p, displayOrder: index + 1 }));

  const updated = [
    ...sortedProducts,
    ...popularProducts.filter((p) => !p.isActive),
  ];
  return updated;
};

const removePopularProduct = (
  product: Product,
  popularProducts: PopularProduct[],
): PopularProduct[] => {
  const updatedData = popularProducts.map((p) =>
    p.productId === product.id ? { ...p, isActive: false, displayOrder: 0 } : p,
  );
  const inactiveData = updatedData.filter((p) => !p.isActive);

  const activeProducts = updatedData
    .filter((p) => p.isActive)
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map((p, index) => ({ ...p, displayOrder: index + 1 }));
  const updated = [...activeProducts, ...inactiveData];
  console.log('removePopularProduct', updated);
  return updated;
};

export default function PopularProductsPage() {
  const { currentUser } = useAPI();
  const { products = [], loading: productsLoading } = useProducts();
  const {
    popularProducts = [],
    loading: popularProductsLoading,
    fetchPopularProducts,
  } = usePopularProducts();
  const { showAlert } = useAlert();
  const mediaSize = useTPGEMediaQuery();
  const productsPerPage = ProductsPerPage[mediaSize];
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [filterText, setFilterText] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);

  const isLoading = popularProductsLoading || productsLoading;

  if (!hasAdminRole(currentUser)) return null;

  function handleAddProduct(product: Product) {
    const activeProducts = popularProducts.filter((f) => f.isActive);
    const newDisplayOrder = activeProducts.length + 1;
    if (newDisplayOrder > 5) {
      showAlert(
        "You can't have more than 5 active popular products.",
        'warning',
      );
      return;
    }

    const updatedData = addPopularProduct(
      product,
      popularProducts,
      newDisplayOrder,
      currentUser as Staff | null,
    );
    handleSaveChanges(updatedData);
  }

  function handleOrderChange(productId: number, newOrder: number) {
    if (!popularProducts.length) {
      return;
    }
    const updated = changeOrder(popularProducts, productId, newOrder);
    if (updated.length) {
      handleSaveChanges(updated);
    }
  }

  function getUniqueProductCategories() {
    const categories =
      products.map((product) => product.productCategory.name) || [];
    return [allOption, ...new Set(categories)];
  }

  function handleRemovePopularProduct(product: Product) {
    if (!popularProducts.length) {
      return;
    }

    const updatedData = removePopularProduct(product, popularProducts);
    handleSaveChanges(updatedData);
  }

  async function handleSaveChanges(data: PopularProduct[] = []) {
    const updatedPopularProducts =
      data
        .filter((p) => p.isActive)
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((p, index) => ({
          ...p,
          displayOrder: index + 1,
          product: undefined,
        })) ?? [];

    if (!updatedPopularProducts.length) {
      showAlert('Error saving changes, please refresh and try again.', 'error');
      return;
    }
    const postResult = await popularProductsApi.createOrUpdate(
      updatedPopularProducts,
    );

    if (postResult.status !== 200) {
      showAlert('Error saving changes, please try again.', 'error');
      return;
    }
    fetchPopularProducts();
    showAlert('Changes saved successfully.', 'success');
  }

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const filteredProducts =
    products.filter(
      (product) =>
        (filterText
          ? product.name.toLowerCase().includes(filterText.toLowerCase())
          : true) &&
        (selectedCategory && selectedCategory !== allOption
          ? product.productCategory.name === selectedCategory
          : true) &&
        (showAllProducts ||
          popularProducts.some(
            (p) => p.productId === product.id && p.isActive,
          )),
    ) || [];

  const totalNumberOfPages = Math.ceil(
    filteredProducts.length / productsPerPage,
  );

  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage,
  );

  return (
    <>
      <AdminHeader
        title="Popular Products"
        description="Manage your popular products, search add or remove products."
        icon={<GradeIcon fontSize="large" sx={{ color: 'white' }} />}
      />
      <AdminPageContent>
        <PopularProductFilter
          filterText={filterText}
          setFilterText={setFilterText}
          selectedCategory={selectedCategory}
          setSelectedCategory={(category) => {
            setSelectedCategory(category);
            setCurrentPage(1);
          }}
          showAllProducts={showAllProducts}
          setShowAllProducts={setShowAllProducts}
          getUniqueProductCategories={getUniqueProductCategories}
        />
        <PopularProductTable
          isLoading={isLoading}
          paginatedProducts={paginatedProducts}
          popularProductsData={popularProducts}
          showAllProducts={showAllProducts}
          handleOrderChange={handleOrderChange}
          addProduct={handleAddProduct}
          removePopularProduct={handleRemovePopularProduct}
        />
        <AdminPagination
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </AdminPageContent>
    </>
  );
}
