import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { PropsWithChildren } from 'react';

interface LoadingSpinnerProps {
  isLoading: boolean;
  isTable?: boolean;
  colSpan?: number;
}

export default function LoadingSpinner({
  isLoading,
  isTable = false,
  colSpan = 1,
  children,
}: PropsWithChildren<LoadingSpinnerProps>) {
  if (!isLoading) {
    return <>{children}</>;
  }
  if (isTable) {
    return (
      <TableRow>
        <TableCell colSpan={colSpan} sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </TableCell>
      </TableRow>
    );
  }
  return (
    <Stack
      sx={{
        padding: 4,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Stack>
  );
}
