/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { endOfWeek } from 'date-fns/endOfWeek';
import { startOfWeek } from 'date-fns/startOfWeek';
import { useEffect, useState } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
} from 'recharts';
import reportsApi from '../../../../api/report';
import { ReportView } from '../../../../common/enums.tsx';
import { SaleByPaymentType } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import useAlert from '../../../../hooks/useAlert';
import { getColor } from '../functions/getColor.ts';
import ReportHeader from './ReportHeader.tsx';
import StyledTableContainer from './StyledTableContainer.tsx';

export default function SalesByPaymentTypeReport() {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [paymentData, setPaymentData] = useState<SaleByPaymentType[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(() =>
    startOfWeek(new Date(), { weekStartsOn: 0 }),
  );
  const [endDate, setEndDate] = useState<Date | null>(() =>
    endOfWeek(new Date(), { weekStartsOn: 0 }),
  );
  const [view, setView] = useState<ReportView.Chart | ReportView.Table>(
    ReportView.Chart,
  );
  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  async function fetchPaymentTypeData() {
    setIsLoading(true);
    const result = await reportsApi.getSalesByPaymentType(startDate, endDate);
    if (result.data && result.status === 200) {
      setPaymentData(result.data);
    } else {
      showAlert('Failed to get report, please refresh and try again.', 'error');
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchPaymentTypeData();
  }, []);

  function handleViewChange(
    _: React.MouseEvent<HTMLElement>,
    newView: ReportView.Chart | ReportView.Table | null,
  ) {
    if (newView !== null) {
      setView(newView);
    }
  }

  const totalAmount = paymentData.reduce(
    (sum, row) => sum + row.totalAmount,
    0,
  );
  return (
    <>
      <ReportHeader
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        view={view}
        handleViewChange={handleViewChange}
        fetchData={fetchPaymentTypeData}
      />
      <LoadingSpinner isLoading={isLoading}>
        <>
          {view === ReportView.Chart ? (
            <Box sx={{ width: '100%', height: '55vh', minHeight: '400px' }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={paymentData}
                    dataKey="totalAmount"
                    nameKey="paymentType"
                    cx="50%"
                    cy="50%"
                    fill="#8884d8"
                    activeIndex={activeIndex}
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(undefined)}
                    activeShape={(props: any) => {
                      const RADIAN = Math.PI / 180;
                      const {
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        startAngle,
                        endAngle,
                        fill,
                        payload,
                        percent,
                        value,
                      } = props;
                      const sin = Math.sin(-RADIAN * midAngle);
                      const cos = Math.cos(-RADIAN * midAngle);
                      const sx = cx + (outerRadius + 10) * cos;
                      const sy = cy + (outerRadius + 10) * sin;
                      const mx = cx + (outerRadius + 30) * cos;
                      const my = cy + (outerRadius + 30) * sin;
                      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                      const ey = my;
                      const textAnchor = cos >= 0 ? 'start' : 'end';

                      return (
                        <g>
                          <Sector
                            cx={cx}
                            cy={cy}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            fill={fill}
                          />
                          <Sector
                            cx={cx}
                            cy={cy}
                            startAngle={startAngle}
                            endAngle={endAngle}
                            innerRadius={outerRadius + 6}
                            outerRadius={outerRadius + 10}
                            fill={fill}
                          />
                          <path
                            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                            stroke={fill}
                            fill="none"
                          />
                          <circle
                            cx={ex}
                            cy={ey}
                            r={2}
                            fill={fill}
                            stroke="none"
                          />
                          <text
                            x={ex + (cos >= 0 ? 1 : -1) * 12}
                            y={ey}
                            textAnchor={textAnchor}
                            fill="#333"
                          >{`${payload.paymentType}`}</text>
                          <text
                            x={ex + (cos >= 0 ? 1 : -1) * 12}
                            y={ey}
                            dy={18}
                            textAnchor={textAnchor}
                            fill="#999"
                          >
                            {`$${value} (${(percent * 100).toFixed(2)}%)`}
                          </text>
                        </g>
                      );
                    }}
                  >
                    {paymentData.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={getColor(index)}
                        stroke="none"
                      />
                    ))}
                  </Pie>
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          ) : (
            <StyledTableContainer>
              <Table size="medium" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Payment Type</TableCell>
                    <TableCell>Order Count</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentData.map((row) => (
                    <TableRow key={row.paymentType}>
                      <TableCell component="th" scope="row">
                        {row.paymentType}
                      </TableCell>
                      <TableCell>{row.orderCount}</TableCell>

                      <TableCell>${row.totalAmount}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Total Sales
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      ${totalAmount.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </>
      </LoadingSpinner>
    </>
  );
}
