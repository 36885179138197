import { StationEndpoint } from '../common/endpoints';
import { ServerResponse, Station } from '../common/types';
import { getRequest } from './requests';

export async function getAll(amountToTake?: number) {
  const suffix = amountToTake ? `?take=${amountToTake}` : '';
  const result: ServerResponse<Station[]> = await getRequest(
    `${StationEndpoint}${suffix}`,
  );
  return result;
}

export default {
  getAll,
};
