import {
  MutateProduct,
  Product,
  ProductSize,
  Size,
} from '../../../../common/types';

export const mapProductToMutateProduct = (
  product: Product,
  sizes: Size[],
): MutateProduct => {
  const productSizes: ProductSize[] = product.productSizes.map((m) => ({
    id: m.id,
    productId: m.productId,
    basePrice: m.basePrice,
    isActive: m.isActive,
    isDefault: m.isDefault,
    sizeId: m.sizeId,
    sizeName: m.sizeName,
  }));
  for (const size of sizes) {
    if (!productSizes.find((f) => f.sizeId === size.id)) {
      productSizes.push({
        id: 0,
        productId: product.id,
        basePrice: 0,
        isActive: false,
        isDefault: false,
        sizeId: size.id,
        sizeName: size.name,
      });
    }
  }
  const out: MutateProduct = {
    id: product.id,
    description: product.description,
    isActive: product.isActive,
    name: product.name,
    productCategoryId: product.productCategoryId,
    siteId: product.siteId ?? 1,
    productSizes: productSizes.sort((a, b) =>
      b.sizeName.localeCompare(a.sizeName),
    ),
  };
  return out;
};
