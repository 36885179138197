import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

type TotalBreakdownProps = {
  title: string;
  subtitle?: string;
  amount: string;
  isTotal?: boolean;
};

export default function TotalBreakdown({
  title,
  subtitle = '',
  amount,
  isTotal = false,
}: TotalBreakdownProps) {
  return (
    <ListItem
      divider
      sx={{
        paddingX: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant={isTotal ? 'h6' : 'body1'} fontWeight="normal">
        {title}
        {subtitle && (
          <Typography variant="caption" component="span">
            {subtitle}
          </Typography>
        )}
      </Typography>
      <Typography variant={isTotal ? 'h6' : 'body1'} fontWeight="bold">
        {amount}
      </Typography>
    </ListItem>
  );
}
