import { create } from 'zustand';
import ordersApi from '../api/order';
import { StaffOrder } from '../common/types';

interface PollingOptions {
  initialInterval: number;
  maxInterval: number;
}

type OrdersStore = {
  hasLoaded: boolean;
  orders: StaffOrder[];
  loading: boolean;
  error: unknown | null;
  fetchOrders: () => void;
  startPolling: (options?: PollingOptions) => void;
  stopPolling: () => void;
};

const defaultPollingOptions: PollingOptions = {
  initialInterval: 2000,
  maxInterval: 15000,
};

let _ordersInterval: NodeJS.Timeout | null = null;

const useOrders = create<OrdersStore>((set, get) => ({
  hasLoaded: false,
  orders: [],
  loading: false,
  error: null,
  fetchOrders: async () => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await ordersApi.getOrders();
      set({ orders: result.data ?? [], loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
  startPolling: (options = defaultPollingOptions) => {
    const { initialInterval, maxInterval } = options;
    const pollingInterval =
      initialInterval > maxInterval ? maxInterval : initialInterval;
    get().fetchOrders();
    if (_ordersInterval) {
      clearInterval(_ordersInterval);
    }
    _ordersInterval = setInterval(() => {
      get().fetchOrders();
    }, pollingInterval);
  },
  stopPolling: () => {
    if (_ordersInterval) {
      clearInterval(_ordersInterval);
    }
  },
}));

export default useOrders;

if (!useOrders.getState().hasLoaded) {
  useOrders.getState().fetchOrders();
}
