import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FC, PropsWithChildren } from 'react';

const AdminDialogTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <DialogTitle>
      <Typography
        fontWeight="bold"
        fontSize="1.5rem"
        sx={{ paddingBottom: '0.5em' }}
      >
        {children}
      </Typography>
      <Divider />
    </DialogTitle>
  );
};

export default AdminDialogTitle;
