import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoFull from '../../../assets/logo_full.png';
import build from '../../../common/buildEnv';
import useAlert from '../../../hooks/useAlert';
import useCustomerAuth from '../../../hooks/useCustomerAuth';
import CustomerLoginComponent from './CustomerLoginComponent';

const { version, env } = build;

const LoginDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerNavbar() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { showAlert } = useAlert();
  const { currentCustomer, logout, showAccountModal, setShowAccountModal } =
    useCustomerAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const profileNav = () => {
    navigate('/profile');
    handleMenuClose();
  };
  const menuNav = () => {
    navigate('/');
    handleMenuClose();
  };

  const handleLogout = () => {
    logout();
    showAlert('You are logged out', 'info');
    handleMenuClose();
  };

  const handleLoginClick = () => {
    setShowAccountModal('login');
    handleMenuClose();
  };

  const handleLoginClose = () => {
    setShowAccountModal(null);
  };

  return (
    <Stack sx={{ position: 'sticky', top: 0, zIndex: 10 }}>
      <Card raised sx={{ backgroundColor: theme.palette.common.white }}>
        <Stack
          flexDirection="row"
          sx={{
            paddingY: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Stack
            flexDirection="row"
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <img src={LogoFull} style={{ maxHeight: 30 }} />
          </Stack>

          <IconButton
            aria-label="menu"
            onClick={handleMenuClick}
            sx={{
              position: 'absolute',
              right: 8,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="customer-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'customer-menu',
            }}
          >
            <MenuItem onClick={menuNav} disabled={'/' === location.pathname}>
              Menu
            </MenuItem>
            {!!currentCustomer && (
              <MenuItem
                onClick={profileNav}
                disabled={'/profile' === location.pathname}
              >
                Profile
              </MenuItem>
            )}
            {!currentCustomer && (
              <MenuItem onClick={handleLoginClick}>Login</MenuItem>
            )}
            {!!currentCustomer && (
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            )}
            <MenuItem>
              <Typography
                variant="caption"
                color={`${theme.palette.grey[400]}`}
              >
                v{`${version} ${env}`}
              </Typography>
            </MenuItem>
          </Menu>
        </Stack>
        <Dialog
          fullScreen
          open={showAccountModal !== null}
          TransitionComponent={LoginDialogTransition}
          onClose={handleLoginClose}
        >
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            dividers
          >
            <Stack
              flexDirection="row"
              justifyContent="flex-start"
              flex={0}
              width="100%"
            >
              <IconButton
                onClick={handleLoginClose}
                sx={{ marginLeft: 'auto', marginRight: 1 }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <CustomerLoginComponent />
          </DialogContent>
        </Dialog>
      </Card>
    </Stack>
  );
}
