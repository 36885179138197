import { create } from 'zustand';
import componentTypesApi from '../api/componentTypes';
import { TpgeComponentType } from '../common/types';

type ComponentTypesStore = {
  hasLoaded: boolean;
  componentTypes: TpgeComponentType[];
  loading: boolean;
  error: unknown | null;
  fetchComponentTypes: (inclueDeleted?: boolean) => void;
};

const useComponentTypes = create<ComponentTypesStore>((set, get) => ({
  hasLoaded: false,
  componentTypes: [],
  loading: false,
  error: null,
  fetchComponentTypes: async (includeDeleted = false) => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await componentTypesApi.getComponentTypes(includeDeleted);
      set({ componentTypes: result.data ?? [], loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
}));

export default useComponentTypes;

if (!useComponentTypes.getState().hasLoaded) {
  useComponentTypes.getState().fetchComponentTypes();
}
