import { ProductEndpoint } from '../common/endpoints';
import { MutateProduct, Product, ServerResponse } from '../common/types';
import { getRequest, postRequest, putRequest } from './requests';

export async function listProducts(amountToTake?: number) {
  const suffix = amountToTake ? `?take=${amountToTake}` : '';
  const result: ServerResponse<Product[]> = await getRequest(
    `${ProductEndpoint}${suffix}`,
  );
  return result;
}

export async function getProduct(id: number) {
  const result: ServerResponse<Product> = await getRequest(
    `${ProductEndpoint}/${id}`,
  );
  return result;
}

export async function updateProduct(product: MutateProduct) {
  const result: ServerResponse<null> = await putRequest(
    `${ProductEndpoint}/${product.id}`,
    product,
  );
  return result;
}

export async function createProduct(product: MutateProduct) {
  const result: ServerResponse<Product> = await postRequest(
    ProductEndpoint,
    product,
  );
  return result;
}

export default {
  listProducts,
  updateProduct,
  createProduct,
  getProduct,
};
