import CloseIcon from '@mui/icons-material/Close';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Site } from '../../../../common/types';

type SiteSelectionModalProps = {
  onSiteSelected: (selected: Site | null) => void;
  sites: Site[];
  selectedSiteId: number;
  close: () => void;
};

export default function SiteSelectionModal({
  sites,
  selectedSiteId,
  onSiteSelected = () => {},
  close = () => {},
}: SiteSelectionModalProps) {
  const theme = useTheme();
  const selectedSite = sites.find((f) => f.id === selectedSiteId);
  const otherSites = sites.filter((f) => f.id !== selectedSiteId);

  return (
    <Dialog open maxWidth="xs" fullWidth onClose={close}>
      <Stack sx={{ padding: '1em' }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Typography variant="h3" fontWeight="bold">
            Select Store
          </Typography>
          <IconButton
            onClick={close}
            sx={{ marginLeft: 'auto', marginRight: '-0.5em' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <List sx={{ pt: 0 }}>
          {selectedSite && (
            <ListItem disableGutters>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.common.darkGrey }}>
                  <LocationOnOutlined
                    sx={{ color: theme.palette.common.utilityGreen }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={selectedSite.name}
                secondary="current selection"
              />
            </ListItem>
          )}
          {otherSites.map((site: Site) => (
            <ListItem disableGutters key={site.id}>
              <ListItemButton
                onClick={() => onSiteSelected(site)}
                sx={{ padding: 0 }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: theme.palette.common.darkGrey }}>
                    <LocationOnOutlined
                      sx={{ color: theme.palette.common.utilityOrange }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={site.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Dialog>
  );
}
