import { StaffEndpoint } from '../common/endpoints';
import { ServerResponse, Staff, StaffAccount } from '../common/types';
import { getRequest, patchRequest, postRequest, putRequest } from './requests';

export async function updateStation(staffEmail: string, stationId: number) {
  const result: ServerResponse<Staff> = await patchRequest(
    `${StaffEndpoint}/update-station`,
    { staffEmail, stationId },
  );
  return result;
}

export async function updateStaff(staffAccount: StaffAccount) {
  const result: ServerResponse<null> = await putRequest(
    StaffEndpoint,
    staffAccount,
  );
  return result;
}

export async function createStaff(staffAccount: StaffAccount) {
  const result: ServerResponse<StaffAccount> = await postRequest(
    StaffEndpoint,
    staffAccount,
  );
  return result;
}

export async function listStaff() {
  const result: ServerResponse<StaffAccount[]> =
    await getRequest(StaffEndpoint);
  return result;
}

export default {
  listStaff,
  updateStaff,
  updateStation,
  createStaff,
};
