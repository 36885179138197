import NotificationsIcon from '@mui/icons-material/Notifications';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns/format';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import orderApi from '../../../api/order';
import { PaymentType } from '../../../common/enums';
import { Order, StaffOrder } from '../../../common/types';
import InfoCard from '../../../components/card/InfoCard';
import ConfirmModal from '../../../components/modal/ConfirmModal';
import useOrders from '../../../datastore/useOrders';
import useAlert from '../../../hooks/useAlert';
import PaymentModal from './components/PaymentModal';
import PosHeader from './components/PosHeader';

export default function PosPage() {
  const location = useLocation();
  const walkUpOrder: Order | null = location.state?.order ?? null;
  const theme = useTheme();
  const [searchCustomer, setSearchCustomer] = useState('');
  const [paymentOrderId, setPaymentOrderId] = useState<number | null>(
    walkUpOrder?.id ? walkUpOrder.id : null,
  );
  const [hidePaid, setHidePaid] = useState(!walkUpOrder);
  const navigate = useNavigate();
  const { orders, fetchOrders } = useOrders();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [removeOrderId, setRemoveOrderId] = useState<number | null>(null);
  const { showAlert } = useAlert();
  const walkUpReturnRoute: string = location.state?.returnRoute ?? '';

  async function handleMarkAsPaid(orderId: number, paymentType: PaymentType) {
    if (!orderId) {
      showAlert('Failed to mark order as paid.', 'error');
      return;
    }
    const order = orders.find((order) => order.id === orderId);
    if (!order) {
      showAlert('Failed to mark order as paid.', 'error');
      return;
    }
    try {
      setIsLoading(true);
      const result = await orderApi.startStopFinishOrder({
        id: order.id,
        startTime: null,
        finishTime: null,
        orderItems: order.orderItems.map((m) => ({
          id: m.id,
          status: m.status,
        })),
        softDelete: false,
        paymentType,
      });

      if (result.status !== 200) {
        showAlert('Failed to mark order as paid.', 'error');
        return;
      }
      fetchOrders();
      showAlert('Order marked as paid.', 'success');
      if (walkUpReturnRoute) {
        navigate(walkUpReturnRoute);
      }
    } catch (err) {
      console.error(err);
      showAlert('Failed to mark order as paid.', 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRemove(orderId: number) {
    if (!orderId) {
      showAlert('Failed to remove order.', 'error');
      return;
    }
    const order = orders.find((order) => order.id === orderId);
    if (!order) {
      showAlert('Failed to remove order.', 'error');
      return;
    }
    try {
      setIsLoading(true);
      const result = await orderApi.startStopFinishOrder({
        ...order,
        softDelete: true,
      });
      if (result.status !== 200) {
        showAlert(
          'Failed to remove order, refresh page and try again.',
          'error',
        );
        return;
      }
      fetchOrders();
      showAlert('Order removed.', 'success');
    } catch (err) {
      console.error(err);
      showAlert('Failed to remove order, refresh page and try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const filteredOrders = orders?.filter((order) => {
    const matchesPaidFilter = !hidePaid || !order.isPaid;
    const matchesSearchFilter =
      !searchCustomer ||
      order.customerName?.toLowerCase().includes(searchCustomer.toLowerCase());
    return matchesPaidFilter && matchesSearchFilter;
  });

  const totalNumberOfPages = Math.ceil(filteredOrders.length / 25);
  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * 25,
    currentPage * 25,
  );

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const paymentOrder = orders.find((f) => f.id === paymentOrderId);
  const removeOrder = orders.find((f) => f.id === removeOrderId);

  return (
    <>
      <Stack
        sx={{ backgroundColor: theme.palette.common.grey, height: '100vh' }}
      >
        <PosHeader
          searchCustomer={searchCustomer}
          setSearchCustomer={setSearchCustomer}
          hidePaid={hidePaid}
          setHidePaid={setHidePaid}
        ></PosHeader>
        <Divider />
        {paginatedOrders.length === 0 ? (
          <InfoCard
            Icon={NotificationsIcon}
            title="No Results Found"
            subtitle="Try refreshing your page or check back later."
          />
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="order table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight="bold">Customer Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">Start Time</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">Finish Time</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">Is Paid</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">Payment Type</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedOrders?.map((order: StaffOrder, index: number) => {
                  const startDate = order.startTime
                    ? format(order.startTime, 'dd/MM/yy')
                    : '';
                  const startTime = order.startTime
                    ? format(order.startTime, 'HH:mm a')
                    : '';
                  const finishDate = order.finishTime
                    ? format(order.finishTime, 'dd/MM/yy')
                    : '';
                  const finishTime = order.finishTime
                    ? format(order.finishTime, 'HH:mm a')
                    : '';

                  return (
                    <TableRow key={index}>
                      <TableCell width="20%">{order.customerName}</TableCell>
                      <TableCell width="15%">
                        <Typography variant="caption">
                          {startDate} {startTime}
                        </Typography>
                      </TableCell>
                      <TableCell width="15%">
                        <Typography variant="caption">
                          {finishDate} {finishTime}
                        </Typography>
                      </TableCell>
                      <TableCell width="10%">
                        {order.isPaid ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell width="18%">
                        {order.paymentType || 'Not Paid'}
                      </TableCell>
                      <TableCell width="10%">
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            margin: 0,
                            whiteSpace: 'nowrap',
                          }}
                          disabled={isLoading || order.isPaid}
                          onClick={() => {
                            setIsLoading(true);
                            setPaymentOrderId(order.id);
                          }}
                        >
                          Mark as Paid
                        </Button>
                      </TableCell>
                      <TableCell width="10%">
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          sx={{
                            margin: 0,
                            whiteSpace: 'nowrap',
                          }}
                          disabled={isLoading}
                          onClick={() => {
                            setIsLoading(true);
                            setRemoveOrderId(order.id ?? 0);
                          }}
                        >
                          Remove Order
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              sx={{ paddingTop: '1em', paddingBottom: '1em' }}
            >
              <Pagination
                count={totalNumberOfPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </TableContainer>
        )}
      </Stack>
      {removeOrderId && (
        <ConfirmModal
          onClose={() => {
            setIsLoading(false);
            setRemoveOrderId(null);
          }}
          open={!!removeOrderId}
          title={`Remove order for ${removeOrder?.customerName?.toUpperCase()}`}
          content="Are you sure you want to remove this item?"
          onConfirm={() => {
            handleRemove(removeOrderId);
            setRemoveOrderId(null);
            setIsLoading(false);
          }}
        />
      )}
      {paymentOrderId && (
        <PaymentModal
          onClose={() => {
            setIsLoading(false);
            setPaymentOrderId(null);
          }}
          open={!!paymentOrderId}
          title={`Payment Method for ${paymentOrder?.customerName?.toUpperCase()}`}
          content="Please select the payment method for this order."
          onConfirm={(paymentType: PaymentType) => {
            handleMarkAsPaid(paymentOrderId, paymentType);
            setPaymentOrderId(null);
            setIsLoading(false);
          }}
        />
      )}
    </>
  );
}
