import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TpgeComponentType } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';

interface ComponentTypesTableProps {
  isLoading: boolean;
  paginatedData: TpgeComponentType[];
  showDisabled: boolean;
  onEditClicked: (component: TpgeComponentType) => void;
  statusToggle: (component: TpgeComponentType) => void;
}

export function ComponentTypesTable({
  isLoading,
  paginatedData,
  showDisabled,
  onEditClicked,
  statusToggle,
}: ComponentTypesTableProps) {
  return (
    <Table sx={{ maxWidth: '100%', overflowX: 'auto' }} size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: '50%' }}>Name</TableCell>
          <TableCell sx={{ width: '50%' }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <LoadingSpinner isLoading={isLoading} isTable colSpan={2}>
          {paginatedData.map((componentType) => {
            if (!componentType.isActive && !showDisabled) return null;
            return (
              <TableRow
                key={componentType.id}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    cursor: 'pointer',
                  },
                }}
              >
                <TableCell sx={{ width: '50%' }}>
                  {componentType.name}
                </TableCell>
                <TableCell sx={{ width: '50%' }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => onEditClicked(componentType)}
                  >
                    Edit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => statusToggle(componentType)}
                    color={componentType.isActive ? 'error' : 'primary'}
                  >
                    {componentType.isActive ? 'Disable' : 'Enable'}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </LoadingSpinner>
      </TableBody>
    </Table>
  );
}
