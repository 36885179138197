import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { TpgeComponent, TpgeComponentType } from '../../../../common/types';
import LoadingButton from '../../../../components/loadingButton/LoadingButton';
import AdminDialogTitle from '../../components/AdminDialogTitle';

interface ComponentModalProps {
  data: TpgeComponent;
  componentTypes: TpgeComponentType[];
  mode: 'create' | 'edit';
  onClose: () => void;
  onSave: (obj: TpgeComponent) => void;
  loading: boolean;
}

export default function ComponentModal({
  data,
  componentTypes = [],
  mode,
  onClose,
  onSave,
  loading,
}: ComponentModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TpgeComponent>({ defaultValues: data });

  const handleSave = (obj: TpgeComponent) => {
    const ct = componentTypes.find((f) => f.id === obj.componentTypeId);
    if (ct) {
      obj.componentType = ct;
      onSave(obj);
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <AdminDialogTitle>
        {mode === 'create' ? 'New' : 'Edit'} Component
      </AdminDialogTitle>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="unit"
            control={control}
            rules={{ required: 'Unit is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                label="Unit"
                type="text"
                fullWidth
                error={!!errors.unit}
                helperText={errors.unit?.message}
              />
            )}
          />
          <Controller
            name="costPerUnit"
            control={control}
            rules={{ required: 'Cost Per Unit is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                label="Cost Per Unit"
                type="number"
                fullWidth
                error={!!errors.costPerUnit}
                helperText={errors.costPerUnit?.message}
              />
            )}
          />
          <Controller
            name="componentTypeId"
            control={control}
            rules={{
              required: 'Component Type is required',
              validate: (val: number) =>
                val > 0 || 'Component Type is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                label="Component Type"
                select
                fullWidth
                error={!!errors.componentTypeId}
                helperText={errors.componentTypeId?.message}
              >
                <MenuItem value={0} disabled>
                  Select option
                </MenuItem>
                {componentTypes.map((ct) => (
                  <MenuItem key={`ct_${ct.id}`} value={ct.id}>
                    {ct.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <FormControlLabel
            control={
              <Controller
                name="isDefault"
                control={control}
                render={({ field: { onChange } }) => (
                  <Switch
                    defaultChecked={data.isDefault}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Is Default?"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={loading}
            loading={loading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
