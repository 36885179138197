import { ProductVariantTypesEndpoint } from '../common/endpoints';
import { ProductVariantType, ServerResponse } from '../common/types';
import { getRequest } from './requests';

export async function listProductVariantTypes(): Promise<
  ServerResponse<ProductVariantType[]>
> {
  const result: ServerResponse<ProductVariantType[]> = await getRequest(
    ProductVariantTypesEndpoint,
  );
  return result;
}
export default { listProductVariantTypes };
