/* eslint-disable @typescript-eslint/no-explicit-any */
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';
import { passwordName } from '../../common/constants';
import {
  PasswordValidationRules,
  phoneRules,
} from '../../common/formValidationRules';
import usePasswordVisibility from '../../hooks/usePasswordVisibility';

interface RegisterFormProps {
  control: Control<FieldValues> | Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  loading: boolean;
  passwordValidationRules?: PasswordValidationRules;
}

export default function RegisterForm({
  control,
  errors,
  loading,
  passwordValidationRules = { required: true },
}: RegisterFormProps) {
  const theme = useTheme();
  const { visible, toggle } = usePasswordVisibility();
  return (
    <>
      <Controller
        name={`Confirm${passwordName}`}
        control={control}
        defaultValue={''}
        rules={passwordValidationRules}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label={'Confirm password'}
            variant="filled"
            type={visible ? 'text' : 'password'}
            slotProps={{
              inputLabel: {
                shrink: true,
                style: {
                  color: errors[`Confirm${passwordName}`]
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                },
              },
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggle}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {visible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            disabled={loading}
            error={!!errors[`Confirm${passwordName}`]}
            helperText={errors[`Confirm${passwordName}`]?.message as string}
          />
        )}
      />

      <Controller
        name="Name"
        control={control}
        defaultValue={''}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label={'Name'}
            variant="filled"
            slotProps={{
              inputLabel: {
                shrink: true,
                style: {
                  color: errors['Name']
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                },
              },
            }}
            disabled={loading}
            error={!!errors['Name']}
            helperText={errors['Name']?.message as string}
          />
        )}
      />
      <Controller
        name="Phone"
        control={control}
        defaultValue={''}
        rules={{ required: false, ...phoneRules() }}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label={'Phone'}
            variant="filled"
            slotProps={{
              inputLabel: {
                shrink: true,
                style: {
                  color: errors['Phone']
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                },
              },
            }}
            disabled={loading}
            error={!!errors['Phone']}
            helperText={errors['Phone']?.message as string}
          />
        )}
      />
    </>
  );
}
