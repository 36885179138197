import CircularProgress from '@mui/material/CircularProgress';
import Button, { type ButtonProps } from '@mui/material/Button';

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  children,
  ...rest
}) => {
  return (
    <Button disabled={loading} {...rest}>
      {loading && <CircularProgress size={20} />}
      {children}
    </Button>
  );
};

export default LoadingButton;
