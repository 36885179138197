import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AdminPageFilter from '../../components/AdminPageFilter';

interface FilterProps {
  filterText: string;
  setFilterText: (value: string) => void;
  showDisabledAccounts: boolean;
  setShowDisabledAccounts: (value: boolean) => void;
}

export default function AccountManagementFilter({
  filterText,
  setFilterText,
  showDisabledAccounts,
  setShowDisabledAccounts,
}: FilterProps) {
  return (
    <AdminPageFilter
      filterText={filterText}
      setFilterText={setFilterText}
      filterLabel="Filter by Email"
    >
      <FormControlLabel
        control={
          <Switch
            checked={showDisabledAccounts}
            onChange={(e) => setShowDisabledAccounts(e.target.checked)}
          />
        }
        label="Show disabled accounts"
      />
    </AdminPageFilter>
  );
}
