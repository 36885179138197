import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface CommonColors {
    grey: string;
    offWhite: string;
    babyBlue: string;
    deepblue: string;
    utilityOrange: string;
    utilityRed: string;
    utilityGreen: string;
    darkGrey: string;
  }
}

export const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#00846D',
      contrastText: '#ffffff',
    },
    error: {
      main: '#ff0000',
    },
    common: {
      white: '#FFFFFF',
      offWhite: '#F4EEE2',
      grey: '#F7F7F7',
      darkGrey: '#E5E5E5',
      babyBlue: '#65AFFF',
      deepblue: '#00171F',
      utilityOrange: '#E07900',
      utilityRed: '#DF2B2B',
      utilityGreen: '#358519',
    },
    text: {
      primary: '#00171F',
      secondary: '#00171F',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Arial, Inter',
    },
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 450,
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.15rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
        },
      },
    },
  },
});

export function hexWithAlpha(hex: string, alpha: number): string | null {
  // Check if the input hex color is valid
  const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
  if (!hexRegex.test(hex)) {
    console.error('Invalid hex color format');
    return null;
  }

  // Remove '#' from hex if present
  hex = hex.replace('#', '');

  // Convert hex to RGBA format
  let r: number, g: number, b: number;
  if (hex.length === 3) {
    r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
    g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
    b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    console.error('Invalid hex color length');
    return null;
  }

  // Validate and normalize alpha
  if (alpha < 0 || alpha > 1) {
    console.error('Invalid alpha value (should be between 0 and 1)');
    return null;
  }

  // Convert alpha to hexadecimal
  const alphaHex = Math.round(alpha * 255)
    .toString(16)
    .toUpperCase()
    .padStart(2, '0');

  // Return hex with alpha
  return `#${r.toString(16).toUpperCase().padStart(2, '0')}${g
    .toString(16)
    .toUpperCase()
    .padStart(2, '0')}${b
    .toString(16)
    .toUpperCase()
    .padStart(2, '0')}${alphaHex}`;
}
