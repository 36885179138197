import { create } from 'zustand';
import componentsApi from '../api/components';
import { TpgeComponent } from '../common/types';

type ComponentsStore = {
  hasLoaded: boolean;
  showDisabled: boolean;
  setShowDisabled: (showDisabled: boolean) => void;
  components: TpgeComponent[];
  loading: boolean;
  error: unknown | null;
  fetchComponents: () => void;
};

const useComponents = create<ComponentsStore>((set, get) => ({
  hasLoaded: false,
  showDisabled: false,
  components: [],
  loading: false,
  error: null,
  fetchComponents: async () => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await componentsApi.getComponents(get().showDisabled);
      set({ components: result.data ?? [], loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
  setShowDisabled: (showDisabled: boolean) => {
    set({ showDisabled });
    get().fetchComponents();
  },
}));

export default useComponents;

if (!useComponents.getState().hasLoaded) {
  useComponents.getState().fetchComponents();
}
