/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RuleIcon from '@mui/icons-material/Rule';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import ordersApi from '../../../../api/order';
import { ProductCategoryType, Stations } from '../../../../common/enums';
import {
  groupStaffOrderItems,
  unfinishedOrdersForStation,
} from '../../../../common/orderFunctions';
import { sortOrdersById } from '../../../../common/sortFunctions';
import { StaffOrder, StaffOrderItem } from '../../../../common/types';
import InfoCard from '../../../../components/card/InfoCard';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import useAlert from '../../../../hooks/useAlert';
import { IndicatorBox } from '../../components/IndicatorBox';
import {
  getOrderPosition,
  shiftOrderItems,
} from '../../functions/orderFunctions';
import {
  allColdDrinksFinished,
  allColdDrinksPlaced,
  allColdDrinksStarted,
  allFoodFinished,
  allFoodPending,
  allFoodStarted,
  allHotCoffeeOrTeaFinished,
  allHotCoffeeOrTeaStarted,
  sortStationOrders,
} from '../../functions/stationFunctions';
import DraggableStationCard from './DraggableStationCard';
import WalkStationHeader from './WalkStationHeader';

interface StationProps {
  orders: StaffOrder[];
  stationName: Stations;
  stationId: number;
  opposingStationId: number;
}

export default function Station({
  orders,
  stationName,
  stationId,
  opposingStationId,
}: StationProps) {
  const theme = useTheme();
  const { showAlert } = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const [draggableOrders, setDraggableOrders] = useState<StaffOrder[]>([]);
  const [moveOrderId, setMoveOrderId] = useState<number | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    const incomingOrdersAreDifferent = !isEqual(
      sortOrdersById(orders),
      sortOrdersById(draggableOrders),
    );
    const incomingOrders = [...orders];
    if (incomingOrdersAreDifferent) {
      incomingOrders.forEach((incomingOrder) => {
        const draggableOrder = draggableOrders.find(
          (dragOrder) => dragOrder.id === incomingOrder.id,
        );
        incomingOrder.orderItems.forEach((incomingOrderItem) => {
          const foundDraggableOrderItem = draggableOrder?.orderItems.find(
            (dragOrderItem) => dragOrderItem.id === incomingOrderItem.id,
          );
          if (foundDraggableOrderItem) {
            incomingOrderItem.queueOrder = foundDraggableOrderItem.queueOrder;
          }
        });
      });
      setDraggableOrders([...orders]);
      setIsLoading(false);
    }
  }, [orders]);

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id === over.id) return;

    setDraggableOrders((prevOrders) => {
      const originalPosition = getOrderPosition(active.id, draggableOrders);
      const newPosition = getOrderPosition(over.id, draggableOrders);
      const newArray = arrayMove(prevOrders, originalPosition, newPosition);
      shiftOrderItems(
        newArray,
        [ProductCategoryType.HotCoffee, ProductCategoryType.Tea],
        showAlert,
      );
      return newArray;
    });
  }

  async function handleMoveOrder() {
    if (!moveOrderId) {
      console.error('Failed to move order due to invalid order id');
      showAlert('Failed to move order, please refresh and try again.', 'error');
      return;
    }

    const result = await ordersApi.updateStation({
      orderId: moveOrderId,
      newStationId: opposingStationId,
      productCategories: [
        ProductCategoryType.HotCoffee,
        ProductCategoryType.Tea,
      ],
    });

    if (result.status !== 200) {
      console.error('Failed to move order, server error');
      showAlert('Failed to move order, please refresh and try again.', 'error');
    }
  }

  const sortedFilteredOrders = sortStationOrders(
    unfinishedOrdersForStation(orders, stationId),
    [ProductCategoryType.HotCoffee, ProductCategoryType.Tea],
    {
      isAllFinishedFn: allHotCoffeeOrTeaFinished,
      isAllStartedFn: allHotCoffeeOrTeaStarted,
    },
  )?.map((order) => {
    const isAllHotCoffeeStarted = allHotCoffeeOrTeaStarted(order.orderItems);
    const isAllHotCoffeeFinished = allHotCoffeeOrTeaFinished(order.orderItems);
    const filteredOrderItems = order.orderItems.filter(
      (orderItem) =>
        orderItem.stationId === stationId &&
        [ProductCategoryType.HotCoffee, ProductCategoryType.Tea].includes(
          orderItem.productCategoryType as ProductCategoryType,
        ),
    );
    const groupedItems = groupStaffOrderItems(filteredOrderItems, true);
    return {
      order,
      isAllHotCoffeeStarted,
      isAllHotCoffeeFinished,
      groupedItems,
    };
  });

  return (
    <DndContext
      collisionDetection={closestCorners}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <Grid
        size={6}
        sx={{
          padding: theme.spacing(1),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <WalkStationHeader stationName={stationName} />
        <Divider />
        {draggableOrders.length === 0 ? (
          <InfoCard
            Icon={NotificationsIcon}
            title="No Results Found"
            subtitle="Try refreshing your page or check back later."
          />
        ) : (
          <Box
            sx={{
              overflowY: 'auto',
              height: 'calc(100vh - 64px)',
            }}
          >
            <Grid container spacing={1} sx={{ padding: 3 }}>
              {sortedFilteredOrders.map(
                (
                  {
                    order,
                    isAllHotCoffeeStarted,
                    isAllHotCoffeeFinished,
                    groupedItems,
                  },
                  index,
                ) => {
                  return (
                    <Grid size={12} key={`${order.customerId}-${index}`}>
                      <Paper
                        elevation={8}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          padding: 0,
                          margin: 0,
                          height: '100%',
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.common.deepblue,
                        }}
                      >
                        {isAllHotCoffeeFinished ? (
                          <Stack
                            sx={{
                              backgroundColor: theme.palette.common.grey,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              padding: 0,
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <Stack
                                sx={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <Stack
                                  sx={{ padding: 1, alignItems: 'center' }}
                                >
                                  <RuleIcon />
                                </Stack>
                                <Divider orientation="vertical" flexItem />
                                <Typography
                                  variant="h3"
                                  sx={{ marginLeft: theme.spacing(1) }}
                                >
                                  {order.customerName}
                                </Typography>
                              </Stack>

                              <Stack sx={{ paddingRight: 2 }}>
                                {order.orderItems.some(
                                  (item: StaffOrderItem) =>
                                    item.productCategoryType ===
                                    ProductCategoryType.Food,
                                ) && (
                                  <IndicatorBox
                                    orderItems={order.orderItems}
                                    label="Food"
                                    allStartedFn={allFoodStarted}
                                    allFinishedFn={allFoodFinished}
                                    allPendingFn={allFoodPending}
                                  />
                                )}
                                {order.orderItems.some(
                                  (item: StaffOrderItem) =>
                                    item.productCategoryType ===
                                    ProductCategoryType.ColdDrinks,
                                ) && (
                                  <IndicatorBox
                                    orderItems={order.orderItems}
                                    label="Cold Drinks"
                                    allStartedFn={allColdDrinksStarted}
                                    allFinishedFn={allColdDrinksFinished}
                                    allPendingFn={allColdDrinksPlaced}
                                  />
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                        ) : (
                          <SortableContext
                            items={draggableOrders as any} //need this to be any as dnd-kit is not typed.
                            strategy={verticalListSortingStrategy}
                          >
                            <DraggableStationCard
                              order={order}
                              groupedItems={groupedItems}
                              isAllHotCoffeeStarted={isAllHotCoffeeStarted}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                              setMoveOrderId={setMoveOrderId}
                            />
                          </SortableContext>
                        )}
                      </Paper>
                    </Grid>
                  );
                },
              )}
            </Grid>
          </Box>
        )}
        {moveOrderId && (
          <ConfirmModal
            onClose={() => {
              setMoveOrderId(null);
              setIsLoading(false);
            }}
            open={!!moveOrderId}
            title="Move Order"
            content="Are you sure you want to move this order?"
            onConfirm={() => {
              handleMoveOrder();
              setMoveOrderId(null);
            }}
          />
        )}
      </Grid>
    </DndContext>
  );
}
