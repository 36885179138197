/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ProductCategoryType } from '../../../../common/enums';
import { StaffOrder, StaffOrderItem, WithQty } from '../../../../common/types';
import { TimeIndicator } from '../../components/TimeIndicator';

interface DraggableStationCardProps {
  isAllHotCoffeeStarted: boolean;
  order: any; //we need this to be 'any' as the dnd-kit library is not typed.
  groupedItems: WithQty<StaffOrderItem>[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setMoveOrderId: (moveOrderId: number) => void;
}

export default function DraggableStationCard({
  isAllHotCoffeeStarted,
  order,
  groupedItems,
  isLoading,
  setIsLoading,
  setMoveOrderId,
}: DraggableStationCardProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: order.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function handlePayNow(order: StaffOrder) {
    navigate('/staff/pos', {
      state: {
        order: order,
        returnRoute: '/staff/walkup',
      },
    });
  }

  return (
    <Stack ref={setNodeRef} {...attributes} {...listeners} style={style}>
      <Stack
        sx={{
          backgroundColor: theme.palette.common.grey,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Stack sx={{ padding: 1, alignItems: 'center' }}>
            {isAllHotCoffeeStarted ? <LockIcon /> : <DragHandleIcon />}
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Typography variant="h3" sx={{ marginLeft: theme.spacing(1) }}>
            {order.customerName}
          </Typography>
        </Stack>
        <TimeIndicator time={order.requestedPickupTime} />
      </Stack>
      <TableContainer component={Paper}>
        <Table aria-label="order table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Qty</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedItems.map((orderItem, index: number) => {
              if (
                orderItem.productCategoryType !==
                  ProductCategoryType.HotCoffee &&
                orderItem.productCategoryType !== ProductCategoryType.Tea
              ) {
                return null;
              }
              return (
                <TableRow key={index}>
                  <TableCell>{orderItem.quantity}</TableCell>
                  <TableCell>{orderItem.productName}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Stack
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flex: 1,
          }}
        >
          <Stack sx={{ flexDirection: 'row' }}>
            <Button
              variant="outlined"
              size="small"
              color="warning"
              sx={{
                margin: 1,
                width: '10em',
                whiteSpace: 'nowrap',
              }}
              disabled={isLoading || isAllHotCoffeeStarted}
              onClick={() => {
                setIsLoading(true);
                setMoveOrderId(order.id);
              }}
            >
              Move Station
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                margin: 1,
                whiteSpace: 'nowrap',
              }}
              disabled={isLoading || order.isPaid}
              onClick={() => {
                setIsLoading(true);
                handlePayNow(order);
              }}
            >
              Pay Now
            </Button>
          </Stack>
        </Stack>
      </TableContainer>
    </Stack>
  );
}
