import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ClockIcon } from '@mui/x-date-pickers';
import { format } from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { getOrderSummary } from '../../../api/customerOrders';
import {
  OrderSummary,
  SummaryOrderItemProductVariant,
} from '../../../common/types';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import useRequest from '../../../hooks/useRequest';

const variantSort = (
  a: SummaryOrderItemProductVariant,
  b: SummaryOrderItemProductVariant,
): number => {
  return a.variantName.localeCompare(b.variantName);
};

export default function OrderConfirmed() {
  const theme = useTheme();
  const { orderId } = useParams();
  const { data, loading } = useRequest<OrderSummary>(
    () => getOrderSummary(+orderId!),
    [orderId],
  );

  const formattedPickup = data?.requestedPickupTime
    ? format(data.requestedPickupTime, 'do LLL yyyy HH:mm')
    : '';
  return (
    <>
      <LoadingSpinner isLoading={loading}>
        <Stack sx={{ flex: 0, paddingX: '1rem', paddingTop: '1rem' }}>
          {data ? (
            <>
              <Typography
                variant="h3"
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: theme.palette.common.white,
                }}
              >
                Order confirmed
              </Typography>
              <Divider sx={{ marginY: 1 }} />
              <Typography variant="h5">Pick up:</Typography>
              <Typography
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  display: 'flex',
                }}
              >
                <LocationOnOutlined sx={{ marginRight: '1rem' }} />
                {data.siteName}
              </Typography>
              <Typography
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  display: 'flex',
                }}
              >
                <ClockIcon sx={{ marginRight: '1rem' }} />
                {formattedPickup}
              </Typography>
              {data.items.map((orderItem, idx) => (
                <Stack key={`${idx}_${orderItem.productName}`}>
                  <Divider sx={{ marginY: 1 }} />
                  <Stack flexDirection="row" justifyContent="flex-start">
                    <Typography fontWeight="bold">
                      {orderItem.productName}
                    </Typography>
                    <Typography fontWeight="bold" sx={{ marginLeft: 'auto' }}>
                      ${orderItem.total.toFixed(2)}
                    </Typography>
                  </Stack>

                  {orderItem.items.sort(variantSort).map((variant, vIdx) => (
                    <Stack
                      key={`${idx}_${vIdx}_${variant.variantName}`}
                      flexDirection="row"
                      justifyContent="flex-start"
                    >
                      <Typography fontSize="12px">
                        - {variant.variantName} x {variant.quantity}
                      </Typography>
                      <Typography fontSize="12px" sx={{ marginLeft: 'auto' }}>
                        ${variant.total.toFixed(2)}
                      </Typography>
                    </Stack>
                  ))}
                  {orderItem.notes?.length > 0 && (
                    <Typography
                      fontSize="12px"
                      sx={{ flex: 1, textAlign: 'left', marginTop: 1 }}
                    >
                      * {orderItem.notes}
                    </Typography>
                  )}
                  {orderItem.quantity > 1 && (
                    <Stack flexDirection="row" justifyContent="space">
                      <Typography sx={{ flex: 1, textAlign: 'center' }}>
                        x {orderItem.quantity}
                      </Typography>
                      <Typography>
                        ${(orderItem.total * orderItem.quantity).toFixed(2)}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              ))}
              <Divider sx={{ marginY: 1 }} />
              <Stack flexDirection="row">
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{ flex: 1, textAlign: 'center' }}
                >
                  Total:
                </Typography>
                <Typography variant="h4" fontWeight="bold">
                  ${data.total.toFixed(2)}
                </Typography>
              </Stack>
            </>
          ) : (
            <>
              <Typography variant="h3">Something went wrong :(</Typography>
            </>
          )}
        </Stack>
      </LoadingSpinner>
    </>
  );
}
