import ErrorIcon from '@mui/icons-material/Error';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Stations } from '../../../common/enums';
import { isStaff } from '../../../common/typeAssertionFunctions';
import InfoCard from '../../../components/card/InfoCard';
import useOrders from '../../../datastore/useOrders';
import useProducts from '../../../datastore/useProducts';
import useStations from '../../../datastore/useStations';
import useAPI from '../../../hooks/useAPI';
import ColdDrinksStation from './components/ColdDrinksStation';
import FoodStation from './components/FoodStation';
import OpposingStation from './components/OpposingStation';
import PrimaryStation from './components/PrimaryStation';

export default function StaffHomePage() {
  const { currentUser } = useAPI();
  const theme = useTheme();
  const { orders = [], startPolling, stopPolling } = useOrders();
  const { products = [] } = useProducts();
  const { stations = [] } = useStations();

  useEffect(() => {
    startPolling({ initialInterval: 5000, maxInterval: 15000 });
    return () => {
      stopPolling();
    };
  }, []);

  if (!isStaff(currentUser) || !products?.length) return null;

  const mappedOrders = (orders || []).map((o) => ({
    ...o,
    requestedPickupTime: o.requestedPickupTime
      ? new Date(o.requestedPickupTime)
      : null,
    startTime: o.startTime ? new Date(o.startTime) : null,
    finishTime: o.finishTime ? new Date(o.finishTime) : null,
  }));

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.common.white,
        height: '100vh',
      }}
    >
      {currentUser.stationName === Stations.StationOne ||
      currentUser.stationName === Stations.StationTwo ? (
        <>
          <PrimaryStation primaryOrders={mappedOrders} user={currentUser} />
          <OpposingStation
            opposingOrders={mappedOrders}
            user={currentUser}
            stations={stations ?? []}
          />
        </>
      ) : currentUser.stationName === Stations.ColdDrinks ? (
        <ColdDrinksStation orders={mappedOrders} user={currentUser} />
      ) : currentUser.stationName === Stations.Food ? (
        <FoodStation user={currentUser} orders={mappedOrders} />
      ) : (
        <Link to="/staff/settings" style={{ textDecoration: 'none' }}>
          <Stack sx={{ flexDirection: 'column', cursor: 'pointer' }}>
            <InfoCard
              Icon={ErrorIcon}
              title="No Station Selected"
              subtitle="Click here to go to settings and select a station."
            />
          </Stack>
        </Link>
      )}
    </Grid>
  );
}
