import { useState } from 'react';

interface PasswordVisibility {
  visible: boolean;
  toggle: () => void;
}

export default function usePasswordVisibility(): PasswordVisibility {
  const [visible, setVisible] = useState(false);
  const toggle = () => setVisible((prev) => !prev);

  return { visible, toggle };
}
