import { postRequest } from '../api/requests';
import { PasswordResetEndpoint } from '../common/endpoints';
import { ServerResponse } from '../common/types';
import useIsOnline from './useIsOnline';

interface PasswordResetData {
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (
    email: string,
    token: string,
    password: string,
  ) => Promise<string | null>;
}

const usePasswordReset = (): PasswordResetData => {
  const { isOnline } = useIsOnline();
  const forgotPassword = async (email: string): Promise<void> => {
    if (!isOnline) return;
    try {
      await postRequest(`${PasswordResetEndpoint}/forgot`, {
        email,
      });
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const resetPassword = async (
    email: string,
    token: string,
    password: string,
  ): Promise<string | null> => {
    if (!isOnline) return 'you are offline';
    try {
      const response: ServerResponse<string | null> = await postRequest(
        `${PasswordResetEndpoint}/reset`,
        {
          email,
          token,
          password,
        },
      );
      if (response.status === 200) {
        return null;
      }
      return response.data ?? 'An error occurred';
    } catch (error) {
      console.error(error);
      return 'An error occurred';
    }
  };

  return {
    forgotPassword,
    resetPassword,
  };
};

export default usePasswordReset;
