/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RuleIcon from '@mui/icons-material/Rule';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { ProductCategoryType } from '../../../../common/enums';
import { unfinishedOrdersForStation } from '../../../../common/orderFunctions';
import { sortOrdersById } from '../../../../common/sortFunctions';
import { Staff, StaffOrder, StaffOrderItem } from '../../../../common/types';
import InfoCard from '../../../../components/card/InfoCard';
import useAlert from '../../../../hooks/useAlert';
import { IndicatorBox } from '../../components/IndicatorBox';
import {
  getOrderPosition,
  handleFinishOrder,
  handleStartStopOrder,
  shiftOrderItems,
} from '../../functions/orderFunctions';
import {
  allColdDrinksFinished,
  allColdDrinksPlaced,
  allColdDrinksStarted,
  allFoodFinished,
  allFoodPending,
  allFoodStarted,
  allHotCoffeeOrTeaFinished,
  allHotCoffeeOrTeaPlaced,
  allHotCoffeeOrTeaStarted,
  sortStationOrders,
} from '../../functions/stationFunctions';
import DraggableDrinkOrderCard from './DraggableDrinkOrderCard';
import StationHeader from './StationHeader';

interface PrimaryStationProps {
  primaryOrders: StaffOrder[];
  user: Staff;
}

export default function PrimaryStation({
  primaryOrders,
  user,
}: PrimaryStationProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();
  const [draggableOrders, setDraggableOrders] = useState<StaffOrder[]>([]);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    const incomingOrdersAreDifferent = !isEqual(
      sortOrdersById(primaryOrders),
      sortOrdersById(draggableOrders),
    );
    const incomingOrders = [...primaryOrders];
    if (incomingOrdersAreDifferent) {
      incomingOrders.forEach((incomingOrder) => {
        const draggableOrder = draggableOrders.find(
          (dragOrder) => dragOrder.id === incomingOrder.id,
        );
        incomingOrder.orderItems.forEach((incomingOrderItem) => {
          const foundDraggableOrderItem = draggableOrder?.orderItems.find(
            (dragOrderItem) => dragOrderItem.id === incomingOrderItem.id,
          );
          if (foundDraggableOrderItem) {
            incomingOrderItem.queueOrder = foundDraggableOrderItem.queueOrder;
          }
        });
      });
      setDraggableOrders([...primaryOrders]);
      setIsLoading(false);
    }
  }, [primaryOrders]);

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id === over.id) return;

    setDraggableOrders((prevOrders) => {
      const originalPosition = getOrderPosition(active.id, draggableOrders);
      const newPosition = getOrderPosition(over.id, draggableOrders);
      const newArray = arrayMove(prevOrders, originalPosition, newPosition);
      shiftOrderItems(
        newArray,
        [ProductCategoryType.HotCoffee, ProductCategoryType.Tea],
        showAlert,
      );
      return newArray;
    });
  }

  const sortedFilteredOrders = sortStationOrders(
    unfinishedOrdersForStation(draggableOrders, user.stationId),
    [ProductCategoryType.HotCoffee, ProductCategoryType.Tea],
    {
      isAllFinishedFn: allHotCoffeeOrTeaFinished,
      isAllStartedFn: allHotCoffeeOrTeaStarted,
    },
  ).map((order) => {
    const isAllHotCoffeePlaced = allHotCoffeeOrTeaPlaced(order.orderItems);
    const isAllHotCoffeeStarted = allHotCoffeeOrTeaStarted(order.orderItems);
    const isAllHotCoffeeFinished = allHotCoffeeOrTeaFinished(order.orderItems);

    return {
      order,
      isAllHotCoffeePlaced,
      isAllHotCoffeeStarted,
      isAllHotCoffeeFinished,
    };
  });

  return (
    <DndContext
      collisionDetection={closestCorners}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <Grid
        size={{ xs: 10, md: 8 }}
        sx={{
          padding: theme.spacing(1),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StationHeader />
        <Divider />
        {sortedFilteredOrders.length === 0 ? (
          <InfoCard
            Icon={NotificationsIcon}
            title="No Results Found"
            subtitle="Try refreshing your page or check back later."
          />
        ) : (
          <Box
            sx={{
              overflowY: 'auto',
              height: 'calc(100vh - 64px)',
            }}
          >
            <Grid container spacing={1} sx={{ padding: 3 }}>
              {sortedFilteredOrders.map(
                (
                  {
                    order,
                    isAllHotCoffeePlaced,
                    isAllHotCoffeeStarted,
                    isAllHotCoffeeFinished,
                  },
                  index,
                ) => {
                  return (
                    <Grid size={12} key={`${order.id}-${index}`}>
                      <Paper
                        elevation={8}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          padding: 0,
                          margin: 0,
                          height: '100%',
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.common.deepblue,
                        }}
                      >
                        {isAllHotCoffeeFinished ? (
                          <>
                            <Stack
                              sx={{
                                backgroundColor: theme.palette.common.grey,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: 1.5,
                              }}
                            >
                              <Stack
                                sx={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  padding: 0,
                                }}
                              >
                                <Stack
                                  sx={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Stack
                                    sx={{ padding: 1, alignItems: 'center' }}
                                  >
                                    <RuleIcon />
                                  </Stack>
                                  <Divider orientation="vertical" flexItem />
                                  <Typography
                                    variant="h3"
                                    sx={{ marginLeft: theme.spacing(1) }}
                                  >
                                    {order.customerName}
                                  </Typography>
                                </Stack>

                                <Stack sx={{ paddingRight: 2 }}>
                                  {order.orderItems.some(
                                    (item: StaffOrderItem) =>
                                      item.productCategoryType ===
                                      ProductCategoryType.Food,
                                  ) && (
                                    <IndicatorBox
                                      orderItems={order.orderItems}
                                      label="Food"
                                      allStartedFn={allFoodStarted}
                                      allFinishedFn={allFoodFinished}
                                      allPendingFn={allFoodPending}
                                    />
                                  )}
                                  {order.orderItems.some(
                                    (item: StaffOrderItem) =>
                                      item.productCategoryType ===
                                      ProductCategoryType.ColdDrinks,
                                  ) && (
                                    <IndicatorBox
                                      orderItems={order.orderItems}
                                      label="Cold Drinks"
                                      allStartedFn={allColdDrinksStarted}
                                      allFinishedFn={allColdDrinksFinished}
                                      allPendingFn={allColdDrinksPlaced}
                                    />
                                  )}
                                </Stack>
                              </Stack>
                            </Stack>
                          </>
                        ) : (
                          <SortableContext
                            items={draggableOrders as any} //need this to be any as dnd-kit is not typed.
                            strategy={verticalListSortingStrategy}
                          >
                            <DraggableDrinkOrderCard
                              order={order}
                              isAllDrinksPlaced={isAllHotCoffeePlaced}
                              isAllDrinksStarted={isAllHotCoffeeStarted}
                              isColdDrinks={false}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                              handleStartOrder={() => {
                                handleStartStopOrder(
                                  order,
                                  true,
                                  [
                                    ProductCategoryType.HotCoffee,
                                    ProductCategoryType.Tea,
                                  ],
                                  showAlert,
                                  setIsLoading,
                                );
                              }}
                              handleStopOrder={() => {
                                handleStartStopOrder(
                                  order,
                                  false,
                                  [
                                    ProductCategoryType.HotCoffee,
                                    ProductCategoryType.Tea,
                                  ],
                                  showAlert,
                                  setIsLoading,
                                );
                              }}
                              handleFinishOrder={() =>
                                handleFinishOrder(
                                  order,
                                  [
                                    ProductCategoryType.HotCoffee,
                                    ProductCategoryType.Tea,
                                  ],
                                  showAlert,
                                  setIsLoading,
                                )
                              }
                            />
                          </SortableContext>
                        )}
                      </Paper>
                    </Grid>
                  );
                },
              )}
            </Grid>
          </Box>
        )}
      </Grid>
    </DndContext>
  );
}
