import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { passwordName, userName } from '../../../common/constants';
import { Staff } from '../../../common/types';
import useAlert from '../../../hooks/useAlert';
import useLoadingModal from '../../../hooks/useLoadingModal';
import StaffLoginCard from './components/StaffLoginCard';

export interface StaffLoginProps {
  login: (email: string, password: string) => Promise<Staff | null>;
  logout: () => void;
}
export default function StaffLogin({ login }: StaffLoginProps) {
  const { showAlert } = useAlert();
  const [showLogin, setShowLogin] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { setLoading, loading } = useLoadingModal();

  useEffect(() => {
    setTimeout(() => setShowLogin(true), 2000);
  }, []);

  async function onLogin(form: { [key: string]: string }) {
    setLoading(true);
    const loggedIn = await login(form[userName], form[passwordName]);
    if (!loggedIn) {
      showAlert('Login failed!', 'error');
    }
    setLoading(false);
  }

  return (
    <>
      <Typography
        variant="h2"
        fontWeight={700}
        paddingBottom={3}
        color="primary"
      >
        TPGE Staff Portal
      </Typography>
      {showLogin ? (
        <Paper
          elevation={8}
          sx={{ padding: '3em', width: isMobile ? '90%' : '50%' }}
        >
          <StaffLoginCard onLogin={onLogin} loading={loading} />
        </Paper>
      ) : (
        false
      )}
    </>
  );
}
