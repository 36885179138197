import { create } from 'zustand';
import LocalStorageSvc, { SELECTED_SITE_KEY } from '../common/localStorageSvc';
import siteData, { EstimatedWaitTime } from '../api/site';
import { ServerResponse } from '../common/types';

export type SelectedSiteType = {
  pollInterval: NodeJS.Timeout | null;
  selectedSiteId: number | null;
  setSelectedSiteId: (id: number | null) => void;
  estimatedWaitTimeInMinutes: number | null;
  updateEstimatedWaitTime: () => void;
  startPolling: () => void;
  stopPolling: () => void;
};

const selectedSiteId = LocalStorageSvc.load<number>(SELECTED_SITE_KEY);

const useSelectedSite = create<SelectedSiteType>((set, get) => ({
  pollInterval: null,
  selectedSiteId: selectedSiteId as number | null,
  setSelectedSiteId: (id: number | null) => {
    const { startPolling, stopPolling, selectedSiteId } = get();
    if (id && id !== selectedSiteId) {
      stopPolling();
      LocalStorageSvc.save<number>(SELECTED_SITE_KEY, id);
      set(() => ({ selectedSiteId: id }));
      if (id) {
        startPolling();
      }
    }
  },
  estimatedWaitTimeInMinutes: null,
  updateEstimatedWaitTime: async () => {
    try {
      const id: number | null = get().selectedSiteId;
      if (id) {
        const result: ServerResponse<EstimatedWaitTime> =
          await siteData.getEstimatedWaitTime(id);
        if (result.data !== null) {
          const data: EstimatedWaitTime = result.data;
          if (!isNaN(data.estimatedWaitTimeInMinutes)) {
            set(() => ({
              estimatedWaitTimeInMinutes: data.estimatedWaitTimeInMinutes,
            }));
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  },
  startPolling: () => {
    const { pollInterval, updateEstimatedWaitTime } = get();
    if (!pollInterval) {
      const newPollInterval = setInterval(updateEstimatedWaitTime, 60000);
      set(() => ({ pollInterval: newPollInterval }));
      updateEstimatedWaitTime();
    }
  },
  stopPolling: () => {
    const { pollInterval } = get();
    if (pollInterval) {
      clearInterval(pollInterval);
      set(() => ({ pollInterval: null }));
    }
  },
}));

export default useSelectedSite;
