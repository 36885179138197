import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Stations } from '../../../../common/enums';
import {
  calculateGSTBreakdown,
  displayPrice,
} from '../../../../common/moneyFunctions';
import { Order } from '../../../../common/types';

interface OrderPricingProps {
  orderData: Order;
  changeOrderStation: (stationName: string) => void;
  assignedStation: () => string;
  submitOrder: (customerName: string) => void;
  customerName: string;
}

export default function OrderPricing({
  orderData,
  changeOrderStation,
  assignedStation,
  submitOrder,
  customerName,
}: OrderPricingProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  const isOrderValid =
    orderData.orderItems.length > 0 && customerName.trim() !== '';
  const noCustomerName = !customerName.trim();
  const tooltipTitle = !isOrderValid
    ? `${orderData.orderItems.length === 0 ? `Add items to your order${noCustomerName ? ' & ' : '.'}` : ''}${noCustomerName ? 'Enter a customer name.' : ''}`
    : '';

  const handleButtonClick = () => {
    if (isOrderValid) {
      submitOrder(customerName);
    } else {
      setShowTooltip(true);
    }
  };

  const station = assignedStation();

  const { total, subtotal, gst } = calculateGSTBreakdown(
    orderData.orderItems || [],
  );

  return (
    <>
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1">Subtotal</Typography>
          <Typography variant="body1">{displayPrice(subtotal)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1">GST (10%)</Typography>
          <Typography variant="body1">{displayPrice(gst)}</Typography>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Total</Typography>
          <Typography variant="h6">{displayPrice(total)}</Typography>
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant={station === Stations.StationOne ? 'contained' : 'outlined'}
            onClick={() => changeOrderStation(Stations.StationOne)}
          >
            Station 1
          </Button>
          <Button
            variant={station === Stations.StationTwo ? 'contained' : 'outlined'}
            onClick={() => changeOrderStation(Stations.StationTwo)}
          >
            Station 2
          </Button>
        </Stack>

        <Tooltip
          title={tooltipTitle}
          arrow
          open={showTooltip}
          onClose={() => setShowTooltip(false)}
        >
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleButtonClick}
          >
            Place Order
          </Button>
        </Tooltip>
      </Stack>
    </>
  );
}
