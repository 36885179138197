import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns/format';
import { Role } from '../../../../common/enums';
import { StaffAccount } from '../../../../common/types';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';

interface TableProps {
  isLoading: boolean;
  paginatedAccounts: StaffAccount[];
  editAccount: (account: StaffAccount) => void;
  statusToggle: (account: StaffAccount) => void;
}

export default function AccountManagementTable({
  isLoading,
  paginatedAccounts,
  editAccount,
  statusToggle,
}: TableProps) {
  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    callback: () => void,
  ) => {
    e.stopPropagation();
    callback();
  };

  return (
    <Table sx={{ maxWidth: '100%', overflowX: 'auto' }} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Created Date</TableCell>
          <TableCell>Enabled</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <LoadingSpinner isLoading={isLoading} isTable colSpan={6}>
          {paginatedAccounts.map((account) => {
            const createdDate = account.createdAt
              ? format(account.createdAt, 'dd/MM/yy')
              : '';
            return (
              <TableRow
                key={account.email}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <TableCell>{account.email}</TableCell>
                <TableCell>
                  {account.roleName === Role.Admin ? 'Admin' : 'Staff'}
                </TableCell>
                <TableCell>{createdDate}</TableCell>
                <TableCell>{account.isActive ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={(e) =>
                      handleButtonClick(e, () => editAccount(account))
                    }
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={(e) =>
                      handleButtonClick(e, () => statusToggle(account))
                    }
                    color={account.isActive ? 'error' : 'primary'}
                  >
                    {account.isActive ? 'Disable' : 'Enable'}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </LoadingSpinner>
      </TableBody>
    </Table>
  );
}
