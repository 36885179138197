import WarningOutlined from '@mui/icons-material/WarningOutlined';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { endOfWeek } from 'date-fns/endOfWeek';
import { startOfWeek } from 'date-fns/startOfWeek';
import { useEffect, useState } from 'react';
import { Bar, Label, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import reports from '../../../../api/report';
import { ReportView } from '../../../../common/enums.tsx';
import { TpgeComponentType } from '../../../../common/types.tsx';
import InfoCard from '../../../../components/card/InfoCard.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import useComponentTypes from '../../../../datastore/useComponentTypes.tsx';
import useAlert from '../../../../hooks/useAlert';
import useRequest from '../../../../hooks/useRequest';
import ReportHeader from './ReportHeader.tsx';
import StyledBarChartWrapper from './StyledBarChartWrapper.tsx';
import StyledTableContainer from './StyledTableContainer.tsx';

export default function ComponentConsumptionReport() {
  const theme = useTheme();
  const { componentTypes = [] } = useComponentTypes();
  const [startDate, setStartDate] = useState<Date | null>(() =>
    startOfWeek(new Date(), { weekStartsOn: 0 }),
  );
  const [endDate, setEndDate] = useState<Date | null>(() =>
    endOfWeek(new Date(), { weekStartsOn: 0 }),
  );
  const [tpgeComponentType, setTpgeComponentType] =
    useState<TpgeComponentType | null>(null);
  const {
    data,
    error,
    loading: isLoading,
    execute: fetchData,
  } = useRequest(
    () =>
      reports.getComponentConsumption(
        tpgeComponentType?.name || '',
        startDate,
        endDate,
      ),
    [startDate, endDate, tpgeComponentType],
  );
  useEffect(() => {
    if (error) {
      console.error(error);
      showAlert('Failed to get report, please refresh and try again.', 'error');
    }
  }, [error]);
  const [view, setView] = useState<ReportView.Chart | ReportView.Table>(
    ReportView.Chart,
  );
  const { showAlert } = useAlert();

  function handleViewChange(
    _: React.MouseEvent<HTMLElement>,
    newView: ReportView.Chart | ReportView.Table | null,
  ) {
    if (newView !== null) {
      setView(newView);
    }
  }

  const chartData = data ?? [];
  const totalQuantity = chartData.reduce(
    (sum, row) => sum + row.totalQuantity,
    0,
  );

  const selectedComponentTypeId = tpgeComponentType?.id || 0;

  return (
    <>
      <ReportHeader
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        view={view}
        handleViewChange={handleViewChange}
        fetchData={fetchData}
      >
        <TextField
          select
          size="small"
          value={selectedComponentTypeId}
          label="Component Type"
          onChange={(e) =>
            setTpgeComponentType(
              componentTypes.find((c) => c.id === +e.target.value) || null,
            )
          }
        >
          <MenuItem value={0} disabled>
            Select Type
          </MenuItem>
          {componentTypes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
        </TextField>
      </ReportHeader>
      {tpgeComponentType ? (
        <LoadingSpinner isLoading={isLoading}>
          <>
            {view === ReportView.Chart ? (
              <StyledBarChartWrapper data={chartData}>
                <XAxis dataKey="type">
                  <Label
                    value="Component"
                    offset={-5}
                    position="insideBottom"
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value="ml"
                    angle={-90}
                    position="insideLeft"
                    style={{ textAnchor: 'middle' }}
                  />
                </YAxis>
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar
                  dataKey="totalQuantity"
                  fill={theme.palette.primary.main}
                  name="Quantity"
                />
              </StyledBarChartWrapper>
            ) : (
              <StyledTableContainer>
                <Table size="medium" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{tpgeComponentType.name} Type</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell>Unit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {chartData.map((row) => (
                      <TableRow key={row.type}>
                        <TableCell component="th" scope="row">
                          {row.type}
                        </TableCell>
                        <TableCell align="right">
                          {row.totalQuantity.toFixed(2)}
                        </TableCell>
                        <TableCell>{row.unit}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 'bold' }}
                      >
                        Total
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        {totalQuantity.toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {chartData.length > 0 ? chartData[0].unit : ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </StyledTableContainer>
            )}
          </>
        </LoadingSpinner>
      ) : (
        <Stack
          flexDirection="row"
          sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <InfoCard
            title="Nothing selected"
            Icon={WarningOutlined}
            subtitle="Please select a component"
          />
        </Stack>
      )}
    </>
  );
}
