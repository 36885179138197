import { PopularProductEndpoint } from '../common/endpoints';
import { PopularProduct, ServerResponse } from '../common/types';
import { getRequest, postRequest } from './requests';

export async function list(activeOnly = false) {
  const url = activeOnly
    ? `${PopularProductEndpoint}?activeOnly=true`
    : PopularProductEndpoint;
  const result: ServerResponse<PopularProduct[]> = await getRequest(url);
  return result;
}
export async function createOrUpdate(popularProducts: PopularProduct[]) {
  const result: ServerResponse<PopularProduct[]> = await postRequest(
    PopularProductEndpoint,
    popularProducts,
  );
  return result;
}
export default { list, createOrUpdate };
