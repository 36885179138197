import { create } from 'zustand';
import popularProductApi from '../api/popularProduct';
import { PopularProduct } from '../common/types';

type PopularProductsStore = {
  hasLoaded: boolean;
  showDisabled: boolean;
  setShowDisabled: (showDisabled: boolean) => void;
  popularProducts: PopularProduct[];
  loading: boolean;
  error: unknown | null;
  fetchPopularProducts: () => void;
};

const usePopularProducts = create<PopularProductsStore>((set, get) => ({
  hasLoaded: false,
  showDisabled: false,
  popularProducts: [],
  loading: false,
  error: null,
  fetchPopularProducts: async () => {
    if (get().loading) {
      return;
    }
    try {
      const { showDisabled } = get();
      const activeOnly = !showDisabled;
      set({ loading: true, hasLoaded: true });
      const result = await popularProductApi.list(activeOnly);
      set({ popularProducts: result.data ?? [], loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
  setShowDisabled: (showDisabled: boolean) => {
    set({ showDisabled });
    get().fetchPopularProducts();
  },
}));

export default usePopularProducts;

// make that first call without having to useEffect in a component
if (!usePopularProducts.getState().hasLoaded) {
  usePopularProducts.getState().fetchPopularProducts();
}
