import { ServerResponse, Size } from '../common/types';
import { getRequest } from './requests';

const baseUrl = 'sizes';

const listSizes = async (): Promise<ServerResponse<Size[]>> => {
  const result: ServerResponse<Size[]> = await getRequest(baseUrl);
  return result;
};

export default { listSizes };
