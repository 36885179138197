import { AlertColor } from '@mui/material/Alert';
import ordersApi from '../../../api/order';
import { OrderItemStatus, ProductCategoryType } from '../../../common/enums';
import { StaffOrder, StaffOrderItem } from '../../../common/types';

export function getOrderPosition(
  orderId: number,
  draggableOrders: StaffOrder[],
): number {
  return draggableOrders.findIndex((order) => order.id === orderId);
}

export async function handleFinishOrder(
  order: StaffOrder,
  productCategoryTypes: ProductCategoryType[],
  showAlert: (message: string, severity: AlertColor) => void,
  setIsLoading: (loading: boolean) => void,
) {
  const updatedOrderItems = order.orderItems
    .map((orderItem) => {
      order.finishTime = new Date();
      if (
        productCategoryTypes.includes(
          orderItem.productCategoryType as ProductCategoryType,
        )
      ) {
        return {
          ...orderItem,
          status: OrderItemStatus.Finished,
        };
      }
      return orderItem;
    })
    .map((m) => ({ id: m.id, status: m.status }));

  const result = await ordersApi.startStopFinishOrder({
    id: order.id,
    startTime: order.startTime,
    finishTime: new Date(),
    paymentType: null,
    softDelete: false,
    orderItems: updatedOrderItems,
  });

  if (result.status === 200) {
    showAlert('Order updated successfully', 'success');
  } else {
    showAlert('Order update failed', 'error');
  }

  setIsLoading(false);
}

export async function handleStartStopOrder(
  order: StaffOrder,
  start: boolean,
  productCategoryTypes: ProductCategoryType[],
  showAlert: (message: string, severity: AlertColor) => void,
  setIsLoading: (loading: boolean) => void,
) {
  const updatedOrderItems = order.orderItems
    .map((orderItem) => {
      if (
        productCategoryTypes.includes(
          orderItem.productCategoryType as ProductCategoryType,
        )
      ) {
        return {
          ...orderItem,
          status: start ? OrderItemStatus.Started : OrderItemStatus.Placed,
        };
      }
      return orderItem;
    })
    .map((m) => ({ id: m.id, status: m.status }));

  const result = await ordersApi.startStopFinishOrder({
    id: order.id,
    finishTime: null,
    startTime: start ? new Date() : null,
    paymentType: null,
    softDelete: false,
    orderItems: updatedOrderItems,
  });

  if (result.status !== 200) {
    const failMessage = start ? 'Failed to Start' : 'Failed to Stop';
    showAlert(failMessage, 'error');
    setIsLoading(false);
    return;
  }

  const successMessage = start ? 'Order Started' : 'Order Stopped';
  showAlert(successMessage, 'success');
}

export async function shiftOrderItems(
  ordersToShift: StaffOrder[],
  productCategoryTypes: ProductCategoryType[],
  showAlert: (message: string, severity: AlertColor) => void,
) {
  ordersToShift.forEach((order, index) => {
    order.orderItems.forEach((item: StaffOrderItem) => {
      if (
        productCategoryTypes.includes(
          item.productCategoryType as ProductCategoryType,
        )
      ) {
        item.queueOrder = index;
      }
    });
  });
  const result = await ordersApi.changeQueueOrders({
    productCategories: productCategoryTypes,
    orderDtos: ordersToShift.map((order) => ({
      id: order.id,
      orderItems: order.orderItems.map((item) => ({
        id: item.id,
        queueOrder: item.queueOrder,
      })),
    })),
  });

  if (result.status !== 200) {
    showAlert('Failed to update orders queue order', 'warning');
    console.error('Failed to update orders queue order');
  }
}
