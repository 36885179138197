import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface StyledSelectProps {
  sx?: SxProps;
  value?: string | number | null;
  label?: string;
  shrink?: boolean;
  children: JSX.Element | JSX.Element[];
  disabled?: boolean;
  disabledOnDark?: boolean;
  onChange: (value: string | number | null) => void;
}

export default function StyledSelect({
  sx,
  value,
  onChange,
  label,
  shrink = true,
  children,
  disabled = false,
  disabledOnDark = false,
}: StyledSelectProps) {
  const theme = useTheme();

  return (
    <FormControl size="small">
      <InputLabel id={label} shrink={shrink}>
        {label}
      </InputLabel>
      <Select
        labelId={label}
        label={label}
        value={value}
        disabled={disabled}
        IconComponent={KeyboardArrowDownIcon}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          backgroundColor: disabled
            ? disabledOnDark
              ? '#bdbdbd'
              : theme.palette.action.disabledBackground
            : theme.palette.background.default,
          color: disabled
            ? theme.palette.text.disabled
            : theme.palette.text.primary,
          minWidth: '12em',
          '& .MuiSvgIcon-root': {
            fontSize: '1.5em',
            color: disabled
              ? theme.palette.action.disabled
              : theme.palette.text.primary,
          },
          ...sx,
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
}
