import Add from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Fragment, useState } from 'react';
import { ProductVariantTypeEnum } from '../../common/enums';
import {
  calculatePriceDifferenceWithSign,
  productVariantPriceAdjustment,
} from '../../common/moneyFunctions';
import { OrderItem } from '../../common/types';
import { OrderItemViewModel } from '../../viewModels/OrderItemViewModel';
import StyledButton from '../button/StyledButton';
import {
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';

type AddItemToOrderModalProps = {
  item: OrderItem;
  onClose: (item: OrderItem | null) => void;
  submitText?: string;
};

export default function OrderItemVariationsModal({
  item,
  onClose,
  submitText = 'Add to cart',
}: AddItemToOrderModalProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [vm, setViewModel] = useState<OrderItemViewModel>(
    new OrderItemViewModel(item),
  );

  const addToCart = () => onClose(vm.toItem());

  const addVariation = (variationId: number | string) => {
    const tmp = vm.addVariant(variationId);
    setViewModel(tmp);
  };

  const removeVariation = (variationId: number | string | undefined) => {
    const tmp = vm.removeVariant(variationId);
    setViewModel(tmp);
  };

  const setNotes = (notes: string) => {
    const tmp = vm.setNotes(notes);
    setViewModel(tmp);
  };

  const setSize = (sizeId: string | number) => {
    const tmp = vm.setSize(sizeId);
    setViewModel(tmp);
  };

  if (
    vm.selectedBean &&
    vm.allowsBeansVariations &&
    !vm.hasSelectedVariant(vm.selectedBean)
  ) {
    addVariation(vm.selectedBean.id);
  }

  const handleOtherVariationChange = (event: SelectChangeEvent<string>) => {
    const selectedVariationId = event.target.value;
    addVariation(selectedVariationId);
  };

  const basePrice = vm.selectedSize?.basePrice || 0;

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      onClose={() => onClose(null)}
      scroll={isMobile ? 'paper' : 'body'}
      PaperProps={{
        sx: {
          maxHeight: isMobile ? 'calc(100% - 64px)' : 'none',
        },
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" fontWeight="bold">
            {item.productName}
          </Typography>
          <IconButton onClick={() => onClose(null)}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers={isMobile}>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {vm.allowsSizeVariations && (
            <FormControl fullWidth>
              <InputLabel>Size</InputLabel>
              <Select
                value={vm.selectedSize.id}
                label="Size"
                onChange={(e) => setSize(e.target.value)}
              >
                {item.sizeOptions
                  .filter((sizeOption) => sizeOption.isActive)
                  .map((sizeOption) => (
                    <MenuItem value={sizeOption.id} key={sizeOption.id}>
                      {sizeOption.sizeName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {vm.showBeansVariations && (
            <FormControl fullWidth>
              <InputLabel>Beans</InputLabel>
              <Select
                value={vm.selectedBean?.id || ''}
                label="Beans"
                onChange={(e) => {
                  addVariation(e.target.value);
                }}
              >
                {vm.beansVariations.map((beansOption) => (
                  <MenuItem value={beansOption.id} key={beansOption.id}>
                    {beansOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {vm.allowsMilkVariations && (
            <FormControl fullWidth>
              <InputLabel>Milk</InputLabel>
              <Select
                value={vm.selectedMilk?.id || OrderItemViewModel.NO_MILK}
                label="Milk"
                onChange={(e) =>
                  addVariation(
                    e.target.value === OrderItemViewModel.NO_MILK
                      ? e.target.value
                      : +e.target.value,
                  )
                }
              >
                <MenuItem value={OrderItemViewModel.NO_MILK}>
                  {OrderItemViewModel.NO_MILK}
                </MenuItem>
                {vm.milkVariations.map((variation) => (
                  <MenuItem value={variation.id} key={variation.id}>
                    {variation.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {vm.allowsShotsVariation && !!vm.selectedShot && (
            <TextField
              fullWidth
              label="Xtra Shot"
              slotProps={{
                htmlInput: {
                  style: { textAlign: 'center' },
                },
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => removeVariation(vm.selectedShot!.id)}
                        disabled={vm.shotCount === 0}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => addVariation(vm.selectedShot!.id)}
                      >
                        <Add />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
              value={vm.shotCount}
            />
          )}
          {vm.allowsOtherVariations && (
            <FormControl fullWidth>
              <InputLabel>Other</InputLabel>
              <Select
                value=""
                label="Other"
                onChange={handleOtherVariationChange}
              >
                {vm.otherVariations.map((variation) => (
                  <MenuItem value={variation.id} key={variation.id}>
                    {variation.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            label="Notes"
            value={vm.notes || ''}
            onChange={(e) => setNotes(e.target.value)}
            slotProps={{
              htmlInput: { maxLength: 50 },
              inputLabel: { shrink: true },
            }}
            fullWidth
            margin="normal"
          />
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="bold">Base Price</Typography>
            </Grid>
            <Grid size={3}>
              <Typography
                fontWeight="bold"
                textAlign="right"
              >{`$${basePrice.toFixed(2)}`}</Typography>
            </Grid>

            {Object.keys(vm.variationsWithQty).map((variantId, idx) => {
              const variantWithQty = vm.variationsWithQty[variantId];
              const nameChunks = [variantWithQty.name];
              if (variantWithQty.quantity > 1) {
                nameChunks.push(`x ${variantWithQty.quantity}`);
              }
              return (
                <Fragment key={`${variantId}_${idx}`}>
                  <Grid size={8}>
                    <Typography>{`${nameChunks.join(' ')}`}</Typography>
                  </Grid>
                  <Grid size={3}>
                    <Typography textAlign="right">
                      {calculatePriceDifferenceWithSign(
                        productVariantPriceAdjustment(
                          variantWithQty,
                          vm.selectedSize,
                        )
                          .times(variantWithQty.quantity)
                          .toFixed(2),
                      )}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    {[
                      ProductVariantTypeEnum.Other,
                      ProductVariantTypeEnum.Shot,
                    ].includes(
                      variantWithQty.productVariantTypeName as ProductVariantTypeEnum,
                    ) && (
                      <IconButton
                        onClick={() => removeVariation(variantId)}
                        sx={{ padding: 0, marginLeft: 1.5 }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Grid>
                </Fragment>
              );
            })}
            <Grid size={8}>
              <Typography fontWeight="bold">Total</Typography>
            </Grid>
            <Grid size={3}>
              <Typography
                fontWeight="bold"
                textAlign="right"
              >{`$${vm.total.toFixed(2)}`}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <StyledButton variant="contained" fullWidth onClick={addToCart}>
          {submitText}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}
