import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useState } from 'react';
import { createComponent, updateComponent } from '../../../api/components';
import { hasAdminRole } from '../../../common/roleFunctions.tsx';
import {
  TpgeComponent,
  TpgeComponentType,
  TPGEMediaQuery,
} from '../../../common/types.tsx';
import useComponents from '../../../datastore/useComponents.tsx';
import useComponentTypes from '../../../datastore/useComponentTypes.tsx';
import useAlert from '../../../hooks/useAlert.tsx';
import useAPI from '../../../hooks/useAPI.tsx';
import useTPGEMediaQuery from '../../../hooks/useTPGEMediaQuery.tsx';
import { AdminHeader } from '../components/AdminHeader.tsx';
import AdminPageContent from '../components/AdminPageContent.tsx';
import AdminPagination from '../components/AdminPagination.tsx';
import ComponentModal from './components/ComponentModal.tsx';
import { ComponentsFilter } from './components/ComponentsFilter.tsx';
import { ComponentsTable } from './components/ComponentsTable.tsx';

const ComponentsPerPage: { [key in TPGEMediaQuery]: number } = {
  xlarge: 9,
  large: 7,
  medium: 5,
  small: 4,
};

const filterData = (
  components: TpgeComponent[] = [],
  showDisabled = false,
  filterText = '',
  componentTypeId: number | null = null,
): TpgeComponent[] => {
  const lowerFilter = filterText.toLowerCase();
  const data = components
    .filter(
      (component) =>
        (showDisabled || component.isActive === true) &&
        (filterText.length
          ? component.name.toLowerCase().includes(lowerFilter)
          : true) &&
        ((componentTypeId || 0) > 0
          ? component.componentType.id === componentTypeId
          : true),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return data;
};

const newObj = (): TpgeComponent => ({
  id: 0,
  name: '',
  unit: '',
  costPerUnit: 0,
  componentTypeId: 0,
  componentType: {} as TpgeComponentType,
  isActive: true,
  isDefault: false,
});

export default function ComponentsPage() {
  const { currentUser } = useAPI();
  const {
    components = [],
    loading: isLoadingComponents,
    fetchComponents: refetch,
    showDisabled,
    setShowDisabled,
  } = useComponents();
  const { componentTypes = [], loading: isLoadingComponentTypess } =
    useComponentTypes();
  const { showAlert } = useAlert();
  const mediaSize = useTPGEMediaQuery();
  const countPerPage = ComponentsPerPage[mediaSize];
  const [filterText, setFilterText] = useState<string>('');
  const [selectedComponentTypeId, setSelectedComponentTypeId] = useState<
    number | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [mutating, setMutating] = useState(false);
  const [mutateObj, setMutateObj] = useState<TpgeComponent | null>(null);

  const isLoading = isLoadingComponents || isLoadingComponentTypess || mutating;

  async function handleSave(obj: TpgeComponent) {
    const mode = obj.id > 0 ? 'edit' : 'create';
    const mutateFn = mode === 'edit' ? updateComponent : createComponent;
    const errorText = mode === 'edit' ? 'updating' : 'creating';
    const successText = mode === 'edit' ? 'updated' : 'created';
    setMutating(true);
    try {
      const result = await mutateFn(obj);
      if (result.status !== 200) {
        showAlert(
          `Error ${errorText} Component, refresh and try again`,
          'error',
        );
        return;
      } else {
        showAlert(`Component ${successText} successfully`, 'success');
        refetch();
      }
      if (mutateObj) {
        setMutateObj(null);
      }
    } catch (err) {
      showAlert(`Error ${errorText} Component, refresh and try again`, 'error');
    } finally {
      setMutating(false);
    }
  }

  async function toggleActive(obj: TpgeComponent) {
    const updated = { ...obj, isActive: !obj.isActive };
    handleSave(updated);
  }

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const filteredComponents = filterData(
    components || [],
    showDisabled,
    filterText,
    selectedComponentTypeId,
  );

  const totalNumberOfPages = Math.ceil(
    filteredComponents.length / countPerPage,
  );

  const paginatedData = filteredComponents.slice(
    (currentPage - 1) * countPerPage,
    currentPage * countPerPage,
  );

  if (!hasAdminRole(currentUser)) {
    return null;
  }

  return (
    <>
      <AdminHeader
        title="Components"
        description="Manage components"
        icon={<ShoppingCartIcon fontSize="large" sx={{ color: 'white' }} />}
        buttonText="New Component"
        buttonAction={() => setMutateObj(newObj())}
      />
      <AdminPageContent>
        <ComponentsFilter
          filterText={filterText}
          setFilterText={setFilterText}
          selectedComponentTypeId={selectedComponentTypeId}
          setSelectedComponentTypeId={(val = '') => {
            const parsed = parseInt(val);
            if (parsed && !isNaN(parsed)) {
              setSelectedComponentTypeId(parsed);
            } else {
              setSelectedComponentTypeId(null);
            }
            setCurrentPage(1);
          }}
          showDisabled={showDisabled}
          setShowDisabled={setShowDisabled}
          componentTypes={componentTypes || []}
        />
        <ComponentsTable
          isLoading={isLoading}
          paginatedData={paginatedData}
          showDisabled={showDisabled}
          statusToggle={toggleActive}
          editComponent={(component) => setMutateObj(component)}
        />
        <AdminPagination
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </AdminPageContent>
      {!!mutateObj && (
        <ComponentModal
          data={mutateObj}
          componentTypes={componentTypes || []}
          mode={mutateObj.id > 0 ? 'edit' : 'create'}
          onClose={() => setMutateObj(null)}
          onSave={handleSave}
          loading={isLoading}
        />
      )}
    </>
  );
}
