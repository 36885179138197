import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { TpgeComponentType } from '../../../../common/types';
import AdminPageFilter from '../../components/AdminPageFilter';

interface FilterSectionProps {
  filterText: string;
  setFilterText: (value: string) => void;
  componentTypes: TpgeComponentType[];
  selectedComponentTypeId: number | null;
  setSelectedComponentTypeId: (value: string) => void;
  showDisabled: boolean;
  setShowDisabled: (value: boolean) => void;
}

export function ComponentsFilter({
  filterText,
  setFilterText,
  componentTypes,
  selectedComponentTypeId,
  setSelectedComponentTypeId,
  showDisabled,
  setShowDisabled,
}: FilterSectionProps) {
  const typeFilterValue = selectedComponentTypeId
    ? `${selectedComponentTypeId}`
    : 'All';

  return (
    <AdminPageFilter filterText={filterText} setFilterText={setFilterText}>
      <>
        <TextField
          select
          size="small"
          label="Filter by Type"
          value={typeFilterValue}
          onChange={(e) => setSelectedComponentTypeId(e.target.value)}
          variant="outlined"
          sx={{ minWidth: 200, paddingRight: 2 }}
        >
          <MenuItem value="All">All</MenuItem>
          {componentTypes.map((ct) => (
            <MenuItem key={`componentType_${ct.id}`} value={`${ct.id}`}>
              {ct.name}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          control={
            <Switch
              checked={showDisabled}
              onChange={(e) => setShowDisabled(e.target.checked)}
            />
          }
          label="Show disabled components"
        />
      </>
    </AdminPageFilter>
  );
}
