import { useContext } from 'react';
import { StaffLoginContext } from '../contexts/StaffLoginContext';

const useStaffLogin = () => {
  const contextValue = useContext(StaffLoginContext);
  if (!contextValue) {
    throw new Error('StaffLoginContext is null');
  }
  return contextValue;
};

export default useStaffLogin;
