import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { TpgeComponentType } from '../../../../common/types';
import LoadingButton from '../../../../components/loadingButton/LoadingButton';
import AdminDialogTitle from '../../components/AdminDialogTitle';

interface ComponentTypesModalProps {
  data: TpgeComponentType;
  mode: 'create' | 'edit';
  onClose: () => void;
  onSave: (obj: TpgeComponentType) => void;
  loading: boolean;
}

export default function ComponentTypesModal({
  data,
  mode,
  onClose,
  onSave,
  loading,
}: ComponentTypesModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TpgeComponentType>({ defaultValues: data });

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <AdminDialogTitle>
        {mode === 'create' ? 'New' : 'Edit'} Component Type
      </AdminDialogTitle>
      <form onSubmit={handleSubmit(onSave)}>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={loading}
            loading={loading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
