import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React from 'react';
import StyledButton from '../../../components/button/StyledButton';
import DarkUpperSection from '../../../components/section/DarkUpperSection';

interface HeaderProps {
  title: string;
  description: string;
  icon?: React.ReactNode;
  buttonText?: string;
  buttonAction?: () => void;
  buttonDisabled?: boolean;
  dropdownOptions?: Array<{ label: string; value: string }>;
  selectedDropdownValue?: string;
  onDropdownChange?: (value: string) => void;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  extraContent?: React.ReactNode;
  sx?: SxProps;
}

export function AdminHeader({
  title,
  description,
  icon,
  buttonText,
  buttonAction,
  buttonDisabled = false,
  dropdownOptions,
  selectedDropdownValue,
  onDropdownChange,
  showBackButton = false,
  onBackButtonClick,
  extraContent,
  sx = {},
}: HeaderProps) {
  const theme = useTheme();

  const hasDropdown = !!dropdownOptions && !!onDropdownChange;
  const hasExtras = buttonText || extraContent || hasDropdown;

  return (
    <DarkUpperSection>
      <Stack
        sx={{
          height: '85px',
          paddingX: 0,
          paddingY: 2,
          gap: 0,
          alignItems: 'center',
          justifyContent: 'flex-start',
          ...sx,
        }}
        flexDirection="row"
      >
        {showBackButton && (
          <IconButton onClick={onBackButtonClick}>
            <ArrowBackIcon
              fontSize="large"
              sx={{ color: theme.palette.primary.contrastText, padding: 0 }}
            />
          </IconButton>
        )}
        {icon}
        <Typography
          variant="h2"
          fontWeight="bold"
          sx={{ paddingLeft: 1, paddingTop: 0 }}
          color={theme.palette.primary.contrastText}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          color={theme.palette.primary.contrastText}
          sx={{ paddingLeft: 2, paddingTop: 1 }}
        >
          {description}
        </Typography>
        {hasExtras && (
          <Stack sx={{ flex: 1, alignItems: 'end' }} className="foobarbaz">
            {!!buttonText && (
              <StyledButton
                variant="contained"
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
                onClick={buttonAction}
                disabled={buttonDisabled}
              >
                {buttonText}
              </StyledButton>
            )}
            {extraContent ?? null}
            {hasDropdown && (
              <FormControl>
                <Select
                  value={selectedDropdownValue}
                  size="small"
                  onChange={(e) => onDropdownChange(e.target.value)}
                  displayEmpty
                  sx={{
                    backgroundColor: 'common.white',
                    color: 'common.black',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'common.white',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'grey.300',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'common.black',
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select a report
                  </MenuItem>
                  {dropdownOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        color: 'common.black',
                        '&.Mui-selected': {
                          backgroundColor: 'action.selected',
                        },
                        '&:hover': {
                          backgroundColor: 'action.hover',
                        },
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        )}
      </Stack>
    </DarkUpperSection>
  );
}
