import { create } from 'zustand';
import productCategoryApi from '../api/productCategory';
import { ProductCategory } from '../common/types';

type ProductCategoryStore = {
  hasLoaded: boolean;
  productCategories: ProductCategory[];
  loading: boolean;
  error: unknown | null;
  fetchProductCategories: () => void;
};

const useProductCategories = create<ProductCategoryStore>((set, get) => ({
  hasLoaded: false,
  productCategories: [],
  loading: false,
  error: null,
  fetchProductCategories: async () => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await productCategoryApi.listProductCategories();
      set({
        productCategories: result.data ?? [],
        loading: false,
        error: null,
      });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
}));

export default useProductCategories;

// make that first call without having to useEffect in a component
if (!useProductCategories.getState().hasLoaded) {
  useProductCategories.getState().fetchProductCategories();
}
