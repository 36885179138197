import { ProductVariantEndpoint } from '../common/endpoints';
import { MutateProductVariant, ServerResponse } from '../common/types';
import { postRequest, putRequest } from './requests';

export async function updateProductVariant(
  productVariant: MutateProductVariant,
) {
  const result: ServerResponse<null> = await putRequest(
    `${ProductVariantEndpoint}/${productVariant.id}`,
    productVariant,
  );
  return result;
}

export async function createProductVariant(
  productVariant: MutateProductVariant,
) {
  const result: ServerResponse<MutateProductVariant> = await postRequest(
    ProductVariantEndpoint,
    productVariant,
  );
  return result;
}

export default {
  updateProductVariant,
  createProductVariant,
};
