import { useTheme } from '@mui/material/styles';
import { PageSection } from './PageSection';

type DarkUpperSectionProps = {
  children?: string | JSX.Element | JSX.Element[];
};

export default function DarkUpperSection({ children }: DarkUpperSectionProps) {
  const theme = useTheme();
  return (
    <PageSection
      sx={{
        backgroundColor: theme.palette.common.deepblue,
        paddingX: 2,
        margin: 0,
      }}
    >
      {children}
    </PageSection>
  );
}
