import { OrderItemStatus } from './enums';
import {
  Order,
  OrderItem,
  OrderItemQtyMap,
  OrderItemWithQty,
  OrderSummary,
  Product,
  ProductVariant,
  StaffOrder,
  StaffOrderItem,
  StaffSelectedVariant,
  WithQty,
} from './types';

export function getOrderItemKey(item: OrderItem | StaffOrderItem): string {
  // Sort variants for consistent grouping
  return [
    item.productName,
    item.productSizeId,
    ...item.selectedVariants
      .map(
        (variant) =>
          (variant as ProductVariant)?.name ||
          (variant as StaffSelectedVariant)?.productVariant.name,
      )
      .sort((a, b) => a.localeCompare(b)),
    item.notes || '',
  ].join('|');
}

const hasUnfinishedStationItems = (
  items: StaffOrderItem[],
  stationId: number,
) =>
  items.filter(
    (f) => f.stationId === stationId && f.status !== OrderItemStatus.Finished,
  ).length > 0;
export function unfinishedOrdersForStation(
  orders: StaffOrder[],
  stationId: number,
): StaffOrder[] {
  return orders.filter((order) =>
    hasUnfinishedStationItems(order.orderItems, stationId),
  );
}

export function groupOrderItems(
  items: OrderItem[],
  justByProductName = false,
): OrderItemWithQty[] {
  if (justByProductName) {
    // for hot drinks opposing station
    const mapped = items.reduce<OrderItemQtyMap>((out, item) => {
      if (!out[item.productName]) {
        out[item.productName] = { ...item, quantity: 0 };
      }
      out[item.productName].quantity += 1;
      return out;
    }, {});
    return Object.values(mapped).sort((a, b) =>
      a.productName.localeCompare(b.productName),
    );
  }

  // now we have to deal with matching everything down to comments
  const mapped = items.reduce<OrderItemQtyMap>((out, item) => {
    const key = getOrderItemKey(item);
    if (!out[key]) {
      out[key] = { ...item, quantity: 0 };
    }
    out[key].quantity += 1;
    return out;
  }, {});
  return Object.values(mapped).sort((a, b) => {
    const nameCheck = a.productName.localeCompare(b.productName);
    if (nameCheck) {
      return nameCheck;
    }
    return a.quantity - b.quantity;
  });
}

type StaffOrderItemWithQty = WithQty<StaffOrderItem>;
type StaffOrderItemWithQtyMap = Record<string, StaffOrderItemWithQty>;

export function groupStaffOrderItems(
  items: StaffOrderItem[],
  justByProductName = false,
): StaffOrderItemWithQty[] {
  if (justByProductName) {
    // for hot drinks opposing station
    const mapped = items.reduce<StaffOrderItemWithQtyMap>((out, item) => {
      if (!out[item.productName]) {
        out[item.productName] = { ...item, quantity: 0 };
      }
      out[item.productName].quantity += 1;
      return out;
    }, {});
    return Object.values(mapped).sort((a, b) =>
      a.productName.localeCompare(b.productName),
    );
  }

  // now we have to deal with matching everything down to comments
  const mapped = items.reduce<StaffOrderItemWithQtyMap>((out, item) => {
    const key = getOrderItemKey(item);
    if (!out[key]) {
      out[key] = { ...item, quantity: 0 };
    }
    out[key].quantity += 1;
    return out;
  }, {});
  return Object.values(mapped).sort((a, b) => {
    const nameCheck = a.productName.localeCompare(b.productName);
    if (nameCheck) {
      return nameCheck;
    }
    return a.quantity - b.quantity;
  });
}

export const mapProductToOrderItem = (product: Product): OrderItem => {
  const defaultSize =
    product.productSizes.find((ps) => ps.isDefault) || product.productSizes[0];

  const defaultVariants = product.productVariants.filter(
    (f) => f.isActive && f.isCore,
  );

  const item: OrderItem = {
    id: 0,
    productId: product.id,
    productName: product.name,
    queueOrder: 0,
    productPrice: product.price,
    productSizeId: defaultSize.id,
    sizeOptions: product.productSizes.filter((f) => f.isActive),
    productCategoryType: product.productCategory.name,
    selectedVariants: defaultVariants,
    notes: '',
    status: OrderItemStatus.Placed,
    stationId: 0,
    allowedVariants: product.productVariants.filter((f) => f.isActive),
  };

  return item;
};

export const mapOrderSummaryToOrder = (
  orderSummary: OrderSummary,
  products: Product[] = [],
): Partial<Order> => {
  const newOrder: Partial<Order> = {};
  newOrder.siteId = orderSummary.siteId;
  newOrder.requestedPickupTime = new Date();
  const mappedOrderItems: OrderItem[] = [];

  for (const orderItem of orderSummary.items) {
    const product = products.find((f) => f.id === orderItem.productId);
    if (!product) {
      throw new Error('missing product');
    }
    const newOrderItem = mapProductToOrderItem(product);
    newOrderItem.productSizeId =
      product.productSizes.find((f) => f.sizeName === orderItem.sizeName)?.id ||
      0;
    newOrderItem.selectedVariants = [];
    for (const variant of orderItem.items) {
      const matchingVariant: ProductVariant | undefined =
        newOrderItem.allowedVariants.find((f) => f.id === variant.variantId);
      if (!matchingVariant) {
        throw new Error('missing product variant');
      } else {
        for (let i = 0; i < variant.quantity; i++) {
          newOrderItem.selectedVariants = [
            ...newOrderItem.selectedVariants,
            matchingVariant,
          ];
        }
      }
    }
    for (let i = 0; i < orderItem.quantity; i++) {
      mappedOrderItems.push(newOrderItem);
    }
    newOrder.orderItems = mappedOrderItems;
  }

  return newOrder;
};
