import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { OrderSummary } from '../../../../common/types';
import StyledButton from '../../../../components/button/StyledButton';

interface OrderAgainCarouselProps {
  history: OrderSummary[];
  loading: boolean;
  onItemClick: (order: OrderSummary) => void;
}

export default function OrderAgainCarousel({
  history = [],
  loading,
  onItemClick,
}: OrderAgainCarouselProps) {
  if (loading) {
    return (
      <Stack sx={{ overflowX: 'auto', width: '100%', marginBottom: 1 }}>
        <Stack
          flexDirection="row"
          gap={1}
          sx={{
            minWidth: 'fit-content',
            paddingBottom: 1,
            marginBottom: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Skeleton
            width={225}
            height={75}
            sx={{ marginLeft: '1rem' }}
            variant="rounded"
          />
          <Skeleton width={225} height={75} variant="rounded" />
          <Skeleton width={225} height={75} variant="rounded" />
        </Stack>
      </Stack>
    );
  }

  if (!history.length) {
    return null;
  }

  return (
    <Stack
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
        width: '100%',
        marginBottom: 1,
      }}
    >
      <Stack
        flexDirection="row"
        sx={{ minWidth: 'fit-content', paddingBottom: 1 }}
      >
        {history.map((item, i, arr) => (
          <Paper
            role="button"
            key={item.orderId}
            elevation={0}
            sx={{
              width: 200,
              height: 100,
              borderRadius: 2,
              marginLeft: i === 0 ? 2 : 1,
              marginRight: i === arr.length - 1 ? 2 : 0,
              padding: 1,
              paddingY: 0,
            }}
          >
            <Stack
              justifyContent="flex-start"
              sx={{
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <List
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  paddingTop: 0,
                }}
                dense
              >
                {item.items.map((productSummary, idx) => {
                  const productSize = productSummary.sizeName;
                  const primaryText = productSize
                    ? `${productSize} ${productSummary.productName}`
                    : productSummary.productName;
                  const secondaryText = productSummary.items
                    .map(
                      ({ variantName, quantity }) =>
                        `${variantName} x ${quantity}`,
                    )
                    .join(', ');
                  return (
                    <ListItem
                      key={`${productSummary.productId}_${idx}`}
                      disableGutters
                      sx={{ paddingY: 0 }}
                    >
                      <Avatar
                        sx={{
                          width: 24,
                          height: 24,
                          borderRadius: 2,
                          fontSize: 12,
                        }}
                      >
                        {productSummary.quantity}
                      </Avatar>
                      <ListItemText
                        primary={primaryText}
                        secondary={secondaryText}
                        primaryTypographyProps={{ fontSize: 14, noWrap: true }}
                        secondaryTypographyProps={{
                          fontSize: 12,
                          noWrap: true,
                        }}
                        sx={{ marginLeft: 1 }}
                      />
                    </ListItem>
                  );
                })}
              </List>
              <StyledButton onClick={() => onItemClick(item)}>
                <AddShoppingCartIcon />
                Order again
              </StyledButton>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
}
