import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Remove from '@mui/icons-material/Remove';
import Button, { ButtonProps } from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { addHours } from 'date-fns/addHours';
import { addMinutes } from 'date-fns/addMinutes';
import { format } from 'date-fns/format';
import { isAfter } from 'date-fns/isAfter';
import { startOfDay } from 'date-fns/startOfDay';
import { startOfMinute } from 'date-fns/startOfMinute';
import Decimal from 'decimal.js';
import { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createOrUpdateOrder } from '../../../api/customerOrders';
import { OpeningHours, ProductCategoryType } from '../../../common/enums';
import LocalStorageSvc, {
  SELECTED_SITE_KEY,
} from '../../../common/localStorageSvc';
import {
  calculateFinalPrice,
  calculateGSTBreakdown,
  displayPrice,
} from '../../../common/moneyFunctions';
import { getOrderItemKey } from '../../../common/orderFunctions';
import { Order, OrderItem, OrderItemWithQty } from '../../../common/types';
import ProductCategoryTypeIcon from '../../../components/icons/ProductCategoryTypeIcon';
import OrderItemVariationsModal from '../../../components/modal/OrderItemVariationsModal';
import useAlert from '../../../hooks/useAlert';
import useCustomerAuth from '../../../hooks/useCustomerAuth';
import useLoadingModal from '../../../hooks/useLoadingModal';
import { hexWithAlpha } from '../../../theme/theme';
import CurrentWaitTime from '../components/CurrentWaitTime';
import {
  decreaseQuantity,
  increaseQuantity,
  orderToCustomerOrder,
  productNameWithSize,
  productVariantsWithQty,
  summarizeOrderItems,
} from './checkoutFunctions';
import PaymentSection from './PaymentSection';
import TotalBreakdown from './TotalBreakdown';

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&.MuiButton-outlined': {
    border: `1px solid ${theme.palette.common.babyBlue}`,
    color: theme.palette.common.babyBlue,
  },
  '&.Mui-disabled': {
    backgroundColor: hexWithAlpha(theme.palette.common.babyBlue, 0.25),
  },
}));

const emptyOrder: Partial<Order> = {
  requestedPickupTime: new Date(),
  siteId: LocalStorageSvc.load<number>(SELECTED_SITE_KEY) ?? 0,
  orderItems: [],
};

let debouncedUpdateTimer: NodeJS.Timeout | null = null;

export default function CustomerCheckoutPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { currentCustomer, setShowAccountModal } = useCustomerAuth();
  const { setLoading } = useLoadingModal();
  const defaultOrder = { ...emptyOrder, ...(location.state ?? {}) };
  const [order, setOrder] = useState<Partial<Order>>(defaultOrder);
  const [editOrderItem, setEditOrderItem] = useState<OrderItemWithQty | null>(
    null,
  );
  const [isPickupAsap, setIsPickupAsap] = useState<boolean>(
    () => defaultOrder.requestedPickupTime <= new Date(),
  );
  const [readyToPay, setReadyToPay] = useState<boolean>(false);
  const summarisedOrderItems = summarizeOrderItems(order.orderItems || []);

  const now = new Date();
  const earliestOrderAt = addHours(startOfDay(now), OpeningHours.Morning);
  const latestOrderAt = addMinutes(
    addHours(startOfDay(now), OpeningHours.Evening),
    1,
  );
  const nowMinute = startOfMinute(now);
  const minOrderTime = isAfter(nowMinute, earliestOrderAt)
    ? nowMinute
    : earliestOrderAt;

  const updateOrderOnServer = async (
    payload: Partial<Order>,
  ): Promise<boolean> => {
    setLoading(true);
    try {
      const res = await createOrUpdateOrder(orderToCustomerOrder(payload));
      if (res.status === 200 && res.data) {
        setOrder((prev) => ({ ...prev, id: res.data!.id! }));
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSetReadyToPay = async () => {
    const orderSaved = await updateOrderOnServer(order);
    if (orderSaved) {
      setReadyToPay(true);
      return;
    }
    showAlert('Something went wrong :(', 'error');
  };

  const backToOrder = () => {
    navigate('/', { state: order });
  };
  const cancelOrder = () => {
    navigate('/');
  };

  const debouncedUpdate = (payload: Partial<Order>) => {
    if (debouncedUpdateTimer) {
      clearTimeout(debouncedUpdateTimer);
    }
    debouncedUpdateTimer = setTimeout(() => updateOrderOnServer(payload), 500);
  };

  const addAnotherItem = (item: OrderItem) => {
    const orderItems = increaseQuantity(item, order.orderItems);
    const updated = { ...order, orderItems };
    setOrder(updated);
    updated.id && debouncedUpdate(updated);
  };

  const removeAnItem = (item: OrderItem) => {
    const orderItems = decreaseQuantity(item, order.orderItems);
    const updated = { ...order, orderItems };
    setOrder(updated);
    updated.id && debouncedUpdate(updated);
  };

  const closeOrderItemModal = (orderItem: OrderItem | null) => {
    if (orderItem && editOrderItem) {
      const updatedOrder = { ...order };
      const originalEditOrderItemKey = getOrderItemKey(editOrderItem);
      updatedOrder.orderItems = (updatedOrder.orderItems || []).filter(
        (f) => getOrderItemKey(f) !== originalEditOrderItemKey,
      );
      for (let i = 0; i < editOrderItem.quantity; i++) {
        updatedOrder.orderItems.push(orderItem);
      }
      setOrder(updatedOrder);
      updatedOrder.id && debouncedUpdate(updatedOrder);
    }
    setEditOrderItem(null);
  };

  const openOrderItemModal = (orderItem: OrderItemWithQty) => {
    setEditOrderItem({ ...orderItem });
  };

  const { total, subtotal, gst } = calculateGSTBreakdown(
    order.orderItems || [],
  );

  return (
    <>
      <CurrentWaitTime />
      <Stack gap={1} sx={{ paddingX: 2, paddingTop: 1 }}>
        <Stack
          flexDirection="row"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <IconButton onClick={backToOrder}>
            <ProductCategoryTypeIcon category={ProductCategoryType.HotCoffee} />
          </IconButton>
          <Typography
            variant="h3"
            fontWeight="bold"
            textAlign="center"
            sx={{ flex: 1 }}
          >
            Checkout
          </Typography>
          <IconButton onClick={cancelOrder}>
            <DeleteIcon />
          </IconButton>
        </Stack>
        <Typography fontWeight="bold">Order Summary</Typography>
        {order.orderItems?.length ? (
          <>
            <List dense>
              {summarisedOrderItems.map((item: OrderItemWithQty) => (
                <Fragment key={getOrderItemKey(item)}>
                  <ListItem
                    divider={!item.notes?.length}
                    sx={{ padding: 0, alignItems: 'center' }}
                  >
                    <ListItemText
                      primary={
                        <span
                          style={{ textDecoration: 'underline' }}
                          onClick={() => openOrderItemModal(item)}
                        >
                          {productNameWithSize(item)}
                        </span>
                      }
                      secondary={productVariantsWithQty(item)}
                      primaryTypographyProps={{
                        variant: 'body2',
                        fontWeight: 'bold',
                      }}
                      secondaryTypographyProps={{
                        variant: 'caption',
                        fontSize: 10,
                      }}
                      sx={
                        item.notes?.length
                          ? { marginBottom: 0, paddingBottom: 0 }
                          : undefined
                      }
                    />
                    <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
                      <IconButton onClick={() => removeAnItem(item)}>
                        <Remove />
                      </IconButton>
                      <Typography
                        sx={{ width: 30, textAlign: 'center' }}
                      >{`${item.quantity}`}</Typography>
                      <IconButton onClick={() => addAnotherItem(item)}>
                        <Add />
                      </IconButton>
                    </Stack>
                    <Typography fontWeight="bold">
                      {displayPrice(
                        calculateFinalPrice(item).times(
                          new Decimal(item.quantity),
                        ),
                      )}
                    </Typography>
                  </ListItem>
                  {item.notes ? (
                    <ListItem
                      divider
                      sx={{ padding: 0, alignItems: 'center', marginTop: 0 }}
                    >
                      <ListItemText
                        secondary={item.notes}
                        secondaryTypographyProps={{
                          variant: 'caption',
                          fontSize: 10,
                        }}
                        sx={{ marginTop: 0 }}
                      />
                    </ListItem>
                  ) : null}
                </Fragment>
              ))}

              <TotalBreakdown
                title="Subtotal"
                subtitle=" (ex gst)"
                amount={displayPrice(subtotal)}
              />
              <TotalBreakdown title="GST" amount={displayPrice(gst)} />
              <TotalBreakdown
                title="Total"
                subtitle=" (inc gst)"
                amount={displayPrice(total)}
                isTotal
              />
            </List>

            <Typography fontWeight="bold">Pickup Time</Typography>
            <ButtonGroup
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 2,
              }}
            >
              <StyledButton
                onClick={() => setIsPickupAsap(true)}
                sx={{ flex: 1 }}
                disabled={isPickupAsap}
              >
                ASAP
              </StyledButton>
              <StyledButton
                onClick={() => setIsPickupAsap(false)}
                sx={{ flex: 1 }}
                disabled={!isPickupAsap}
              >
                Schedule Time
              </StyledButton>
            </ButtonGroup>
            {!isPickupAsap && (
              <TimePicker
                label="Select Time"
                views={['hours', 'minutes']}
                ampm={false}
                value={order.requestedPickupTime || new Date()}
                minTime={minOrderTime}
                maxTime={latestOrderAt}
                onChange={(newValue) =>
                  setOrder((prev) => ({
                    ...prev,
                    requestedPickupTime: newValue,
                  }))
                }
                sx={{ marginBottom: 2 }}
              />
            )}
            {!!editOrderItem && (
              <OrderItemVariationsModal
                item={editOrderItem}
                onClose={closeOrderItemModal}
                submitText="Update cart"
              />
            )}
            {!currentCustomer ? (
              <>
                <Typography>
                  You need to be logged in to complete payment
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setShowAccountModal('login')}
                >
                  Log in
                </Button>
              </>
            ) : now > latestOrderAt ? (
              <Typography>
                Shop shuts at {format(latestOrderAt, 'ha')}
              </Typography>
            ) : !readyToPay ? (
              <Button variant="contained" onClick={handleSetReadyToPay}>
                Proceed to payment
              </Button>
            ) : (
              // must have an order.id at this point, it would have been created in handleSetReadyToPay
              <PaymentSection amount={total} orderId={order.id!} />
            )}
          </>
        ) : (
          <Typography>No items added to order yet</Typography>
        )}
      </Stack>
    </>
  );
}
