import Box from '@mui/material/Box';
import { PropsWithChildren } from 'react';
import { BarChart, CartesianGrid, ResponsiveContainer } from 'recharts';
import { ComponentConsumption } from '../../../../common/types';
import { TransformedDataItem } from './ProductsSold';
import { RevenueCostProfitChartData } from './RevenueCostProfit';

interface StyledBarChartWrapperProps {
  data:
    | ComponentConsumption[]
    | TransformedDataItem[]
    | RevenueCostProfitChartData[];
}

export default function StyledBarChartWrapper({
  children,
  data,
}: PropsWithChildren<StyledBarChartWrapperProps>) {
  return (
    <Box sx={{ width: '100%', height: '60vh', minHeight: '400px' }}>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 60,
            left: 60,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {children}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
