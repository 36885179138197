// ReportHeader.tsx
import BarChartIcon from '@mui/icons-material/BarChart';
import ClearIcon from '@mui/icons-material/Clear';
import TableChartIcon from '@mui/icons-material/TableChart';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { PropsWithChildren } from 'react';
import { ReportView } from '../../../../common/enums.tsx';

interface ReportHeaderProps {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  view: ReportView.Chart | ReportView.Table;
  handleViewChange: (
    event: React.MouseEvent<HTMLElement>,
    newView: ReportView.Chart | ReportView.Table | null,
  ) => void;
  fetchData: () => void;
}

export default function ReportHeader({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  view,
  handleViewChange,
  fetchData,
  children,
}: PropsWithChildren<ReportHeaderProps>) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        paddingTop: 3,
        marginBottom: view === ReportView.Chart ? '1.25em' : '4em',
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        flex={1}
        sx={{ paddingLeft: 3 }}
      >
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
          slotProps={{
            textField: {
              size: 'small',
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    {startDate && (
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          setStartDate(null);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              },
            },
          }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={setEndDate}
          minDate={startDate ?? undefined}
          slotProps={{
            textField: {
              size: 'small',
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    {endDate && (
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          setEndDate(null);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              },
            },
          }}
        />
        {children}
        <Button variant="contained" onClick={fetchData}>
          Search
        </Button>
      </Stack>

      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        aria-label="view selector"
        sx={{ paddingRight: 5 }}
      >
        <ToggleButton value={ReportView.Chart} aria-label="chart view">
          <BarChartIcon />
        </ToggleButton>
        <ToggleButton value={ReportView.Table} aria-label="table view">
          <TableChartIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}
