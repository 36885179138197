import { passwordRegex } from '../../../../common/formValidationRules';

export function formatRoleDisplayName(role: string): string {
  const staffRolePrefix = 'Staff';
  return role.replace(staffRolePrefix, '');
}

export function validatePassword(
  value: string | undefined,
  allowEmpty: boolean = false,
): true | string {
  if (!value) {
    return allowEmpty ? true : 'Password is required';
  }

  if (!passwordRegex.test(value)) {
    return 'Password must be at least 8 characters long and include at least one letter, one number, and one special character (@$!%*#?&)';
  }

  const validations = [
    {
      regex: /[A-Z]/,
      message: 'Password must contain at least one capital letter',
    },
    {
      regex: /[a-z]/,
      message: 'Password must contain at least one lowercase letter',
    },
    { regex: /[0-9]/, message: 'Password must contain at least one number' },
    {
      regex: /[@$!%*#?&]/,
      message:
        'Password must contain at least one special character (@$!%*#?&)',
    },
  ];

  for (const { regex, message } of validations) {
    if (!regex.test(value)) {
      return message;
    }
  }

  return true;
}
