export const navbarListItemsTextEnum = {
  //Staff
  DASHBOARD: 'Dashboard',
  ORDER: 'ORDER',
  POS: 'POS',
  WALK_UP: 'Walk Up',
  SETTINGS: 'Preferences',
  //Admin
  ACCOUNTS: 'Accounts',
  CUSTOMERS: 'Customers',
  PRODUCTS: 'Products',
  COMPONENTS: 'Components',
  COMPONENT_TYPES: 'ComponentTypes',
  POPULAR: 'Popular Products',
  REPORTS: 'Reports',
  //General
  LOG_OUT: 'Log Out',
};

export const navbarListItemsIconEnum = {
  //Staff
  DASHBOARD: 'HomeIcon',
  POS: 'PointOfSaleIcon',
  ORDER: 'ReceiptIcon',
  WALK_UP: 'WalkUpIcon',
  SETTINGS: 'SettingsIcon',
  //Admin
  ACCOUNTS: 'ManageAccountsIcon',
  CUSTOMERS: 'PersonIcon',
  PRODUCTS: 'ShoppingCartIcon',
  COMPONENTS: 'TapasIcon',
  COMPONENT_TYPES: 'LocalDiningIcon',
  REPORTS: 'AssessmentIcon',
  POPULAR: 'GradeIcon',
  //General
  LOG_OUT: 'LogoutIcon',
};

export const navbarMainItems = [
  {
    text: navbarListItemsTextEnum.DASHBOARD,
    icon: navbarListItemsIconEnum.DASHBOARD,
    path: '/staff/home',
    tooltipText: 'Home',
  },
  {
    text: navbarListItemsTextEnum.ORDER,
    icon: navbarListItemsIconEnum.ORDER,
    path: '/staff/order',
    tooltipText: 'Order',
  },
  {
    text: navbarListItemsTextEnum.POS,
    icon: navbarListItemsIconEnum.POS,
    path: '/staff/pos',
    tooltipText: 'POS',
  },
  {
    text: navbarListItemsTextEnum.WALK_UP,
    icon: navbarListItemsIconEnum.WALK_UP,
    path: '/staff/walkup',
    tooltipText: 'Walk Up',
  },
];

export const navbarAdminItems = [
  {
    text: navbarListItemsTextEnum.ACCOUNTS,
    icon: navbarListItemsIconEnum.ACCOUNTS,
    path: '/admin/accounts',
    tooltipText: 'Accounts',
  },
  {
    text: navbarListItemsTextEnum.CUSTOMERS,
    icon: navbarListItemsIconEnum.CUSTOMERS,
    path: '/admin/customers',
    tooltipText: 'Customers',
  },
  {
    text: navbarListItemsTextEnum.PRODUCTS,
    icon: navbarListItemsIconEnum.PRODUCTS,
    path: '/admin/products',
    tooltipText: 'Products',
  },
  {
    text: navbarListItemsTextEnum.COMPONENTS,
    icon: navbarListItemsIconEnum.COMPONENTS,
    path: '/admin/components',
    tooltipText: 'Components',
  },
  {
    text: navbarListItemsTextEnum.COMPONENT_TYPES,
    icon: navbarListItemsIconEnum.COMPONENT_TYPES,
    path: '/admin/componenttypes',
    tooltipText: 'Component Types',
  },
  {
    text: navbarListItemsTextEnum.POPULAR,
    icon: navbarListItemsIconEnum.POPULAR,
    path: '/admin/popular',
    tooltipText: 'Popular',
  },
  {
    text: navbarListItemsTextEnum.REPORTS,
    icon: navbarListItemsIconEnum.REPORTS,
    path: '/admin/reports',
    tooltipText: 'Reports',
  },
];

export const navbarEndItems = [
  {
    text: navbarListItemsTextEnum.SETTINGS,
    icon: navbarListItemsIconEnum.SETTINGS,
    path: '/staff/settings',
    tooltipText: 'Settings',
  },
  {
    text: navbarListItemsTextEnum.LOG_OUT,
    icon: navbarListItemsIconEnum.LOG_OUT,
    path: '/staff/home',
    tooltipText: 'Log Out',
  },
];
