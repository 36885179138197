import { create } from 'zustand';
import { listProductVariantTypes } from '../api/productVariantTypes';
import { ProductVariantType } from '../common/types';

type ProductVariantTypesStore = {
  hasLoaded: boolean;
  productVariantTypes: ProductVariantType[];
  loading: boolean;
  error: unknown | null;
  milkType: ProductVariantType | null;
  beansType: ProductVariantType | null;
  shotsType: ProductVariantType | null;
  otherType: ProductVariantType | null;
  fetchProductVariantTypes: () => void;
};

const useProductVariantTypes = create<ProductVariantTypesStore>((set, get) => ({
  hasLoaded: false,
  productVariantTypes: [],
  loading: false,
  error: null,
  milkType: null,
  beansType: null,
  shotsType: null,
  otherType: null,
  fetchProductVariantTypes: async () => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await listProductVariantTypes();
      const data = result.data ?? [];
      const milkType = data.find((pvt) => pvt.name === 'Milk') ?? null;
      const beansType = data.find((pvt) => pvt.name === 'Beans') ?? null;
      const shotsType = data.find((pvt) => pvt.name === 'Shot') ?? null;
      const otherType = data.find((pvt) => pvt.name === 'Other') ?? null;
      set({
        productVariantTypes: data,
        loading: false,
        error: null,
        beansType,
        milkType,
        shotsType,
        otherType,
      });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
}));

export default useProductVariantTypes;

// make that first call without having to useEffect in a component
if (!useProductVariantTypes.getState().hasLoaded) {
  useProductVariantTypes.getState().fetchProductVariantTypes();
}
