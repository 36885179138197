import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTheme } from '@mui/material/styles';

interface NavbarItemProps {
  text: string;
  icon: React.ReactNode;
  path: string;
  handleSelect: (path: string, text: string) => void;
}

export function NavbarItem({
  text,
  icon,
  path,
  handleSelect,
}: NavbarItemProps) {
  const theme = useTheme();
  return (
    <ListItem
      sx={{
        display: 'flex',
        padding: 0,
        margin: 0,
      }}
      onClick={() => handleSelect(path, text)}
    >
      <ListItemIcon
        sx={{
          color: theme.palette.primary.main,
          alignItems: 'center',
          margin: 0,
          marginBottom: 3,
          '&:hover': {
            color: theme.palette.primary.contrastText,
            cursor: 'pointer',
          },
        }}
      >
        {icon}
      </ListItemIcon>
    </ListItem>
  );
}
