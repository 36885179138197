/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ProductCategoryType } from '../../../../common/enums';
import { groupStaffOrderItems } from '../../../../common/orderFunctions';
import { Order, OrderItem, StaffOrder } from '../../../../common/types';
import { IndicatorBox } from '../../components/IndicatorBox';
import { TimeIndicator } from '../../components/TimeIndicator';
import {
  allColdDrinksFinished,
  allColdDrinksPlaced,
  allColdDrinksStarted,
  allHotCoffeeOrTeaFinished,
  allHotCoffeeOrTeaPlaced,
  allHotCoffeeOrTeaStarted,
} from '../../functions/stationFunctions';

interface DraggableFoodOrderCardProps {
  isAllFoodStarted: boolean;
  isAllFoodPlaced: boolean;
  order: any; //we need this to be 'any' as the dnd-kit library is not typed.
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  handleStartOrder: (start: boolean) => void;
  handleStopOrder: (start: boolean) => void;
  handleFinishOrder: (order: Order) => void;
}

export default function DraggableFoodOrderCard({
  isAllFoodStarted,
  isAllFoodPlaced,
  order,
  isLoading,
  setIsLoading,
  handleStartOrder,
  handleStopOrder,
  handleFinishOrder,
}: DraggableFoodOrderCardProps) {
  const theme = useTheme();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: order.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const groupedItems = groupStaffOrderItems((order as StaffOrder).orderItems);

  return (
    <Stack ref={setNodeRef} {...attributes} {...listeners} style={style}>
      <Stack
        sx={{
          backgroundColor: theme.palette.common.grey,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Stack sx={{ padding: 1, alignItems: 'center' }}>
            {isAllFoodStarted ? <LockIcon /> : <DragHandleIcon />}
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Typography variant="h3" sx={{ marginLeft: theme.spacing(1) }}>
            {order.customerName}
          </Typography>
        </Stack>
        <TimeIndicator time={order.requestedPickupTime} />
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="order table">
          <TableHead>
            <TableRow>
              <TableCell>Qty</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedItems.map((orderItemWithQty, index: number) => {
              if (
                orderItemWithQty.productCategoryType !==
                ProductCategoryType.Food
              ) {
                return null;
              }

              return (
                <TableRow key={index}>
                  <TableCell>{orderItemWithQty.quantity}</TableCell>
                  <TableCell>{orderItemWithQty.productName}</TableCell>
                  <TableCell sx={{ width: '25em' }}>
                    {orderItemWithQty?.notes || ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Stack
            sx={{
              justifyContent: 'flex-start',
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {order.orderItems.some(
              (item: OrderItem) =>
                item.productCategoryType === ProductCategoryType.ColdDrinks,
            ) && (
              <IndicatorBox
                orderItems={order.orderItems}
                label="Cold Drinks"
                allStartedFn={allColdDrinksStarted}
                allFinishedFn={allColdDrinksFinished}
                allPendingFn={allColdDrinksPlaced}
              />
            )}
            {order.orderItems.some(
              (item: OrderItem) =>
                item.productCategoryType === ProductCategoryType.HotCoffee ||
                item.productCategoryType === ProductCategoryType.Tea,
            ) && (
              <IndicatorBox
                orderItems={order.orderItems}
                label="Hot Coffee"
                allStartedFn={allHotCoffeeOrTeaStarted}
                allFinishedFn={allHotCoffeeOrTeaFinished}
                allPendingFn={allHotCoffeeOrTeaPlaced}
              />
            )}
          </Stack>

          <Stack
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flex: 1,
            }}
          >
            {isAllFoodPlaced && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  margin: 1,
                  width: '10em',
                }}
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  handleStartOrder(true);
                }}
              >
                Start Order
              </Button>
            )}
            {isAllFoodStarted && (
              <Stack sx={{ flexDirection: 'row' }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  sx={{
                    margin: 1,
                    width: '10em',
                    whiteSpace: 'nowrap',
                  }}
                  disabled={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    handleStopOrder(false);
                  }}
                >
                  Reset Order
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    margin: 1,
                    whiteSpace: 'nowrap',
                  }}
                  disabled={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    handleFinishOrder(order);
                  }}
                >
                  Finish Order
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </TableContainer>
    </Stack>
  );
}
