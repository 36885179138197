import { create } from 'zustand';
import sitesApi from '../api/site';
import { Site } from '../common/types';

type SitesStore = {
  hasLoaded: boolean;
  sites: Site[];
  loading: boolean;
  error: unknown | null;
  fetchSites: () => void;
};

const useSites = create<SitesStore>((set, get) => ({
  hasLoaded: false,
  sites: [],
  loading: false,
  error: null,
  fetchSites: async () => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await sitesApi.listSites();
      set({ sites: result.data ?? [], loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
}));

export default useSites;

// make that first call without having to useEffect in a component
if (!useSites.getState().hasLoaded) {
  useSites.getState().fetchSites();
}
