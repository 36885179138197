import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { TooltipProps } from 'recharts';

interface CustomTooltipProps extends TooltipProps<number, string> {}

export default function CustomTooltip({
  active,
  payload,
  label,
}: CustomTooltipProps): React.ReactElement | null {
  if (!active || !payload || payload.length === 0) return null;

  return (
    <Paper elevation={3}>
      <Box sx={{ p: 1 }}>
        <Typography variant="subtitle1">
          {payload[0]?.payload?.fullName || label}
        </Typography>
        {payload.map((entry) => (
          <Typography key={entry.dataKey} variant="body2">
            {`${entry.dataKey}: ${entry.value}`}
          </Typography>
        ))}
      </Box>
    </Paper>
  );
}
