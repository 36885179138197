import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import { ProductCategoryType } from '../../common/enums';

interface MenuTabsProps {
  selectedCategory: string;
  handleCategoryChange: (
    event: React.SyntheticEvent,
    newValue: ProductCategoryType,
  ) => void;
}

export default function MenuTabs({
  selectedCategory,
  handleCategoryChange,
}: MenuTabsProps) {
  const theme = useTheme();

  return (
    <Tabs
      value={selectedCategory}
      onChange={handleCategoryChange}
      variant="scrollable"
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: theme.palette.primary.main,
        },
        '& .MuiTab-root': {
          color: theme.palette.common.deepblue,
        },
      }}
    >
      <Tab
        sx={{
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
        }}
        value={ProductCategoryType.HotCoffee}
        label={ProductCategoryType.HotCoffee}
      />
      <Tab
        sx={{
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
        }}
        value={ProductCategoryType.ColdDrinks}
        label={ProductCategoryType.ColdDrinks}
      />
      <Tab
        sx={{
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
        }}
        value={ProductCategoryType.Tea}
        label={ProductCategoryType.Tea}
      />
      <Tab
        sx={{
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
        }}
        value={ProductCategoryType.Food}
        label={ProductCategoryType.Food}
      />
    </Tabs>
  );
}
