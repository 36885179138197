import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Order, Product } from '../../../../common/types';
import OrderItemCards from './OrderItemCards';
import OrderPricing from './OrderPricing';

interface OrderForProps {
  orderData: Order;
  products: Product[];
  removeOrderItem: (id: number) => void;
  changeOrderItemSize: (id: number, newSizeId: number) => void;
  changeOrderStation: (stationName: string) => void;
  assignedStation: () => string;
  editOrderItemVariations: (id: number) => void;
  submitOrder: (customerName: string) => void;
}

export default function OrderFor({
  orderData,
  products,
  removeOrderItem,
  changeOrderItemSize,
  changeOrderStation,
  assignedStation,
  editOrderItemVariations,
  submitOrder,
}: OrderForProps) {
  const theme = useTheme();
  const [customerName, setCustomerName] = useState('');

  function handleSubmitOrder() {
    submitOrder(customerName);
    setCustomerName('');
  }

  return (
    <Grid
      size={{ xs: 12, md: 5 }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.grey,
        maxHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      <Stack
        flexDirection="row"
        sx={{ alignItems: 'center', marginBottom: theme.spacing(2) }}
      >
        <Typography variant="h3" fontWeight="600" sx={{ flex: 1 }}>
          Order For:
        </Typography>
        <TextField
          size="small"
          fullWidth
          label="Customer Name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          sx={{ flex: 2, marginBottom: theme.spacing(0.5) }}
        />
      </Stack>
      <OrderItemCards
        orderData={orderData}
        products={products}
        removeOrderItem={removeOrderItem}
        changeOrderItemSize={changeOrderItemSize}
        changeOrderStation={changeOrderStation}
        editOrderItemVariations={editOrderItemVariations}
        submitOrder={submitOrder}
      />
      <OrderPricing
        orderData={orderData}
        changeOrderStation={changeOrderStation}
        assignedStation={assignedStation}
        submitOrder={handleSubmitOrder}
        customerName={customerName}
      />
    </Grid>
  );
}
