/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ProductCategoryType } from '../../../../common/enums';
import {
  getOrderItemKey,
  groupStaffOrderItems,
} from '../../../../common/orderFunctions';
import { Order, OrderItem, StaffOrder } from '../../../../common/types';
import useProductVariantTypes from '../../../../datastore/useProductVariantTypes';
import useProducts from '../../../../datastore/useProducts';
import { IndicatorBox } from '../../components/IndicatorBox';
import { TimeIndicator } from '../../components/TimeIndicator';
import {
  allColdDrinksFinished,
  allColdDrinksPlaced,
  allColdDrinksStarted,
  allFoodFinished,
  allFoodPending,
  allFoodStarted,
  allHotCoffeeOrTeaFinished,
  allHotCoffeeOrTeaPlaced,
  allHotCoffeeOrTeaStarted,
} from '../../functions/stationFunctions';

interface DraggableDrinkOrderCardProps {
  order: any; //we need this to be 'any' as the dnd-kit library is not typed.
  isAllDrinksStarted: boolean;
  isAllDrinksPlaced: boolean;
  isLoading: boolean;
  isColdDrinks: boolean;
  setIsLoading: (isLoading: boolean) => void;
  handleStartOrder: (start: boolean) => void;
  handleStopOrder: (start: boolean) => void;
  handleFinishOrder: (order: Order) => void;
}

export default function DraggableDrinkOrderCard({
  order,
  isAllDrinksStarted,
  isAllDrinksPlaced,
  isLoading,
  isColdDrinks,
  setIsLoading,
  handleStartOrder,
  handleStopOrder,
  handleFinishOrder,
}: DraggableDrinkOrderCardProps) {
  const theme = useTheme();
  const { products } = useProducts();
  const { beansType, milkType, shotsType } = useProductVariantTypes();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: order.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const categoryTypes = isColdDrinks
    ? [ProductCategoryType.ColdDrinks]
    : [ProductCategoryType.HotCoffee, ProductCategoryType.Tea];
  const groupedItems = groupStaffOrderItems(
    (order as StaffOrder).orderItems,
  ).filter((orderItemWithQty) =>
    categoryTypes.includes(
      orderItemWithQty.productCategoryType as ProductCategoryType,
    ),
  );

  return (
    <Stack ref={setNodeRef} {...attributes} {...listeners} style={style}>
      <Stack
        sx={{
          backgroundColor: theme.palette.common.grey,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Stack sx={{ padding: 1, alignItems: 'center' }}>
            {isAllDrinksStarted ? <LockIcon /> : <DragHandleIcon />}
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Typography variant="h3" sx={{ marginLeft: theme.spacing(1) }}>
            {order.customerName}
          </Typography>
        </Stack>
        <TimeIndicator time={order.requestedPickupTime} />
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="order table">
          <TableHead>
            <TableRow>
              <TableCell>Qty</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Milk</TableCell>
              <TableCell>Beans</TableCell>
              <TableCell>Xtra Shots</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedItems.map((staffOrderItemWithQty) => {
              const key = getOrderItemKey(staffOrderItemWithQty);
              const product = products.find(
                (f) => f.id === staffOrderItemWithQty.productId,
              );
              const selectedSize = product?.productSizes.find(
                (f) => f.id === staffOrderItemWithQty.productSizeId,
              );
              const milkVariant = staffOrderItemWithQty.selectedVariants.find(
                (f) => f.productVariant.productVariantTypeId === milkType?.id,
              );
              const extraShots = staffOrderItemWithQty.selectedVariants.filter(
                (f) => f.productVariant.productVariantTypeId === shotsType?.id,
              );
              const beansVariant = staffOrderItemWithQty.selectedVariants.find(
                (f) => f.productVariant.productVariantTypeId === beansType?.id,
              );
              const hasNotes = !!staffOrderItemWithQty.notes.length;

              return (
                <React.Fragment key={key}>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: hasNotes
                          ? 0
                          : '1px solid rgba(224,224,224,1',
                        paddingBottom: hasNotes ? 0 : 1,
                      }}
                    >
                      {staffOrderItemWithQty.quantity}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: hasNotes
                          ? 0
                          : '1px solid rgba(224,224,224,1',
                        paddingBottom: hasNotes ? 0 : 1,
                      }}
                    >
                      {selectedSize?.sizeName || '-'}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: hasNotes
                          ? 0
                          : '1px solid rgba(224,224,224,1',
                        paddingBottom: hasNotes ? 0 : 1,
                      }}
                    >
                      {staffOrderItemWithQty.productName}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: hasNotes
                          ? 0
                          : '1px solid rgba(224,224,224,1',
                        paddingBottom: hasNotes ? 0 : 1,
                      }}
                    >
                      {milkVariant?.productVariant.name || '-'}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: hasNotes
                          ? 0
                          : '1px solid rgba(224,224,224,1',
                        paddingBottom: hasNotes ? 0 : 1,
                      }}
                    >
                      {beansVariant?.productVariant.name || '-'}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: hasNotes
                          ? 0
                          : '1px solid rgba(224,224,224,1',
                        paddingBottom: hasNotes ? 0 : 1,
                      }}
                    >
                      {extraShots.length || '-'}
                    </TableCell>
                  </TableRow>
                  {!!staffOrderItemWithQty.notes && (
                    <TableRow>
                      <TableCell sx={{ paddingTop: 0 }} colSpan={1}></TableCell>
                      <TableCell
                        sx={{
                          paddingTop: 0,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                        colSpan={5}
                      >
                        Notes: {staffOrderItemWithQty.notes}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Stack
            sx={{
              justifyContent: 'flex-start',
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {order.orderItems.some(
              (item: OrderItem) =>
                item.productCategoryType === ProductCategoryType.Food,
            ) && (
              <IndicatorBox
                orderItems={order.orderItems}
                label="Food"
                allStartedFn={allFoodStarted}
                allFinishedFn={allFoodFinished}
                allPendingFn={allFoodPending}
              />
            )}
            {isColdDrinks &&
              order.orderItems.some(
                (item: OrderItem) =>
                  item.productCategoryType === ProductCategoryType.HotCoffee ||
                  item.productCategoryType === ProductCategoryType.Tea,
              ) && (
                <IndicatorBox
                  orderItems={order.orderItems}
                  label="Hot Coffee"
                  allStartedFn={allHotCoffeeOrTeaStarted}
                  allFinishedFn={allHotCoffeeOrTeaFinished}
                  allPendingFn={allHotCoffeeOrTeaPlaced}
                />
              )}
            {!isColdDrinks &&
              order.orderItems.some(
                (item: OrderItem) =>
                  item.productCategoryType === ProductCategoryType.ColdDrinks,
              ) && (
                <IndicatorBox
                  orderItems={order.orderItems}
                  label="Cold Drinks"
                  allStartedFn={allColdDrinksStarted}
                  allFinishedFn={allColdDrinksFinished}
                  allPendingFn={allColdDrinksPlaced}
                />
              )}
          </Stack>

          <Stack
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flex: 1,
            }}
          >
            {isAllDrinksPlaced && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  margin: 1,
                  width: '10em',
                }}
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  handleStartOrder(true);
                }}
              >
                Start Order
              </Button>
            )}
            {isAllDrinksStarted && (
              <Stack sx={{ flexDirection: 'row' }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  sx={{
                    margin: 1,
                    whiteSpace: 'nowrap',
                    width: '10em',
                  }}
                  disabled={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    handleStopOrder(false);
                  }}
                >
                  Reset Order
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    whiteSpace: 'nowrap',
                    margin: 1,
                  }}
                  disabled={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    handleFinishOrder(order);
                  }}
                >
                  Finish Order
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </TableContainer>
    </Stack>
  );
}
