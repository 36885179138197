import { create } from 'zustand';

interface IsOnlineState {
  isOnline: boolean;
  setIsOnline: (isOnline: boolean) => void;
}

const useIsOnline = create<IsOnlineState>((set) => ({
  isOnline: true,
  setIsOnline: (isOnline: boolean) => set({ isOnline }),
}));

export default useIsOnline;
