import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import useAlert from '../../hooks/useAlert';

export default function AlertCoordinator() {
  const { alert, clearAlert } = useAlert();

  if (!alert) {
    return null;
  }

  setTimeout(() => clearAlert(), alert.duration ?? 3000);

  return (
    <Stack
      flexDirection="row"
      sx={{
        position: 'fixed',
        bottom: 0,
        padding: '1rem',
        width: '100%',
        pointerEvents: 'visiblePainted',
        justifyContent: 'flex-end',
        zIndex: 10000,
      }}
    >
      <Alert severity={alert.severity}>{alert.message}</Alert>
    </Stack>
  );
}
