import { Environment } from './enums';

let appEnv = Environment.Production;
if (
  location.hostname.includes(Environment.Test) ||
  location.hostname.includes(Environment.NonProd)
) {
  appEnv = Environment.Test;
} else if (location.hostname.startsWith('localhost')) {
  appEnv = Environment.Development;
}

export default {
  version: import.meta.env.VITE_APP_VERSION,
  env: appEnv,
};
