import { create } from 'zustand';
import sizesApi from '../api/size';
import { Size } from '../common/types';

type SizesStore = {
  hasLoaded: boolean;
  sizes: Size[];
  loading: boolean;
  error: unknown | null;
  fetchSizes: () => void;
};

const useSizes = create<SizesStore>((set, get) => ({
  hasLoaded: false,
  sizes: [],
  loading: false,
  error: null,
  fetchSizes: async () => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await sizesApi.listSizes();
      set({ sizes: result.data ?? [], loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
}));

export default useSizes;

// make that first call without having to useEffect in a component
if (!useSizes.getState().hasLoaded) {
  useSizes.getState().fetchSizes();
}
