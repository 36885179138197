import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { PropsWithChildren } from 'react';

interface FilterProps {
  filterLabel?: string;
  filterText: string;
  setFilterText: (value: string) => void;
}

export default function AdminPageFilter({
  filterLabel = 'Filter by Name',
  filterText,
  setFilterText,
  children,
}: PropsWithChildren<FilterProps>) {
  return (
    <Stack
      flexDirection="row"
      spacing={4}
      sx={{
        padding: '2em 0',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <TextField
          label={filterLabel}
          size="small"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          variant="outlined"
          sx={{ flexGrow: 1, paddingRight: 2, maxWidth: '50%' }}
        />
        {children}
      </Stack>
    </Stack>
  );
}
