import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { Fragment } from 'react/jsx-runtime';
import { formatCurrency } from '../../../../common/moneyFunctions.tsx';
import {
  MutateProduct,
  ProductCategory,
  Size,
} from '../../../../common/types.tsx';
import LoadingButton from '../../../../components/loadingButton/LoadingButton.tsx';
import AdminDialogTitle from '../../components/AdminDialogTitle.tsx';

type ProductModalProps = {
  isLoading: boolean;
  onClose: () => void;
  onSave: (product: MutateProduct) => void;
  product: MutateProduct;
  productCategories: ProductCategory[];
  sizes: Size[];
};

export default function ProductModal({
  isLoading,
  onClose,
  onSave,
  product,
  productCategories = [],
  sizes = [],
}: ProductModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MutateProduct>({
    defaultValues: { ...product },
  });

  const onSubmit = (data: MutateProduct) => {
    if (isLoading) {
      return;
    }
    data.productSizes.forEach((ps) => (ps.basePrice = Number(ps.basePrice)));
    onSave(data);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <AdminDialogTitle>
        {product.id > 0 ? 'Edit Product' : 'New Product'}
      </AdminDialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="productCategoryId"
            control={control}
            rules={{ required: 'Product Category is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                select
                margin="dense"
                label="Product Category"
                fullWidth
                error={!!errors.productCategoryId}
                helperText={errors.productCategoryId?.message}
              >
                <MenuItem value={0} disabled>
                  Select Product Category
                </MenuItem>
                {productCategories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{ required: 'Description is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={2}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            )}
          />
          <Grid
            container
            spacing={1}
            sx={{
              marginTop: 1,
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Grid size={3} sx={{ alignContent: 'baseline' }}>
              <Typography variant="h6">Sizes</Typography>
            </Grid>
            <Grid size={7} sx={{ alignContent: 'baseline' }}>
              <Typography variant="body1">Price</Typography>
            </Grid>
            {product.productSizes
              .sort((a, b) => b.sizeName.localeCompare(a.sizeName))
              .map((ps, idx) => {
                const size = sizes.find((f) => f.id === ps.sizeId);
                const defaultValue = ps.isActive;
                return (
                  <Fragment key={ps.sizeId}>
                    <Grid size={3}>
                      <Controller
                        name={`productSizes.${idx}.isActive`}
                        control={control}
                        defaultValue={defaultValue}
                        render={({ field }) => (
                          <FormControlLabel
                            checked={field.value}
                            control={
                              <Checkbox
                                onChange={(_, checked) =>
                                  field.onChange(checked)
                                }
                                onBlur={field.onBlur}
                                inputRef={field.ref}
                                disabled={ps.isDefault}
                              />
                            }
                            label={size!.name}
                            sx={{
                              '& .MuiFormControlLabel-label.Mui-disabled': {
                                color: 'rgba(0,0,0,1)',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid size={7}>
                      <Controller
                        name={`productSizes.${idx}.basePrice`}
                        control={control}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <TextField
                            size="small"
                            margin="dense"
                            type="text"
                            defaultValue={formatCurrency(ps.basePrice)}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace(
                                /[^0-9.]/g,
                                '',
                              );
                              onChange(rawValue);
                            }}
                            onBlur={() => {
                              const formattedValue = formatCurrency(value || 0);
                              onChange(formattedValue);
                              onBlur();
                            }}
                            fullWidth
                            slotProps={{
                              input: {
                                startAdornment: '$',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Fragment>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={isLoading}
            loading={isLoading}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
