import { create } from 'zustand';
import productsApi from '../api/product';
import { Product } from '../common/types';

type ProductsStore = {
  hasLoaded: boolean;
  showDisabled: boolean;
  setShowDisabled: (showDisabled: boolean) => void;
  products: Product[];
  loading: boolean;
  error: unknown | null;
  fetchProducts: () => Promise<void>;
};

const useProducts = create<ProductsStore>((set, get) => ({
  hasLoaded: false,
  showDisabled: false,
  products: [],
  loading: false,
  error: null,
  fetchProducts: async () => {
    if (get().loading) {
      return;
    }
    try {
      const showDisabled = get().showDisabled;
      set({ loading: true, hasLoaded: true });
      const result = await productsApi.listProducts();
      const products = showDisabled
        ? (result.data ?? [])
        : (result.data ?? []).filter((p) => p.isActive);
      set({ products, loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
  setShowDisabled: (showDisabled: boolean) => {
    set({ showDisabled });
    get().fetchProducts();
  },
}));

export default useProducts;

// Initial fetch
if (!useProducts.getState().hasLoaded) {
  useProducts.getState().fetchProducts();
}
