import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { calculateProductVariantInternalCost } from '../../../../../common/moneyFunctions.tsx';
import { ProductVariant } from '../../../../../common/types.tsx';
import LoadingSpinner from '../../../../../components/loadingSpinner/LoadingSpinner.tsx';
import SizePricesTableCell from '../../../components/SizePricesTableCell.tsx';

interface ProductTableProps {
  isLoading: boolean;
  paginatedVariations: ProductVariant[];
  editProduct: (pv: ProductVariant) => void;
  statusToggle: (pv: ProductVariant) => void;
}

export function VariationTable({
  isLoading,
  paginatedVariations,
  editProduct,
  statusToggle,
}: ProductTableProps) {
  return (
    <Table sx={{ maxWidth: '100%', overflowX: 'auto' }} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Core Variant</TableCell>
          <TableCell>Price Adjustment</TableCell>
          <TableCell>Internal Cost</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <LoadingSpinner isLoading={isLoading} isTable colSpan={6}>
          {paginatedVariations.map((variant) => {
            const sizePrices = variant.productVariantSizeVolumes
              .filter((f) => f.isActive)
              .map((m) => ({ sizeName: m.sizeName, cost: m.priceAdjustment }));
            const sizeInternalCosts = variant.productVariantSizeVolumes
              .filter((f) => f.isActive)
              .map((m) => ({
                sizeName: m.sizeName,
                cost: calculateProductVariantInternalCost(
                  variant,
                  m.productSizeId,
                ).toNumber(),
              }));
            return (
              <TableRow key={variant.id}>
                <TableCell>{variant.name}</TableCell>
                <TableCell>{variant.productVariantTypeName}</TableCell>
                <TableCell>{variant.isCore ? 'Yes' : 'No'}</TableCell>
                <SizePricesTableCell sizePrices={sizePrices} />
                <SizePricesTableCell sizePrices={sizeInternalCosts} />
                <TableCell>
                  <Button
                    variant="contained"
                    sx={{ marginRight: 2 }}
                    onClick={() => editProduct(variant)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => statusToggle(variant)}
                    color={variant.isActive ? 'error' : 'primary'}
                  >
                    {variant.isActive ? 'Disable' : 'Enable'}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </LoadingSpinner>
      </TableBody>
    </Table>
  );
}
