import PersonIcon from '@mui/icons-material/Person';
import { useEffect, useState } from 'react';
import { hasAdminRole } from '../../../common/roleFunctions.tsx';
import { isStaff } from '../../../common/typeAssertionFunctions.tsx';
import { TPGEMediaQuery } from '../../../common/types.tsx';
import useCustomers from '../../../datastore/useCustomers.tsx';
import useAlert from '../../../hooks/useAlert.tsx';
import useAPI from '../../../hooks/useAPI.tsx';
import useTPGEMediaQuery from '../../../hooks/useTPGEMediaQuery.tsx';
import { AdminHeader } from '../components/AdminHeader.tsx';
import AdminPageContent from '../components/AdminPageContent.tsx';
import AdminPageFilter from '../components/AdminPageFilter.tsx';
import AdminPagination from '../components/AdminPagination.tsx';
import CustomerManagementTable from './components/CustomerManagementTable.tsx';

const CustomersPerPage: { [key in TPGEMediaQuery]: number } = {
  xlarge: 16,
  large: 12,
  medium: 8,
  small: 6,
};

export default function CustomerManagementPage() {
  const { currentUser } = useAPI();
  const { customers = [], loading: isLoading, error } = useCustomers();
  const { showAlert } = useAlert();
  const mediaSize = useTPGEMediaQuery();
  const customersPerPage = CustomersPerPage[mediaSize];
  const [filterText, setFilterText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (error) {
      showAlert('Error retrieving customers, refresh and try again.', 'error');
    }
  }, [error]);

  if (!hasAdminRole(currentUser) || !isStaff(currentUser)) return null;

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const filteredCustomers =
    customers.filter((customer) =>
      filterText
        ? customer.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
          customer.lastName.toLowerCase().includes(filterText.toLowerCase())
        : true,
    ) || [];

  const totalNumberOfPages = Math.ceil(
    filteredCustomers.length / customersPerPage,
  );
  const paginatedCustomers = filteredCustomers.slice(
    (currentPage - 1) * customersPerPage,
    currentPage * customersPerPage,
  );

  return (
    <>
      <AdminHeader
        title="Customers"
        description="View your customers details."
        icon={<PersonIcon fontSize="large" sx={{ color: 'white' }} />}
      />
      <AdminPageContent>
        <AdminPageFilter
          filterText={filterText}
          setFilterText={setFilterText}
        />
        <CustomerManagementTable
          isLoading={isLoading}
          paginatedCustomers={paginatedCustomers}
        />
        <AdminPagination
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </AdminPageContent>
    </>
  );
}
