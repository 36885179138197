import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Stations } from '../../../../common/enums';
import { isStaff } from '../../../../common/typeAssertionFunctions';
import useAPI from '../../../../hooks/useAPI';
import CurrentTime from '../../home/components/CurrentTime';

interface WalkStationHeaderProps {
  stationName?: Stations;
}

export default function WalkStationHeader({
  stationName,
}: WalkStationHeaderProps) {
  const theme = useTheme();
  const { currentUser } = useAPI();
  const navigate = useNavigate();

  if (!isStaff(currentUser)) return;

  function onNewOrder() {
    navigate('/staff/order', {
      state: {
        returnRoute: '/staff/walkup',
      },
    });
  }

  const isStationTwo = stationName === Stations.StationTwo;

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 1,
      }}
    >
      {isStationTwo ? (
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            variant="h2"
            fontWeight="600"
            sx={{ marginRight: theme.spacing(2), paddingLeft: 1 }}
          >
            {stationName}
          </Typography>
          <Button
            variant="contained"
            sx={{ marginRight: 2 }}
            onClick={onNewOrder}
          >
            New Order
          </Button>
        </Stack>
      ) : (
        <Stack sx={{ flexDirection: 'row' }}>
          <TransferWithinAStationIcon fontSize="large" />
          <Typography
            variant="h2"
            fontWeight="600"
            sx={{ marginRight: theme.spacing(2), paddingLeft: 1 }}
          >
            {stationName}
          </Typography>
        </Stack>
      )}

      {!isStationTwo && <CurrentTime />}
    </Stack>
  );
}
