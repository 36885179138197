/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RuleIcon from '@mui/icons-material/Rule';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { playAlarm } from '../../../../common/alarmFunctions';
import { ProductCategoryType } from '../../../../common/enums';
import { unfinishedOrdersForStation } from '../../../../common/orderFunctions';
import { sortOrdersById } from '../../../../common/sortFunctions';
import { Staff, StaffOrder, StaffOrderItem } from '../../../../common/types';
import InfoCard from '../../../../components/card/InfoCard';
import useAlert from '../../../../hooks/useAlert';
import { IndicatorBox } from '../../components/IndicatorBox';
import {
  getOrderPosition,
  handleFinishOrder,
  handleStartStopOrder,
  shiftOrderItems,
} from '../../functions/orderFunctions';
import {
  allColdDrinksFinished,
  allColdDrinksPlaced,
  allColdDrinksStarted,
  allFoodFinished,
  allFoodPending,
  allFoodStarted,
  allHotCoffeeOrTeaFinished,
  allHotCoffeeOrTeaStarted,
  anyFoodPending,
  sortStationOrders,
} from '../../functions/stationFunctions';
import DraggableFoodOrderCard from './DraggableFoodOrderCard';
import StationHeader from './StationHeader';

interface FoodStationProps {
  orders: StaffOrder[];
  user: Staff;
}

export default function FoodStation({ orders, user }: FoodStationProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();
  const [draggableOrders, setDraggableOrders] = useState<StaffOrder[]>([
    ...orders,
  ]);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function updateDraggableOrders(orders: StaffOrder[]) {
    const incomingOrdersAreDifferent =
      isEqual(sortOrdersById(orders), sortOrdersById(draggableOrders)) ||
      orders.length !== draggableOrders.length;
    if (incomingOrdersAreDifferent) {
      const updatedOrders = orders.map((order) => {
        const updatedItems = order.orderItems.map((orderItem) => {
          const foundOrder = draggableOrders.find(
            (dragOrder) => order.id === dragOrder.id,
          );
          const foundItem = foundOrder?.orderItems.find(
            (item) => item.id === orderItem.id,
          );

          return {
            ...orderItem,
            queueOrder: foundItem?.queueOrder ?? orderItem.queueOrder,
          };
        });

        return {
          ...order,
          orderItems: updatedItems,
        };
      });

      setDraggableOrders(updatedOrders);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    updateDraggableOrders(orders);
  }, [orders]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      let shouldRingAlarm = false;

      orders.forEach((order) => {
        if (anyFoodPending(order.orderItems)) {
          // Check if the requested pickup time is within 5 minutes of the current time or in the past
          if (order.requestedPickupTime) {
            const pickupTime = new Date(order.requestedPickupTime).getTime();
            const timeDifference = (pickupTime - currentTime) / 1000 / 60;
            if (timeDifference <= 5 || pickupTime <= currentTime) {
              shouldRingAlarm = true;
            }
          }
        }
      });

      if (shouldRingAlarm) {
        playAlarm();
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [draggableOrders]);

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id === over.id) return;

    setDraggableOrders((prevOrders) => {
      const originalPosition = getOrderPosition(active.id, draggableOrders);
      const newPosition = getOrderPosition(over.id, draggableOrders);
      const newArray = arrayMove(prevOrders, originalPosition, newPosition);
      shiftOrderItems(newArray, [ProductCategoryType.Food], showAlert);
      return newArray;
    });
  }

  const unfinishedOrders = unfinishedOrdersForStation(
    draggableOrders,
    user.stationId,
  );

  const sortedFilteredOrders = sortStationOrders(
    unfinishedOrders,
    [ProductCategoryType.Food],
    {
      isAllFinishedFn: allFoodFinished,
      isAllStartedFn: allFoodStarted,
    },
  ).map((order) => {
    const isAllFoodPlaced = allFoodPending(order.orderItems);
    const isAllFoodStarted = allFoodStarted(order.orderItems);
    const isAllFoodFinished = allFoodFinished(order.orderItems);

    return { order, isAllFoodPlaced, isAllFoodStarted, isAllFoodFinished };
  });

  return (
    <DndContext
      collisionDetection={closestCorners}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <Grid
        size={12}
        sx={{
          padding: theme.spacing(1),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StationHeader />
        <Divider />
        {sortedFilteredOrders.length === 0 ? (
          <InfoCard
            Icon={NotificationsIcon}
            title="No Results Found"
            subtitle="Try refreshing your page or check back later."
          />
        ) : (
          <Box
            sx={{
              overflowY: 'auto',
              height: 'calc(100vh - 64px)',
            }}
          >
            <Grid container spacing={1} sx={{ padding: 3 }}>
              {sortedFilteredOrders.map(
                (
                  {
                    order,
                    isAllFoodPlaced,
                    isAllFoodStarted,
                    isAllFoodFinished,
                  },
                  index,
                ) => (
                  <Grid size={12} key={`${order.customerId}-${index}`}>
                    <Paper
                      elevation={8}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: 0,
                        margin: 0,
                        height: '100%',
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.common.deepblue,
                      }}
                    >
                      {isAllFoodFinished ? (
                        <Stack
                          sx={{
                            backgroundColor: theme.palette.common.grey,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 0,
                          }}
                        >
                          <Stack
                            sx={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Stack sx={{ padding: 1, alignItems: 'center' }}>
                                <RuleIcon />
                              </Stack>
                              <Divider orientation="vertical" flexItem />
                              <Typography
                                variant="h3"
                                sx={{ marginLeft: theme.spacing(1) }}
                              >
                                {order.customerName}
                              </Typography>
                            </Stack>

                            <Stack sx={{ paddingRight: 2 }}>
                              {order.orderItems.some(
                                (item: StaffOrderItem) =>
                                  item.productCategoryType ===
                                  ProductCategoryType.ColdDrinks,
                              ) && (
                                <IndicatorBox
                                  orderItems={order.orderItems}
                                  label="Cold Drinks"
                                  allStartedFn={allColdDrinksStarted}
                                  allFinishedFn={allColdDrinksFinished}
                                  allPendingFn={allColdDrinksPlaced}
                                />
                              )}
                              {order.orderItems.some(
                                (item: StaffOrderItem) =>
                                  item.productCategoryType ===
                                    ProductCategoryType.HotCoffee ||
                                  item.productCategoryType ===
                                    ProductCategoryType.Tea,
                              ) && (
                                <IndicatorBox
                                  orderItems={order.orderItems}
                                  label="Hot Coffee"
                                  allStartedFn={allHotCoffeeOrTeaStarted}
                                  allFinishedFn={allHotCoffeeOrTeaFinished}
                                  allPendingFn={allHotCoffeeOrTeaFinished}
                                />
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      ) : (
                        <SortableContext
                          items={draggableOrders as any} //need this to be any as dnd-kit is not typed.
                          strategy={verticalListSortingStrategy}
                        >
                          <DraggableFoodOrderCard
                            order={order}
                            isAllFoodPlaced={isAllFoodPlaced}
                            isAllFoodStarted={isAllFoodStarted}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            handleStartOrder={() => {
                              handleStartStopOrder(
                                order,
                                true,
                                [ProductCategoryType.Food],
                                showAlert,
                                setIsLoading,
                              );
                            }}
                            handleStopOrder={() => {
                              handleStartStopOrder(
                                order,
                                false,
                                [ProductCategoryType.Food],
                                showAlert,
                                setIsLoading,
                              );
                            }}
                            handleFinishOrder={() =>
                              handleFinishOrder(
                                order,
                                [ProductCategoryType.Food],
                                showAlert,
                                setIsLoading,
                              )
                            }
                          />
                        </SortableContext>
                      )}
                    </Paper>
                  </Grid>
                ),
              )}
            </Grid>
          </Box>
        )}
      </Grid>
    </DndContext>
  );
}
