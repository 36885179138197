import useMediaQuery from '@mui/material/useMediaQuery';
import { TabletSize } from '../common/enums';
import { TPGEMediaQuery } from '../common/types';

export default function useTPGEMediaQuery(): TPGEMediaQuery {
  const isLargeScreen = useMediaQuery(TabletSize.Large);
  const isMediumScreen = useMediaQuery(TabletSize.Medium);
  const isSmallScreen = useMediaQuery(TabletSize.Small);

  if (isSmallScreen) return 'small';
  if (isMediumScreen) return 'medium';
  if (isLargeScreen) return 'large';
  return 'xlarge';
}
