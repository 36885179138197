import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { ProductCategory } from '../../../../common/types';
import AdminPageFilter from '../../components/AdminPageFilter';

interface FilterSectionProps {
  filterText: string;
  setFilterText: (value: string) => void;
  selectedCategoryId: number;
  setSelectedCategoryId: (value: number) => void;
  showDisabled: boolean;
  toggleShowDisabled: () => void;
  productCategories: ProductCategory[];
}

export function ProductFilter({
  filterText,
  setFilterText,
  selectedCategoryId,
  setSelectedCategoryId,
  showDisabled,
  toggleShowDisabled,
  productCategories,
}: FilterSectionProps) {
  return (
    <AdminPageFilter filterText={filterText} setFilterText={setFilterText}>
      <TextField
        select
        size="small"
        label="Filter by Category"
        value={selectedCategoryId}
        onChange={(e) => setSelectedCategoryId(+e.target.value)}
        variant="outlined"
        sx={{ minWidth: 200, paddingRight: 2 }}
      >
        <MenuItem value={0}>Any</MenuItem>
        {productCategories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </TextField>
      <FormControlLabel
        control={
          <Switch
            checked={showDisabled}
            onChange={() => toggleShowDisabled()}
          />
        }
        label="Show disabled products"
      />
    </AdminPageFilter>
  );
}
