import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useState } from 'react';
import {
  createComponentType,
  updateComponentType,
} from '../../../api/componentTypes.tsx';
import { hasAdminRole } from '../../../common/roleFunctions.tsx';
import { TpgeComponentType, TPGEMediaQuery } from '../../../common/types.tsx';
import useComponentTypes from '../../../datastore/useComponentTypes.tsx';
import useAlert from '../../../hooks/useAlert.tsx';
import useAPI from '../../../hooks/useAPI.tsx';
import useTPGEMediaQuery from '../../../hooks/useTPGEMediaQuery.tsx';
import { AdminHeader } from '../components/AdminHeader.tsx';
import AdminPageContent from '../components/AdminPageContent.tsx';
import AdminPagination from '../components/AdminPagination.tsx';
import { ComponentTypesFilter } from './components/ComponentTypesFilter.tsx';
import ComponentTypesModal from './components/ComponentTypesModal.tsx';
import { ComponentTypesTable } from './components/ComponentTypesTable.tsx';

const RowsPerPage: { [key in TPGEMediaQuery]: number } = {
  xlarge: 9,
  large: 7,
  medium: 5,
  small: 4,
};

const filterData = (
  components: TpgeComponentType[] = [],
  showDisabled = false,
  filterText = '',
): TpgeComponentType[] => {
  const lowerFilter = filterText.toLowerCase();
  const data = components
    .filter(
      (component) =>
        (showDisabled || component.isActive === true) &&
        (filterText.length
          ? component.name.toLowerCase().includes(lowerFilter)
          : true),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return data;
};

const newObj = (): TpgeComponentType => ({ id: 0, name: '', isActive: true });

export default function ComponentTypesPage() {
  const { currentUser } = useAPI();
  const [showDisabled, setShowDisabled] = useState(false);
  const {
    componentTypes,
    loading,
    fetchComponentTypes: refetch,
  } = useComponentTypes();
  const { showAlert } = useAlert();
  const mediaSize = useTPGEMediaQuery();
  const countPerPage = RowsPerPage[mediaSize];
  const [filterText, setFilterText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [mutating, setMutating] = useState(false);
  const [mutateObj, setMutateObj] = useState<TpgeComponentType | null>(null);

  const isLoading = loading || mutating;

  async function handleSave(obj: TpgeComponentType) {
    const mode = obj.id > 0 ? 'edit' : 'create';
    const mutateFn =
      mode === 'edit' ? updateComponentType : createComponentType;
    const errorText = mode === 'edit' ? 'updating' : 'creating';
    const successText = mode === 'edit' ? 'updated' : 'created';
    setMutating(true);
    try {
      const result = await mutateFn(obj);
      if (result.status !== 200) {
        showAlert(
          `Error ${errorText} ComponentType, refresh and try again`,
          'error',
        );
        return;
      } else {
        showAlert(`Component Type ${successText} successfully`, 'success');
        refetch();
      }
      if (mutateObj) {
        setMutateObj(null);
      }
    } catch (err) {
      showAlert(
        `Error ${errorText} ComponentType, refresh and try again`,
        'error',
      );
    } finally {
      setMutating(false);
    }
  }

  async function toggleActive(obj: TpgeComponentType) {
    const updated = { ...obj, isActive: !obj.isActive };
    handleSave(updated);
  }

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function changeDisabledFilter(includeDisabled: boolean) {
    setShowDisabled(includeDisabled);
    refetch(includeDisabled);
  }

  const filteredComponents = filterData(
    componentTypes || [],
    showDisabled,
    filterText,
  );

  const totalNumberOfPages = Math.ceil(
    filteredComponents.length / countPerPage,
  );

  const paginatedData = filteredComponents.slice(
    (currentPage - 1) * countPerPage,
    currentPage * countPerPage,
  );

  if (!hasAdminRole(currentUser)) {
    return null;
  }

  return (
    <>
      <AdminHeader
        title="Component Types"
        description="Manage component types"
        icon={<ShoppingCartIcon fontSize="large" sx={{ color: 'white' }} />}
        buttonText="New Component Type"
        buttonAction={() => setMutateObj(newObj())}
      />
      <AdminPageContent>
        <ComponentTypesFilter
          filterText={filterText}
          setFilterText={setFilterText}
          showDisabled={showDisabled}
          setShowDisabled={() => changeDisabledFilter(!showDisabled)}
        />
        <ComponentTypesTable
          isLoading={isLoading}
          paginatedData={paginatedData}
          showDisabled={showDisabled}
          statusToggle={toggleActive}
          onEditClicked={(componentType) => setMutateObj({ ...componentType })}
        />
        <AdminPagination
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </AdminPageContent>
      {!!mutateObj && (
        <ComponentTypesModal
          data={mutateObj}
          mode={mutateObj.id > 0 ? 'edit' : 'create'}
          onClose={() => setMutateObj(null)}
          onSave={handleSave}
          loading={isLoading}
        />
      )}
    </>
  );
}
