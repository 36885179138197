import Stack from '@mui/material/Stack';
import axios from 'axios';
import { createContext, MouseEvent, useEffect, useState } from 'react';
import staffAuthData from '../api/staffAuthData';
import LocalStorageSvc, {
  JWT_KEY,
  USER_DATA_KEY,
} from '../common/localStorageSvc';
import { isStaff } from '../common/typeAssertionFunctions';
import { Staff } from '../common/types';
import useAPI from '../hooks/useAPI';
import useIsOnline from '../hooks/useIsOnline';
import StaffLogin from '../pages/staff/login/StaffLogin';

interface StaffLoginContextValue {
  logout: (e?: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const StaffLoginContext = createContext<StaffLoginContextValue | null>(
  null,
);

export default function StaffLoginProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { currentUser, setCurrentUser } = useAPI();
  const { isOnline } = useIsOnline();
  const auth = staffAuthData(
    isStaff(currentUser) ? currentUser : null,
    setCurrentUser as (user: Staff | null) => void,
    isOnline,
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const jwtToken = LocalStorageSvc.load<string>(JWT_KEY);
    if (jwtToken) {
      const userData = LocalStorageSvc.load<Staff>(USER_DATA_KEY);
      // Set token in current user state
      setCurrentUser({
        ...(userData || ({} as Staff)),
        jwtToken,
      });
    }
  }, []);

  // Interceptor to detect token issues
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      function (response) {
        // This is for 200 responses
        return response;
      },
      function (error) {
        // Logout if token expired
        if (error.response?.status === 401) {
          //@todo should show a modal or something prior to logout
          logout();
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [logout]);

  async function refreshOnTimeout() {
    timer && clearTimeout(timer);
    const timeoutId = setTimeout(async () => {
      await auth?.refreshToken();
    }, 300000);
    setTimer(timeoutId);
  }

  useEffect(() => {
    if (currentUser?.jwtToken) {
      LocalStorageSvc.save<string>(JWT_KEY, currentUser.jwtToken);
      LocalStorageSvc.save<Staff>(USER_DATA_KEY, currentUser as Staff);
      refreshOnTimeout().then(() => console.info('refreshToken called'));
    }
  }, [currentUser?.jwtToken]);

  async function logout(e?: MouseEvent<HTMLButtonElement, MouseEvent>) {
    console.info('staff logout invoked');
    e?.preventDefault();
    LocalStorageSvc.remove(JWT_KEY);
    LocalStorageSvc.remove(USER_DATA_KEY);
    setCurrentUser(null);
  }

  const value: StaffLoginContextValue = {
    logout,
  };

  return (currentUser?.jwtToken?.length || 0) > 0 ? (
    <StaffLoginContext.Provider value={value}>
      {children}
    </StaffLoginContext.Provider>
  ) : (
    <Stack
      sx={{
        minWidth: '100vw',
        minHeight: '100vh',
        background: 'linear-gradient(180deg, #F4EEE2 0%, #F4EEE2 100%)',
      }}
    >
      <Stack
        spacing={1}
        sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}
      >
        {auth && <StaffLogin login={auth.login} logout={logout} />}
      </Stack>
    </Stack>
  );
}
