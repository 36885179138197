import ReceiptIcon from '@mui/icons-material/Receipt';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface MenuHeaderProps {
  searchProduct: string;
  setSearchProduct: (value: string) => void;
}

export default function MenuHeader({
  searchProduct,
  setSearchProduct,
}: MenuHeaderProps) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack sx={{ flexDirection: 'row' }}>
        <ReceiptIcon fontSize="large" />
        <Typography
          variant="h2"
          fontWeight="600"
          sx={{ marginRight: theme.spacing(2), paddingLeft: 1 }}
        >
          Menu
        </Typography>
      </Stack>
      <TextField
        label="Search by product name"
        size="small"
        value={searchProduct}
        onChange={(e) => setSearchProduct(e.target.value)}
      />
    </Stack>
  );
}
