import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';

export interface LoadingModalProps {
  open: boolean;
}
export default function LoadingModal({ open }: LoadingModalProps) {
  return (
    <Dialog open={open} maxWidth="xs">
      <Stack spacing={8} sx={{ padding: '6em 8em 6em 8em' }}>
        <Stack>
          <CircularProgress />
        </Stack>
      </Stack>
    </Dialog>
  );
}
