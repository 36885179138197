export const userName = 'User';
export const passwordName = 'Password';
export const bearer = 'Bearer';
export const apiUrl = '/api/';
export const reportColors = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#8884d8',
];
export const allOption = 'All';
