import { Staff, Customer } from './types.tsx';
import { Role } from './enums.tsx';

export function isStaff(user: Staff | Customer | null): user is Staff {
  return (
    !!user &&
    'roleName' in user &&
    (user.roleName === Role.Admin || user.roleName === Role.General)
  );
}
