import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import {
  calculateFinalPrice,
  calculatePriceDifferenceWithSign,
  productVariantPriceAdjustment,
} from '../../../../common/moneyFunctions';
import {
  Order,
  OrderItem,
  Product,
  ProductSize,
  ProductVariant,
  ProductVariantWithQty,
} from '../../../../common/types';
import ConfirmModal from '../../../../components/modal/ConfirmModal';

interface OrderItemCardsProps {
  orderData: Order;
  products: Product[];
  removeOrderItem: (id: number) => void;
  changeOrderItemSize: (id: number, newSizeId: number) => void;
  changeOrderStation: (stationName: string) => void;
  editOrderItemVariations: (id: number) => void;
  submitOrder: (customerName: string) => void;
}

function groupVariants(variants: ProductVariant[]): ProductVariantWithQty[] {
  const grouped = variants
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce<Record<number, ProductVariantWithQty>>((acc, variant) => {
      const key = variant.id;
      if (!acc[key]) {
        acc[key] = { ...variant, quantity: 0 };
      }
      acc[key].quantity += 1;
      return acc;
    }, {});

  return Object.values(grouped);
}

const ProductSizeSortOrder = ['Small', 'Regular', 'Large'];
const sortSizes = (productSizes: ProductSize[]): ProductSize[] => {
  return productSizes.sort(
    (a, b) =>
      ProductSizeSortOrder.indexOf(a.sizeName) -
      ProductSizeSortOrder.indexOf(b.sizeName),
  );
};

export default function OrderItemCards({
  orderData,
  products,
  removeOrderItem,
  changeOrderItemSize,
  editOrderItemVariations,
}: OrderItemCardsProps) {
  const theme = useTheme();
  const [removeOrderItemId, setRemoveOrderItemId] = useState<number | null>(
    null,
  );

  function handleEditClick(id: number): void {
    editOrderItemVariations(id);
  }

  function handleSizeChange(itemId: number, selectedSizeId: number): void {
    changeOrderItemSize(itemId, selectedSizeId);
  }

  return (
    <>
      <Stack
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          marginBottom: theme.spacing(2),
        }}
      >
        {orderData.orderItems.map((orderItem: OrderItem, index: number) => {
          const productSize = products
            .find((f) => f.id === orderItem.productId)
            ?.productSizes.find((f) => f.id === orderItem.productSizeId);
          if (!productSize) {
            console.error('productSize not found', {
              productId: orderItem.productId,
              productSizeId: orderItem.productSizeId,
            });
            return null;
          }
          return (
            <Paper
              key={`${orderItem.id}${index}`}
              sx={{
                backgroundColor: theme.palette.common.grey,
                marginTop: index === 0 ? 0 : theme.spacing(2),
                marginBottom: 0.4,
              }}
              elevation={2}
            >
              <Box sx={{ padding: 0 }}>
                <Stack
                  sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <IconButton
                    color="primary"
                    aria-label="edit order item"
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.common.offWhite,
                      },
                    }}
                    onClick={() => {
                      handleEditClick(orderItem.id);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <Typography variant="body1" sx={{ alignSelf: 'center' }}>
                    {orderItem.productName}
                  </Typography>

                  <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
                    <Typography variant="body2">
                      ${calculateFinalPrice(orderItem).toFixed(2)}
                    </Typography>
                    <IconButton
                      color="error"
                      aria-label="remove from order"
                      sx={{
                        '&:hover': {
                          backgroundColor: theme.palette.common.offWhite,
                        },
                      }}
                      onClick={() => {
                        setRemoveOrderItemId(orderItem.id);
                      }}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Stack>
                </Stack>
                {orderItem.sizeOptions.length > 0 && (
                  <Stack>
                    <Tabs
                      value={orderItem.productSizeId}
                      onChange={(_, newValue: number) =>
                        handleSizeChange(orderItem.id, newValue)
                      }
                      sx={{
                        alignItems: 'center',
                        alignSelf: 'center',
                        '& .MuiTabs-indicator': {
                          backgroundColor: theme.palette.primary.main,
                        },
                        '& .MuiTab-root': {
                          color: theme.palette.common.deepblue,
                        },
                      }}
                    >
                      {orderItem.sizeOptions.length &&
                        sortSizes(orderItem.sizeOptions).map((option) => (
                          <Tab
                            key={option.id}
                            sx={{
                              '&.Mui-selected': {
                                color: theme.palette.primary.main,
                              },
                            }}
                            value={option.id}
                            label={option.sizeName}
                          />
                        ))}
                    </Tabs>
                  </Stack>
                )}
              </Box>
              {orderItem.selectedVariants.length > 0 && (
                <Stack flexDirection="column">
                  {groupVariants(orderItem.selectedVariants).map((variant) => (
                    <Typography
                      variant="caption"
                      key={`${variant.id}`}
                      sx={{ margin: 0, paddingBottom: 0, paddingX: 1 }}
                    >
                      {variant.name}
                      {variant.quantity > 1
                        ? ` x${variant.quantity}`
                        : ''}:{' '}
                      {calculatePriceDifferenceWithSign(
                        productVariantPriceAdjustment(variant, productSize)
                          .times(variant.quantity)
                          .toNumber(),
                      )}
                    </Typography>
                  ))}
                </Stack>
              )}
              {orderItem.notes && (
                <Typography
                  variant="caption"
                  sx={{ margin: 0, marginBottom: 1, paddingX: 1 }}
                >
                  Notes: {orderItem.notes}
                </Typography>
              )}
            </Paper>
          );
        })}
      </Stack>

      {removeOrderItemId && (
        <ConfirmModal
          onClose={() => setRemoveOrderItemId(null)}
          open={!!removeOrderItemId}
          title="Remove Item"
          content="Are you sure you want to remove this item?"
          onConfirm={() => {
            removeOrderItem(removeOrderItemId);
            setRemoveOrderItemId(null);
          }}
        />
      )}
    </>
  );
}
