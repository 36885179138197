import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import staffApi from '../../../api/staff';
import LocalStorageSvc, {
  USER_DATA_KEY,
} from '../../../common/localStorageSvc';
import { isStaff } from '../../../common/typeAssertionFunctions';
import { Staff } from '../../../common/types';
import StyledButton from '../../../components/button/StyledButton';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import DarkUpperSection from '../../../components/section/DarkUpperSection';
import StyledSelect from '../../../components/select/StyledSelect';
import useStations from '../../../datastore/useStations';
import useAPI from '../../../hooks/useAPI';
import useAlert from '../../../hooks/useAlert';

export default function StaffSettings() {
  const { currentUser, setCurrentUser } = useAPI();

  const initialStationId = (currentUser as Staff)?.stationId ?? 0;
  const { stations = [], loading: isLoading } = useStations();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [selectedStation, setSelectedStation] = useState<{
    stationName: string;
    id: number;
  }>({ stationName: '', id: initialStationId });
  const theme = useTheme();
  const { showAlert } = useAlert();

  function updateStation(stationId: number) {
    if (isStaff(currentUser) && !!stationId) {
      setIsDirty(true);
      const foundStation = stations.find((s) => s.id === stationId);
      if (!foundStation) {
        showAlert('Station not found', 'warning');
        return;
      }
      setSelectedStation({ id: +stationId, stationName: foundStation.name });
    }
  }

  async function handleSaveStation() {
    if (!selectedStation?.id) {
      showAlert('Please select a station', 'error');
      return;
    }
    if (isStaff(currentUser)) {
      try {
        const result = await staffApi.updateStation(
          currentUser?.email,
          selectedStation.id,
        );
        if (result.status === 200) {
          const updatedUser = {
            ...currentUser,
            stationId: selectedStation.id,
            stationName: selectedStation.stationName,
          };
          setCurrentUser(updatedUser);
          LocalStorageSvc.save(USER_DATA_KEY, updatedUser);
          showAlert('Changes saved successfully', 'success');
          setIsDirty(false);
        } else {
          showAlert('Failed to save changes', 'error');
        }
      } catch (error) {
        showAlert('Failed to save changes', 'error');
        console.error(error);
      }
    }
  }

  if (!stations.length || !isStaff(currentUser)) return null;

  const orderedStations = [
    { value: 0, name: 'Choose Station', id: 0 },
    ...stations.sort((a, b) => a.name.localeCompare(b.name)),
  ];

  return (
    <DarkUpperSection>
      <Stack spacing={4} sx={{ padding: '1em 0' }}>
        <Typography
          variant="h2"
          fontWeight="bold"
          color={theme.palette.primary.contrastText}
        >
          Settings
        </Typography>
        <Stack direction="row" spacing={4} sx={{ alignItems: 'center' }}>
          <Typography variant="h4" color={theme.palette.primary.contrastText}>
            Set your Station:
          </Typography>
          <LoadingSpinner isLoading={isLoading}>
            <StyledSelect
              value={
                (selectedStation
                  ? selectedStation.id
                  : currentUser?.stationId) || 0
              }
              onChange={(e) => updateStation(e ? +e : 0)}
            >
              {orderedStations?.map((station) => (
                <MenuItem
                  key={station.id}
                  value={station.id}
                  disabled={station.id === 0}
                >
                  {station.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </LoadingSpinner>
          <Stack sx={{ flex: 1, alignItems: 'end' }}>
            <StyledButton
              variant="contained"
              sx={{ whiteSpace: 'nowrap' }}
              onClick={handleSaveStation}
              disabled={!isDirty}
            >
              Save Changes
            </StyledButton>
          </Stack>
        </Stack>
      </Stack>
    </DarkUpperSection>
  );
}
