import { create } from 'zustand';
import { Customer, Staff } from '../common/types';

interface APIState {
  currentUser: Staff | Customer | null;
  setCurrentUser: (currentUser: Staff | Customer | null) => void;
}

const useAPI = create<APIState>((set) => ({
  currentUser: null,
  setCurrentUser: (currentUser: Staff | Customer | null): void =>
    set(() => ({ currentUser })),
}));

export default useAPI;
