import Decimal from 'decimal.js';
import { ProductVariantTypeEnum } from '../common/enums';
import { calculateFinalPrice } from '../common/moneyFunctions';
import { getOrderItemKey } from '../common/orderFunctions';
import {
  OrderItem,
  ProductSize,
  ProductVariant,
  ProductVariantWithQty,
} from '../common/types';

const getVariationsOfType = (
  variants: ProductVariant[],
  pvType: ProductVariantTypeEnum,
): [ProductVariant[], boolean] => {
  const filtered = variants.filter(
    (variant) => variant.productVariantTypeName === pvType && variant.isActive,
  );
  return [filtered, !!filtered.length];
};

const removeAllVariantsOfType = (
  selectedVariants: ProductVariant[],
  newVariantTypeName: string,
): ProductVariant[] => {
  return selectedVariants.filter(
    (variant) => variant.productVariantTypeName !== newVariantTypeName,
  );
};

const filterOutMutuallyExclusiveVariants = (
  selectedVariants: ProductVariant[],
  newVariantTypeName: string,
): ProductVariant[] => {
  if (
    [ProductVariantTypeEnum.Beans, ProductVariantTypeEnum.Milk].includes(
      newVariantTypeName as ProductVariantTypeEnum,
    )
  ) {
    return removeAllVariantsOfType(selectedVariants, newVariantTypeName);
  }
  return selectedVariants;
};

const orderHasSelectedVariant = (
  localItem: OrderItem,
  variant: ProductVariant,
): boolean => {
  return !!localItem.selectedVariants.find((f) => f.id === variant.id);
};

const addVariationToOrderItem = (
  localItem: OrderItem,
  variationId: number | string,
): OrderItem | null => {
  let selectedVariants = [...localItem.selectedVariants];

  // special case no milk
  if (variationId === OrderItemViewModel.NO_MILK) {
    selectedVariants = filterOutMutuallyExclusiveVariants(
      selectedVariants,
      ProductVariantTypeEnum.Milk,
    );
    return { ...localItem, selectedVariants };
  }

  const variation = localItem.allowedVariants.find(
    (variant) => variant.id === variationId && variant.isActive,
  );
  if (!variation) {
    console.error('selected variation not allowed or not active');
    return null;
  }

  selectedVariants = filterOutMutuallyExclusiveVariants(
    selectedVariants,
    variation.productVariantTypeName,
  );

  if (variation.productVariantTypeName === ProductVariantTypeEnum.Beans) {
    // also clear out any existing extra shots of another bean type
    selectedVariants = removeAllVariantsOfType(
      selectedVariants,
      ProductVariantTypeEnum.Shot,
    );
  }

  selectedVariants.push({ ...variation });
  return { ...localItem, selectedVariants };
};

export class OrderItemViewModel {
  private readonly item: OrderItem;
  public static readonly NO_MILK = 'No Milk';
  public static readonly SELECT_OPTION = 'Select option';
  public allowsSizeVariations = false;

  public shotVariations: ProductVariant[] = [];
  public allowsShotsVariation = false;

  public milkVariations: ProductVariant[] = [];
  public allowsMilkVariations = false;

  public otherVariations: ProductVariant[] = [];
  public allowsOtherVariations = false;

  public beansVariations: ProductVariant[] = [];
  public allowsBeansVariations = false;
  public showBeansVariations = false;

  public selectedSize: ProductSize;
  public selectedMilk: ProductVariant | undefined;
  public selectedBean: ProductVariant | undefined;
  public selectedShot: ProductVariant | undefined;

  public shotCount: number = 0;

  public variationsWithQty: Record<string, ProductVariantWithQty> = {};

  public notes: string = '';

  public total: Decimal = new Decimal(0);

  constructor(item: OrderItem) {
    this.item = { ...item };
    this.notes = this.item.notes;

    this.allowsSizeVariations =
      this.item.sizeOptions.filter((option) => option.isActive).length > 1;

    const [sv, allowsSV] = getVariationsOfType(
      this.item.allowedVariants,
      ProductVariantTypeEnum.Shot,
    );
    this.shotVariations = sv;
    this.allowsShotsVariation = allowsSV;

    const [mv, allowsMV] = getVariationsOfType(
      this.item.allowedVariants,
      ProductVariantTypeEnum.Milk,
    );
    this.milkVariations = mv;
    this.allowsMilkVariations = allowsMV;

    const [ov, allowsOV] = getVariationsOfType(
      this.item.allowedVariants,
      ProductVariantTypeEnum.Other,
    );
    this.otherVariations = ov;
    this.allowsOtherVariations = allowsOV;

    const [abv, allowsBV] = getVariationsOfType(
      this.item.allowedVariants,
      ProductVariantTypeEnum.Beans,
    );
    this.beansVariations = abv;
    this.allowsBeansVariations = allowsBV;
    this.showBeansVariations = allowsBV && abv.length > 1;

    this.selectedSize =
      this.item.sizeOptions.find(
        (option) => option.id === this.item.productSizeId,
      ) || this.item.sizeOptions[0];

    this.selectedMilk = this.item.selectedVariants.find(
      (variant) =>
        variant.productVariantTypeName === ProductVariantTypeEnum.Milk,
    );

    this.shotCount = this.item.selectedVariants.filter(
      (variant) =>
        variant.productVariantTypeName === ProductVariantTypeEnum.Shot,
    ).length;

    this.determineBeanVariant();
    this.addDefaultBeans();
    this.determineShotVariant();
    this.setVariationAdjustments();

    this.total = calculateFinalPrice(this.item);
  }

  private determineBeanVariant() {
    const existing = this.item.selectedVariants.find(
      (f) => f.productVariantTypeName === ProductVariantTypeEnum.Beans,
    );
    if (existing) {
      this.selectedBean = existing;
    } else if (this.beansVariations.length) {
      this.selectedBean =
        this.beansVariations.find((f) => f.isCore) || this.beansVariations[0];
    } else {
      this.selectedBean = undefined;
    }
  }

  private addDefaultBeans() {
    if (
      this.selectedBean &&
      this.allowsBeansVariations &&
      !this.item.selectedVariants.find(
        (f) => f.productVariantTypeName === ProductVariantTypeEnum.Beans,
      )
    ) {
      const tmp = addVariationToOrderItem(this.item, this.selectedBean.id);
      if (tmp) {
        this.item.selectedVariants = tmp.selectedVariants;
      }
    }
  }

  private determineShotVariant() {
    if (this.selectedBean?.component) {
      const componentId = this.selectedBean.component.id;
      this.selectedShot = this.shotVariations.find(
        (f) => f.componentId === componentId,
      );
      if (componentId && !this.selectedShot) {
        console.info(
          'determineShotVariant: failed to locate an Extra Shot option for ',
          this.selectedBean.name,
        );
      }
    }
  }

  private setVariationAdjustments() {
    this.variationsWithQty = this.item.selectedVariants.reduce(
      (output, variant, i, arr) => {
        const firstIndexByVariantName = arr.findIndex(
          (v) => v.name === variant.name,
        );
        if (firstIndexByVariantName !== i) {
          return output;
        }
        const allMatchingVariants = arr.filter((v) => v.id === variant.id);

        return {
          ...output,
          [variant.id]: {
            ...variant,
            quantity: allMatchingVariants.length,
          },
        };
      },
      {},
    );
  }

  public addVariant(variantId: number | string): OrderItemViewModel {
    const newItem = addVariationToOrderItem(this.item, variantId);
    const result = newItem
      ? new OrderItemViewModel(newItem)
      : new OrderItemViewModel(this.item);
    return result;
  }

  public removeVariant(
    variantId: number | string | undefined,
  ): OrderItemViewModel {
    if (variantId === undefined) {
      return new OrderItemViewModel(this.item);
    }

    const numericVariantId = Number(variantId);

    const lastIndex = this.item.selectedVariants
      .map((v) => v.id)
      .lastIndexOf(numericVariantId);

    if (lastIndex > -1) {
      this.item.selectedVariants.splice(lastIndex, 1);
    } else {
      console.info('Selected variation not found');
    }

    return new OrderItemViewModel(this.item);
  }

  public hasSelectedVariant(variant: ProductVariant): boolean {
    return orderHasSelectedVariant(this.item, variant);
  }

  public setNotes(newNotes: string): OrderItemViewModel {
    this.item.notes = newNotes;
    return new OrderItemViewModel(this.item);
  }

  public setSize(sizeId: string | number): OrderItemViewModel {
    this.item.productSizeId = +sizeId;
    return new OrderItemViewModel(this.item);
  }

  public toItem(): OrderItem {
    return this.item;
  }

  public itemEqualsOrderItem(orderItem: OrderItem): boolean {
    const thisItemKey = getOrderItemKey(this.item);
    const otherOrderItemKey = getOrderItemKey(orderItem);
    return thisItemKey.localeCompare(otherOrderItemKey) === 0;
  }
}
