import { create } from 'zustand';
import customersApi from '../api/customer';
import { CustomerInfo } from '../common/types';

type CustomersStore = {
  hasLoaded: boolean;
  customers: CustomerInfo[];
  loading: boolean;
  error: unknown | null;
  fetchCustomers: () => void;
};

const useCustomers = create<CustomersStore>((set, get) => ({
  hasLoaded: false,
  customers: [],
  loading: false,
  error: null,
  fetchCustomers: async () => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await customersApi.getAll();
      set({ customers: result.data ?? [], loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
}));

export default useCustomers;

if (!useCustomers.getState().hasLoaded) {
  useCustomers.getState().fetchCustomers();
}
