import { create } from 'zustand';
import staffApi from '../api/staff';
import { StaffAccount } from '../common/types';

type StaffStore = {
  hasLoaded: boolean;
  showDisabled: boolean;
  setShowDisabled: (showDisabled: boolean) => void;
  staff: StaffAccount[];
  loading: boolean;
  error: unknown | null;
  fetchStaff: () => void;
};

const useStaff = create<StaffStore>((set, get) => ({
  hasLoaded: false,
  showDisabled: false,
  staff: [],
  loading: false,
  error: null,
  fetchStaff: async () => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await staffApi.listStaff();
      const showDisabled = get().showDisabled;
      const staffData = result.data ?? [];
      const staff = showDisabled
        ? staffData
        : staffData.filter((s) => s.isActive);
      set({ staff, loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
  setShowDisabled: (showDisabled: boolean) => {
    set({ showDisabled });
    get().fetchStaff();
  },
}));

export default useStaff;

// make that first call without having to useEffect in a component
if (!useStaff.getState().hasLoaded) {
  useStaff.getState().fetchStaff();
}
