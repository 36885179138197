import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

interface PaginationProps {
  totalNumberOfPages: number;
  currentPage: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  color?: 'primary' | 'secondary' | 'standard';
  size?: 'small' | 'medium' | 'large';
}

export default function AdminPagination({
  totalNumberOfPages,
  currentPage,
  handlePageChange,
  color = 'primary',
  size = 'medium',
}: PaginationProps) {
  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="center"
      sx={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}
    >
      <Pagination
        count={totalNumberOfPages}
        page={currentPage}
        onChange={handlePageChange}
        color={color}
        size={size}
      />
    </Stack>
  );
}
