import { useEffect } from 'react';
import useAlert from '../../hooks/useAlert';
import useIsOnline from '../../hooks/useIsOnline';

export default function IsOnlineCoordinator() {
  const { setIsOnline } = useIsOnline();
  const { showAlert } = useAlert();

  useEffect(() => {
    function changeStatus() {
      const status = navigator.onLine;
      setIsOnline(status);
      showAlert(
        `You are ${status ? 'on' : 'off'}line`,
        status ? 'info' : 'warning',
        2000,
      );
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    changeStatus();

    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);

  return false;
}
