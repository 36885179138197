import AddIcon from '@mui/icons-material/Add';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { PriorityCoffee } from '../../../../common/enums';
import {
  formatCurrency,
  productBasePrice,
} from '../../../../common/moneyFunctions';
import { OrderItem, Product } from '../../../../common/types';
import InfoCard from '../../../../components/card/InfoCard';
import ProductCategoryTypeIcon from '../../../../components/icons/ProductCategoryTypeIcon';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';

interface MenuCardsProps {
  isLoading: boolean;
  productData: Product[] | undefined;
  searchProduct: string;
  orderItems: OrderItem[];
  selectedCategory: string;
  addOrderItem(productId: number): void;
}

function getPriority(name: string) {
  switch (name) {
    case PriorityCoffee.Cappuccino:
      return 1;
    case PriorityCoffee.Late:
      return 2;
    case PriorityCoffee.FlatWhite:
      return 3;
    default:
      return 4;
  }
}

export default function MenuCards({
  isLoading,
  productData,
  searchProduct,
  orderItems,
  selectedCategory,
  addOrderItem,
}: MenuCardsProps) {
  const theme = useTheme();

  const sortedProductData =
    productData?.sort((a, b) => {
      const priorityA = getPriority(a.name);
      const priorityB = getPriority(b.name);

      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }
      return a.name.localeCompare(b.name);
    }) ?? [];

  return (
    <LoadingSpinner isLoading={isLoading}>
      <>
        <Grid container spacing={2}>
          {sortedProductData.map((product, index) => {
            if (
              product.productCategory.name === selectedCategory &&
              (searchProduct === '' ||
                product.name
                  .toLowerCase()
                  .includes(searchProduct.toLowerCase()))
            ) {
              return (
                <Grid
                  size={{
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                  }}
                  key={`${product.id}-${index}`}
                >
                  <Paper
                    elevation={8}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      padding: 2,
                      margin: 1,
                      marginTop: 4,
                      height: '100%',
                      backgroundColor: theme.palette.common.grey,
                      color: theme.palette.primary.main,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <ProductCategoryTypeIcon
                        category={product.productCategory.name}
                      />
                      <IconButton
                        color="primary"
                        aria-label="add to order"
                        sx={{
                          '&:hover': {
                            backgroundColor: theme.palette.common.grey,
                          },
                        }}
                        onClick={() => addOrderItem(product.id)}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                    <Box>
                      <Typography variant="body1">{product.name}</Typography>
                      <Typography variant="body2">
                        ${formatCurrency(productBasePrice(product))}
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      Quantity:{' '}
                      {
                        orderItems.filter(
                          (item) => item.productName === product.name,
                        ).length
                      }
                    </Typography>
                  </Paper>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
        {productData &&
          productData.filter(
            (product) =>
              product.productCategory.name === selectedCategory &&
              (searchProduct === '' ||
                product.name
                  .toLowerCase()
                  .includes(searchProduct.toLowerCase())),
          ).length === 0 && (
            <InfoCard
              Icon={SearchOffIcon}
              title="Sorry, your search did not return any results"
              subtitle="Please check your search criteria and try again"
            />
          )}
      </>
    </LoadingSpinner>
  );
}
