import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { formatTime } from '../../../../common/timeFunctions';

export default function CurrentTime() {
  const theme = useTheme();
  const [currentTime, setCurrentTime] = useState(formatTime(new Date()));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(formatTime(new Date()));
    }, 30000);

    return () => clearInterval(interval);
  }, []);
  return (
    <Typography
      variant="h2"
      fontWeight="600"
      sx={{ marginRight: theme.spacing(2) }}
    >
      {currentTime}
    </Typography>
  );
}
