import { StaffAuthEndpoint } from '../common/endpoints';
import LocalStorageSvc, {
  JWT_KEY,
  USER_DATA_KEY,
} from '../common/localStorageSvc';
import { ServerResponse, Staff } from '../common/types';
import { postRequest } from './requests';

export default function staffAuthData(
  currentUser: Staff | null,
  setCurrentUser: (user: Staff | null) => void,
  isOnline: boolean,
) {
  const persistUser = (user: Staff) => {
    LocalStorageSvc.save<string>(JWT_KEY, user.jwtToken);
    LocalStorageSvc.save<Staff>(USER_DATA_KEY, user);
    setCurrentUser(user);
  };
  async function login(name: string, password: string): Promise<Staff | null> {
    if (!isOnline) return null;
    try {
      const results: ServerResponse<Staff> = await postRequest(
        `${StaffAuthEndpoint}/login`,
        {
          user: name,
          password: password,
        },
      );
      if (results.status === 200 && results.data) {
        persistUser(results.data);
      }
      return results.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async function refreshToken() {
    if (!currentUser || !isOnline) return null;
    try {
      const results = await postRequest(`${StaffAuthEndpoint}/refresh-token`, {
        email: currentUser.email,
      });

      if (results.status === 200 && results.data) {
        persistUser(results.data);
      } else {
        LocalStorageSvc.remove(JWT_KEY);
        LocalStorageSvc.remove(USER_DATA_KEY);
      }

      return results;
    } catch (error) {
      console.error(error);
    }

    //can't refresh the token without the internet. Assume good.
    return { status: 200 };
  }

  return { login, refreshToken, persistUser };
}
