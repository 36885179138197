import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { endOfWeek } from 'date-fns/endOfWeek';
import { startOfWeek } from 'date-fns/startOfWeek';
import React, { useEffect, useState } from 'react';
import { Bar, Cell, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import reportsApi from '../../../../api/report';
import { ReportView } from '../../../../common/enums.tsx';
import { RevenueCostProfitType } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import useAlert from '../../../../hooks/useAlert';
import ReportHeader from './ReportHeader.tsx';
import StyledBarChartWrapper from './StyledBarChartWrapper.tsx';
import StyledTableContainer from './StyledTableContainer.tsx';

export interface RevenueCostProfitChartData {
  name: string;
  value: number;
}

export default function RevenueCostProfitReport() {
  const [reportData, setReportData] = useState<RevenueCostProfitType>();
  const [startDate, setStartDate] = useState<Date | null>(() =>
    startOfWeek(new Date(), { weekStartsOn: 0 }),
  );
  const [endDate, setEndDate] = useState<Date | null>(() =>
    endOfWeek(new Date(), { weekStartsOn: 0 }),
  );
  const [view, setView] = useState<ReportView.Chart | ReportView.Table>(
    ReportView.Chart,
  );
  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function fetchReportData() {
    setIsLoading(true);
    const result = await reportsApi.getRevenueCostProfit(startDate, endDate);
    if (result.data && result.status === 200) {
      setReportData(result.data);
    } else {
      showAlert('Failed to get report, please refresh and try again.', 'error');
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchReportData();
  }, []);

  function handleViewChange(
    _: React.MouseEvent<HTMLElement>,
    newView: ReportView.Chart | ReportView.Table | null,
  ) {
    if (newView !== null) {
      setView(newView);
    }
  }

  function getBarColor(entry: { name: string; value: number }) {
    switch (entry.name) {
      case 'Revenue':
        return '#8884d8';
      case 'Cost':
        return '#ff8042';
      case 'Profit':
        return '#82ca9d';
      default:
        return '#000000';
    }
  }

  const chartData: RevenueCostProfitChartData[] = reportData
    ? [
        { name: 'Revenue', value: reportData.totalRevenue },
        { name: 'Cost', value: -reportData.estimatedCost },
        { name: 'Profit', value: reportData.profit },
      ]
    : [
        { name: 'Revenue', value: 0 },
        { name: 'Cost', value: 0 },
        { name: 'Profit', value: 0 },
      ];

  return (
    <>
      <ReportHeader
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        view={view}
        handleViewChange={handleViewChange}
        fetchData={fetchReportData}
      />
      <LoadingSpinner isLoading={isLoading}>
        <>
          {reportData && (
            <>
              {view === ReportView.Chart ? (
                <StyledBarChartWrapper data={chartData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip
                    formatter={(value) =>
                      `$${Math.abs(Number(value)).toFixed(2)}`
                    }
                    labelFormatter={(label) => `${label}`}
                  />
                  <ReferenceLine y={0} stroke="#000" />
                  <Bar dataKey="value">
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getBarColor(entry)} />
                    ))}
                  </Bar>
                </StyledBarChartWrapper>
              ) : (
                <StyledTableContainer>
                  <Table size="medium" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Metric</TableCell>
                        <TableCell align="right">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Total Revenue</TableCell>
                        <TableCell align="right">
                          ${reportData.totalRevenue.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Estimated Cost</TableCell>
                        <TableCell align="right">
                          ${reportData.estimatedCost.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Profit</TableCell>
                        <TableCell align="right">
                          ${reportData.profit.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Order Count</TableCell>
                        <TableCell align="right">
                          {reportData.orderCount}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
            </>
          )}
        </>
      </LoadingSpinner>
    </>
  );
}
