import AcUnitIcon from '@mui/icons-material/AcUnit';
import CoffeeIcon from '@mui/icons-material/Coffee';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { ProductCategoryType } from '../../common/enums';

type ProductCategoryTypeIconProps = {
  category: ProductCategoryType | string | undefined;
} & SvgIconProps;

export default function ProductCategoryTypeIcon({
  category,
  ...rest
}: ProductCategoryTypeIconProps) {
  switch (category) {
    case ProductCategoryType.HotCoffee:
      return <CoffeeIcon {...rest} />;
    case ProductCategoryType.ColdDrinks:
      return <AcUnitIcon {...rest} />;
    case ProductCategoryType.Tea:
      return <EmojiFoodBeverageIcon {...rest} />;
    case ProductCategoryType.Food:
      return <RestaurantIcon {...rest} />;
    default:
      return null;
  }
}
