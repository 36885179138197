import PersonIcon from '@mui/icons-material/Person';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import staffApi from '../../../api/staff';
import { hasAdminRole } from '../../../common/roleFunctions';
import { isStaff } from '../../../common/typeAssertionFunctions';
import { StaffAccount, TPGEMediaQuery } from '../../../common/types';
import useStaff from '../../../datastore/useStaff';
import useAlert from '../../../hooks/useAlert';
import useAPI from '../../../hooks/useAPI';
import useTPGEMediaQuery from '../../../hooks/useTPGEMediaQuery';
import { AdminHeader } from '../components/AdminHeader';
import AdminPagination from '../components/AdminPagination';
import AccountManagementFilter from './components/AccountManagementFilter';
import AccountManagementTable from './components/AccountManagementTable';
import EditAccountModal from './components/EditAccountModal';
import NewAccountModal from './components/NewAccountModal';

const AccountsPerPage: { [key in TPGEMediaQuery]: number } = {
  xlarge: 12,
  large: 8,
  medium: 5,
  small: 4,
};

export default function AccountManagementPage() {
  const { currentUser } = useAPI();
  const { showAlert } = useAlert();
  const mediaSize = useTPGEMediaQuery();
  const accountsPerPage = AccountsPerPage[mediaSize];
  const [filterText, setFilterText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [editAccount, setEditAccount] = useState<StaffAccount | null>(null);
  const [newAccount, setNewAccount] = useState<Partial<StaffAccount> | null>(
    null,
  );

  const {
    showDisabled: showDisabledAccounts,
    setShowDisabled: setShowDisabledAccounts,
    staff: accountsData,
    loading: isLoading,
    error,
    fetchStaff: refetchAccounts,
  } = useStaff();

  if (error) {
    showAlert('Error retrieving accounts, refresh and try again.', 'error');
    return null;
  }

  if (!hasAdminRole(currentUser) || !isStaff(currentUser)) return null;

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async function statusToggle(account: StaffAccount) {
    const updatedAccount = { ...account, isActive: !account.isActive };
    const result = await staffApi.updateStaff(updatedAccount);
    if (result.status !== 200) {
      showAlert(
        'Error updating account status, refresh and try again.',
        'error',
      );
      return;
    }
    refetchAccounts();
    showAlert('Account updated successfully', 'success');
  }

  async function handleSaveEditAccount(editedAccount: StaffAccount) {
    const result = await staffApi.updateStaff(editedAccount);
    if (result.status !== 200) {
      showAlert('Error updating account, refresh and try again.', 'error');
      return;
    }
    refetchAccounts();
    showAlert('Account updated successfully', 'success');
  }

  async function handleSaveNewAccount(newAccount: StaffAccount) {
    const result = await staffApi.createStaff(newAccount);
    if (result.status !== 201 || !result.data) {
      showAlert('Error creating account, refresh and try again.', 'error');
      return;
    }
    refetchAccounts();
    showAlert('Account created successfully', 'success');
  }

  const activeAccounts = showDisabledAccounts
    ? accountsData
    : accountsData?.filter((account) => account.isActive);

  const filteredAccounts =
    activeAccounts?.filter((account) =>
      filterText
        ? account.email.toLowerCase().includes(filterText.toLowerCase())
        : true,
    ) || [];

  const totalNumberOfPages = Math.ceil(
    filteredAccounts.length / accountsPerPage,
  );

  const paginatedAccounts = filteredAccounts.slice(
    (currentPage - 1) * accountsPerPage,
    currentPage * accountsPerPage,
  );

  const defaultAccount: Partial<StaffAccount> = {
    email: '',
    isActive: true,
  };

  return (
    <>
      <AdminHeader
        title="Accounts"
        description="View & Edit Staff and Admin Accounts"
        icon={<PersonIcon fontSize="large" sx={{ color: 'white' }} />}
        buttonText="New Account"
        buttonAction={() => setNewAccount(defaultAccount)}
      />
      <Stack sx={{ marginX: 2, marginY: 1 }}>
        <AccountManagementFilter
          filterText={filterText}
          setFilterText={setFilterText}
          showDisabledAccounts={showDisabledAccounts}
          setShowDisabledAccounts={setShowDisabledAccounts}
        />
        <AccountManagementTable
          isLoading={isLoading}
          paginatedAccounts={paginatedAccounts}
          statusToggle={statusToggle}
          editAccount={(account) => setEditAccount(account)}
        />
        <AdminPagination
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Stack>
      {editAccount && (
        <EditAccountModal
          open={!!editAccount}
          onClose={() => setEditAccount(null)}
          onSave={handleSaveEditAccount}
          account={editAccount}
        />
      )}
      {newAccount && (
        <NewAccountModal
          open={!!newAccount}
          onClose={() => setNewAccount(null)}
          onSave={handleSaveNewAccount}
        />
      )}
    </>
  );
}
