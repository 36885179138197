import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useState } from 'react';
import productsApi from '../../../api/product';
import { hasAdminRole } from '../../../common/roleFunctions.tsx';
import { isStaff } from '../../../common/typeAssertionFunctions.tsx';
import {
  MutateProduct,
  Product,
  Size,
  TPGEMediaQuery,
} from '../../../common/types.tsx';
import useProductCategories from '../../../datastore/useProductCategories.tsx';
import useProducts from '../../../datastore/useProducts.tsx';
import useSizes from '../../../datastore/useSizes.tsx';
import useAlert from '../../../hooks/useAlert';
import useAPI from '../../../hooks/useAPI';
import useTPGEMediaQuery from '../../../hooks/useTPGEMediaQuery';
import { AdminHeader } from '../components/AdminHeader.tsx';
import AdminPageContent from '../components/AdminPageContent.tsx';
import AdminPagination from '../components/AdminPagination.tsx';
import { ProductFilter } from './components/ProductFilter.tsx';
import ProductModal from './components/ProductModal.tsx';
import { ProductTable } from './components/ProductTable.tsx';
import { mapProductToMutateProduct } from './functions/productFunctions.ts';

const ProductsPerPage: { [key in TPGEMediaQuery]: number } = {
  xlarge: 9,
  large: 7,
  medium: 5,
  small: 4,
};

const newMutateProduct = (sizes: Size[] = [], siteId = 1): MutateProduct => ({
  id: 0,
  description: '',
  name: '',
  isActive: true,
  productCategoryId: 0,
  siteId,
  productSizes: sizes
    .sort((a, b) => b.name.localeCompare(a.name))
    .map((m) => ({
      id: 0,
      sizeId: m.id,
      basePrice: 0,
      isActive: true,
      isDefault: m.isDefault,
      sizeName: m.name,
    })),
});

const filterProducts = (
  products: Product[],
  nameFilter: string,
  showDisabled: boolean,
  categoryId: number,
): Product[] => {
  const lowerNameFilter = nameFilter?.toLowerCase().trim() ?? '';
  return products.filter(
    (product) =>
      (showDisabled || product.isActive) &&
      (lowerNameFilter?.length > 0
        ? product.name.toLowerCase().includes(lowerNameFilter)
        : true) &&
      (categoryId > 0 ? product.productCategoryId === categoryId : true),
  );
};

export default function ProductsPage() {
  const { currentUser } = useAPI();
  const {
    products = [],
    loading: productsLoading,
    fetchProducts: refreshProducts,
    showDisabled,
    setShowDisabled,
  } = useProducts();
  const { sizes = [], loading: sizesLoading } = useSizes();
  const { productCategories, loading: productCategoriesLoading } =
    useProductCategories();
  const { showAlert } = useAlert();
  const mediaSize = useTPGEMediaQuery();
  const productsPerPage = ProductsPerPage[mediaSize];
  const [isMutating, setIsMutating] = useState(false);
  const [filterText, setFilterText] = useState<string>('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [mutateProductId, setMutateProductId] = useState<number | null>(null);

  const productToMutate: Product | undefined = products?.find(
    (f) => f.id === mutateProductId,
  );
  const mutateProduct: MutateProduct = productToMutate
    ? mapProductToMutateProduct(productToMutate, sizes)
    : newMutateProduct(sizes, 1);

  const isLoading =
    isMutating || productsLoading || sizesLoading || productCategoriesLoading;

  if (!hasAdminRole(currentUser) || !isStaff(currentUser)) return null;

  async function handleMutate(mutateProduct: MutateProduct) {
    const isUpdate = mutateProduct.id > 0;
    const mutateFn = isUpdate
      ? productsApi.updateProduct
      : productsApi.createProduct;
    const successText = `Product ${isUpdate ? 'updated' : 'created'} successfully`;
    const errorText = `Product ${isUpdate ? 'update' : 'creation'} failed`;
    try {
      setIsMutating(true);
      await mutateFn(mutateProduct);
      refreshProducts();
      showAlert(successText, 'success');
      setMutateProductId(null);
    } catch (err) {
      showAlert(errorText, 'error');
      console.error(err);
    } finally {
      setIsMutating(false);
    }
  }

  async function statusToggle(product: Product) {
    const updatedProduct = { ...product, isActive: !product.isActive };
    await handleMutate(mapProductToMutateProduct(updatedProduct, sizes));
  }

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const filteredProducts = filterProducts(
    products || [],
    filterText || '',
    showDisabled,
    selectedCategoryId,
  );

  const totalNumberOfPages = Math.ceil(
    filteredProducts.length / productsPerPage,
  );

  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage,
  );

  return (
    <>
      <AdminHeader
        title="Products"
        description="Manage your products, click a product to manage its variations."
        icon={<ShoppingCartIcon fontSize="large" sx={{ color: 'white' }} />}
        buttonText="New Product"
        buttonAction={() => setMutateProductId(0)}
      />
      <AdminPageContent>
        <ProductFilter
          filterText={filterText}
          setFilterText={setFilterText}
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={(categoryId) => {
            setSelectedCategoryId(categoryId);
            setCurrentPage(1);
          }}
          showDisabled={showDisabled}
          toggleShowDisabled={() => setShowDisabled(!showDisabled)}
          productCategories={productCategories || []}
        />
        <ProductTable
          isLoading={isLoading}
          paginatedProducts={paginatedProducts}
          statusToggle={statusToggle}
          editProduct={(product) => setMutateProductId(product.id)}
        />
        <AdminPagination
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </AdminPageContent>
      {mutateProductId !== null && (
        <ProductModal
          onClose={() => setMutateProductId(null)}
          onSave={handleMutate}
          product={mutateProduct}
          isLoading={isMutating}
          productCategories={productCategories || []}
          sizes={sizes || []}
        />
      )}
    </>
  );
}
