import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import { calculateProductInternalCost } from '../../../../common/moneyFunctions.tsx';
import { Product } from '../../../../common/types';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import SizePricesTableCell from '../../components/SizePricesTableCell.tsx';

interface ProductTableProps {
  isLoading: boolean;
  paginatedProducts: Product[];
  editProduct: (product: Product) => void;
  statusToggle: (product: Product) => void;
}

export function ProductTable({
  isLoading,
  paginatedProducts,
  editProduct,
  statusToggle,
}: ProductTableProps) {
  const navigate = useNavigate();

  const handleRowClick = (product: Product) => {
    navigate(`/admin/products/${product.id}`, {
      state: {
        product: product,
      },
    });
  };

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    callback: () => void,
  ) => {
    e.stopPropagation();
    callback();
  };

  return (
    <Table sx={{ maxWidth: '100%', overflowX: 'auto' }} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Base Price</TableCell>
          <TableCell>Internal Cost</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <LoadingSpinner isLoading={isLoading} isTable colSpan={6}>
          {paginatedProducts.map((product) => {
            const internalPrices = product.productSizes
              .filter((f) => f.isActive)
              .map((m) => ({
                sizeName: m.sizeName,
                cost: calculateProductInternalCost(product, m.id).toNumber(),
              }));
            return (
              <TableRow
                key={product.id}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    cursor: 'pointer',
                  },
                }}
                onClick={() => handleRowClick(product)}
              >
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>{product.productCategory.name}</TableCell>
                <SizePricesTableCell
                  sizePrices={product.productSizes
                    .filter((f) => f.isActive)
                    .map((m) => ({ sizeName: m.sizeName, cost: m.basePrice }))}
                />
                <SizePricesTableCell sizePrices={internalPrices} />
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ marginRight: 2 }}
                    onClick={(e) =>
                      handleButtonClick(e, () => editProduct(product))
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={(e) =>
                      handleButtonClick(e, () => statusToggle(product))
                    }
                    color={product.isActive ? 'error' : 'primary'}
                  >
                    {product.isActive ? 'Disable' : 'Enable'}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </LoadingSpinner>
      </TableBody>
    </Table>
  );
}
