import { ProductCategory, ServerResponse } from '../common/types';
import { getRequest } from './requests';

const baseUrl = 'productcategories';

const listProductCategories = async (): Promise<
  ServerResponse<ProductCategory[]>
> => {
  const result: ServerResponse<ProductCategory[]> = await getRequest(baseUrl);
  return result;
};

export default { listProductCategories };
