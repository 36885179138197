import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { Role } from '../../../../common/enums';
import { StaffAccount } from '../../../../common/types';
import AdminDialogTitle from '../../components/AdminDialogTitle';
import {
  formatRoleDisplayName,
  validatePassword,
} from '../functions/helperFunctions';

type EditAccountDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (updatedAccount: StaffAccount & { newPassword?: string }) => void;
  account: StaffAccount;
};

export default function EditAccountModal({
  open,
  onClose,
  onSave,
  account,
}: EditAccountDialogProps) {
  const { control, handleSubmit, watch } = useForm<
    StaffAccount & { newPassword?: string; confirmNewPassword?: string }
  >({
    defaultValues: { ...account, newPassword: '', confirmNewPassword: '' },
  });

  const staffRoles: Role[] = Object.values(Role).filter(
    (role) => role !== Role.Customer,
  );

  const onSubmit = (
    data: StaffAccount & { newPassword?: string; confirmNewPassword?: string },
  ) => {
    const updatedData = { ...data };
    delete updatedData.confirmNewPassword;
    if (!updatedData.newPassword) {
      delete updatedData.newPassword;
    }
    onSave(updatedData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <AdminDialogTitle>Edit Account</AdminDialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="roleName"
            control={control}
            rules={{ required: 'Role is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                select
                margin="dense"
                label="Role"
                fullWidth
                error={!!error}
                helperText={error?.message}
              >
                {staffRoles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {formatRoleDisplayName(role)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <>
            <Controller
              name="newPassword"
              control={control}
              rules={{
                validate: (value) => validatePassword(value, true),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="New Password (leave blank to keep current)"
                  type="newPassword"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="confirmNewPassword"
              control={control}
              rules={{
                validate: (value) =>
                  !watch('newPassword') ||
                  value === watch('newPassword') ||
                  'Passwords do not match',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="Confirm New Password"
                  type="newPassword"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
