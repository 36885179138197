import { create } from 'zustand';
import { AlertColor } from '@mui/material/Alert';

interface Alert {
  message: string;
  severity: AlertColor;
  duration?: number;
}

interface AlertState {
  alert: Alert | null;
  showAlert: (message: string, severity: AlertColor, duration?: number) => void;
  clearAlert: () => void;
}

const useAlert = create<AlertState>((set) => ({
  alert: null,
  showAlert: (message: string, severity: AlertColor, duration?: number) =>
    set(() => ({ alert: { message, severity, duration: duration ?? 3000 } })),
  clearAlert: () => set(() => ({ alert: null })),
}));

export default useAlert;
