import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:disabled': {
    backgroundColor: theme.palette.common.grey,
    color: theme.palette.primary.main,
  },
}));

export default StyledButton;
