import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import SideNavbar from '../../../components/navbar/SideNavbar';
import StaffLoginProvider from '../../../contexts/StaffLoginContext';

export default function StaffLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useTheme();

  return (
    <StaffLoginProvider>
      <Stack flexDirection="row">
        <SideNavbar />
        <Box
          sx={{
            flexGrow: 1,
            borderLeft: `1px solid ${theme.palette.common.black}`,
          }}
        >
          {children}
        </Box>
      </Stack>
    </StaffLoginProvider>
  );
}
