import { CustomerEndpoint } from '../common/endpoints';
import { CustomerInfo, ServerResponse } from '../common/types';
import { getRequest } from './requests';

export async function getAll() {
  const result: ServerResponse<CustomerInfo[]> =
    await getRequest(CustomerEndpoint);
  return result;
}

export default { getAll };
