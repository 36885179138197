import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { PopularProduct, Product } from '../../../../common/types';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';

interface ProductTableProps {
  isLoading: boolean;
  paginatedProducts: Product[];
  popularProductsData: PopularProduct[] | undefined;
  showAllProducts: boolean;
  handleOrderChange: (productId: number, newOrder: number) => void;
  addProduct: (product: Product) => void;
  removePopularProduct: (product: Product) => void;
}

export function PopularProductTable({
  isLoading,
  paginatedProducts,
  popularProductsData,
  showAllProducts,
  handleOrderChange,
  addProduct,
  removePopularProduct,
}: ProductTableProps) {
  return (
    <Table sx={{ maxWidth: '100%', overflowX: 'auto' }} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Favourite</TableCell>
          <TableCell>Display Order</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <LoadingSpinner isLoading={isLoading} isTable colSpan={6}>
          {paginatedProducts.map((product) => {
            const popularProduct = popularProductsData?.find(
              (p) => p.productId === product.id && p.isActive,
            );
            const isPopularProduct = !!popularProduct;
            const showDisplayOrderDropdown = isPopularProduct;
            if (!showAllProducts && !isPopularProduct) return null;
            return (
              <TableRow key={product.id}>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>{product.productCategory.name}</TableCell>
                <TableCell>{isPopularProduct ? 'Yes' : 'No'}</TableCell>
                <TableCell sx={{ paddingY: 0.5 }}>
                  {showDisplayOrderDropdown && (
                    <TextField
                      select
                      size="small"
                      value={popularProduct.displayOrder}
                      onChange={(e) =>
                        handleOrderChange(product.id, Number(e.target.value))
                      }
                      variant="outlined"
                      sx={{ width: '80px' }}
                    >
                      {Array.from(
                        {
                          length:
                            popularProductsData?.filter((p) => p.isActive)
                              .length || 0,
                        },
                        (_, i) => i + 1,
                      ).map((num) => (
                        <MenuItem key={num} value={num}>
                          {num}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => addProduct(product)}
                    disabled={isPopularProduct}
                    sx={{ marginRight: 2 }}
                  >
                    Add
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={() => removePopularProduct(product)}
                    disabled={!isPopularProduct}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </LoadingSpinner>
      </TableBody>
    </Table>
  );
}
