import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { PaymentType } from '../../../../common/enums';

type PaymentModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (paymentType: PaymentType) => void;
  title: string;
  content: string;
};

export default function PaymentModal({
  open,
  onClose,
  onConfirm,
  title,
  content,
}: PaymentModalProps) {
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.Cash);

  const handleConfirm = () => {
    onConfirm(paymentType);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ paddingBottom: '0.5em' }}
        >
          {title}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography sx={{ paddingBottom: '1em' }} variant="body2">
            {content}
          </Typography>
        </DialogContentText>
        <FormControl fullWidth sx={{ marginTop: '1em' }}>
          <InputLabel id="payment-type-label">Payment Type</InputLabel>
          <Select
            labelId="payment-type-label"
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value as PaymentType)}
            label="Payment Type"
          >
            <MenuItem value={PaymentType.Cash}>{PaymentType.Cash}</MenuItem>
            <MenuItem value={PaymentType.Card}>{PaymentType.Card}</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
