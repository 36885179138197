import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { create } from 'zustand';
import { postRequest } from '../api/requests';
import {
  CustomerAuthEndpoint,
  CustomerProfileEndpoint,
} from '../common/endpoints';
import LocalStorageSvc, {
  JWT_KEY,
  USER_DATA_KEY,
} from '../common/localStorageSvc';
import { Customer, ServerResponse } from '../common/types';
import useIsOnline from './useIsOnline';

export type CustomerAccountMode = 'login' | 'register';

let customerRefreshTimeout: NodeJS.Timeout | null = null;

const cancelRefreshTimeout = () => {
  if (customerRefreshTimeout) {
    clearTimeout(customerRefreshTimeout);
    console.info('useCustomerAuth.cancelRefreshTimeout.timeout removed');
  }
};

const startRefreshTimeout = (refreshFn: () => void) => {
  cancelRefreshTimeout();
  customerRefreshTimeout = setTimeout(refreshFn, 900000);
  console.info('useCustomerAuth.startRefreshTimeout.timeout added');
};

const initCustomer = () => {
  const existingCustomer = LocalStorageSvc.load<Customer>(USER_DATA_KEY);
  return existingCustomer || null;
};

const storeCustomer = (customer: Customer): void => {
  LocalStorageSvc.save<Customer>(USER_DATA_KEY, customer);
  LocalStorageSvc.save<string>(JWT_KEY, customer.jwtToken);
};

const clearCustomer = (): void => {
  LocalStorageSvc.remove(USER_DATA_KEY);
  LocalStorageSvc.remove(JWT_KEY);
};

interface CustomerAuthState {
  currentCustomer: Customer | null;
  setCurrentCustomer: (customer: Customer | null) => void;
  showAccountModal: CustomerAccountMode | null;
  setShowAccountModal: (show: CustomerAccountMode | null) => void;
}

const initialCustomer = initCustomer();

const useCustomerAuthState = create<CustomerAuthState>((set) => ({
  currentCustomer: initialCustomer,
  setCurrentCustomer: (customer: Customer | null): void =>
    set({ currentCustomer: customer }),
  showAccountModal: null,
  setShowAccountModal: (show: CustomerAccountMode | null): void =>
    set({ showAccountModal: show }),
}));

interface CustomerAuthData {
  logout: () => void;
  login: (name: string, password: string) => Promise<Customer | null>;
  register: (
    email: string,
    password: string,
    name: string,
    phone: string,
  ) => Promise<Customer | null>;
  updateProfile: (name: string, phone: string) => Promise<Customer | null>;
}

type CustomerAuthType = CustomerAuthData & CustomerAuthState;

const useCustomerAuth = (): CustomerAuthType => {
  const { isOnline } = useIsOnline();
  const {
    currentCustomer,
    showAccountModal,
    setCurrentCustomer,
    setShowAccountModal,
  } = useCustomerAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentCustomer) {
      console.info('useCustomerAuth.useEffect.startRefreshTimeout');
      startRefreshTimeout(refreshToken);
    }
  }, [currentCustomer]);

  const logout = () => {
    console.info('useCustomerAuth.logout');
    LocalStorageSvc.remove(USER_DATA_KEY);
    LocalStorageSvc.remove(JWT_KEY);
    setCurrentCustomer(null);
    cancelRefreshTimeout();
    navigate('/');
  };

  const refreshToken = async (): Promise<
    string | { status: number } | null
  > => {
    if (!currentCustomer || !isOnline) return null;
    try {
      const results: ServerResponse<string> = await postRequest(
        `${CustomerAuthEndpoint}/refresh-token`,
        {
          email: currentCustomer.email,
        },
      );

      if (results.status === 200 && results.data) {
        const clone = structuredClone(currentCustomer);
        clone.jwtToken = results.data;
        setCurrentCustomer(clone);
        storeCustomer(clone);
      } else {
        clearCustomer();
      }

      return results || ('' as string);
    } catch (error) {
      console.error(error);
    }

    //can't refresh the token without the internet. Assume good.
    return { status: 200 };
  };

  const login = async (
    name: string,
    password: string,
  ): Promise<Customer | null> => {
    if (!isOnline) return null;
    try {
      const results: ServerResponse<Customer> = await postRequest(
        `${CustomerAuthEndpoint}/login`,
        {
          user: name,
          password: password,
        },
      );
      if (results.status === 200 && results.data) {
        const clone: Customer = { ...results.data };
        storeCustomer(clone);
        setCurrentCustomer(clone);
        startRefreshTimeout(refreshToken);
        return clone;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const register = async (
    email: string,
    password: string,
    name: string,
    phone: string,
  ): Promise<Customer | null> => {
    if (!isOnline) return null;
    try {
      const results: ServerResponse<Customer> = await postRequest(
        `${CustomerAuthEndpoint}/register`,
        {
          email,
          password,
          name,
          phone,
        },
      );

      if (results.status === 200 && results.data) {
        const clone = { ...results.data };
        storeCustomer(clone);
        setCurrentCustomer(clone);
        startRefreshTimeout(refreshToken);
        return clone;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const updateProfile = async (
    name: string,
    phone: string,
  ): Promise<Customer | null> => {
    if (!useIsOnline.getState().isOnline) return null;
    try {
      const results: ServerResponse<Customer> = await postRequest(
        `${CustomerProfileEndpoint}/update-profile`,
        {
          name,
          phone,
        },
      );
      if (results.status === 200) {
        const clone = results.data as Customer;
        storeCustomer(clone);
        setCurrentCustomer(clone);
        startRefreshTimeout(refreshToken);
        return clone;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return {
    logout,
    login,
    register,
    updateProfile,
    currentCustomer,
    showAccountModal,
    setCurrentCustomer,
    setShowAccountModal,
  };
};

export default useCustomerAuth;
