import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AdminPageFilter from '../../components/AdminPageFilter';

interface FilterSectionProps {
  filterText: string;
  setFilterText: (value: string) => void;
  showDisabled: boolean;
  setShowDisabled: (value: boolean) => void;
}

export function ComponentTypesFilter({
  filterText,
  setFilterText,
  showDisabled,
  setShowDisabled,
}: FilterSectionProps) {
  return (
    <AdminPageFilter filterText={filterText} setFilterText={setFilterText}>
      <FormControlLabel
        control={
          <Switch
            checked={showDisabled}
            onChange={(e) => setShowDisabled(e.target.checked)}
          />
        }
        label="Show disabled component types"
      />
    </AdminPageFilter>
  );
}
