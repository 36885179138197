import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { getPickupTimeColor } from '../functions/stationFunctions';
import { formatTime } from '../../../common/timeFunctions';

interface TimeIndicatorProps {
  time: Date | null;
}

export function TimeIndicator({ time }: TimeIndicatorProps) {
  if (!time) return;
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        padding: 1,
      }}
    >
      <AccessTimeRoundedIcon
        sx={{
          color: getPickupTimeColor(time),
        }}
      />
      <Typography variant="body1" sx={{ paddingLeft: 1, paddingTop: 0.25 }}>
        {formatTime(new Date(time))}
      </Typography>
    </Stack>
  );
}
