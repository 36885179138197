import { useEffect, useState } from 'react';
import { ServerResponse } from '../common/types';

type UseRequestReturnType<T> = {
  data: T | null;
  loading: boolean;
  error: Error | null;
  execute: () => void;
};

export default function useRequest<T>(
  apiCall: () => Promise<ServerResponse<T>>,
  dependencies: unknown[] = [],
  delayExecution = false,
): UseRequestReturnType<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const execute = async () => {
    try {
      setError(null);
      setLoading(true);

      const res: ServerResponse<T> = await apiCall();
      if (res.status === 200 && (res?.data as ServerResponse<T>)) {
        setData(res.data);
      } else {
        console.error(res);
        setError(new Error('request failed'));
      }
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!delayExecution) {
      execute();
    }
  }, [delayExecution, ...dependencies]);

  return {
    data,
    loading,
    error,
    execute,
  };
}
