import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { endOfWeek } from 'date-fns/endOfWeek';
import { startOfWeek } from 'date-fns/startOfWeek';
import { useEffect, useState } from 'react';
import { Bar, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import reportsApi from '../../../../api/report';
import { allOption } from '../../../../common/constants.tsx';
import { ReportView } from '../../../../common/enums.tsx';
import { truncateString } from '../../../../common/stringFunctions.tsx';
import { SalesByProductCategory } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import useAlert from '../../../../hooks/useAlert';
import { getColor } from '../functions/getColor.ts';
import CustomTooltip from './CustomTooltip.tsx';
import ReportHeader from './ReportHeader.tsx';
import StyledBarChartWrapper from './StyledBarChartWrapper.tsx';
import StyledTableContainer from './StyledTableContainer.tsx';

export interface TransformedDataItem {
  name: string;
  fullName: string;
  [categoryName: string]: string | number;
}

export default function ProductsSoldReport() {
  const { showAlert } = useAlert();
  const [productData, setProductData] = useState<SalesByProductCategory[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(() =>
    startOfWeek(new Date(), { weekStartsOn: 0 }),
  );
  const [endDate, setEndDate] = useState<Date | null>(() =>
    endOfWeek(new Date(), { weekStartsOn: 0 }),
  );
  const [view, setView] = useState<ReportView.Chart | ReportView.Table>(
    ReportView.Chart,
  );
  const [selectedCategory, setSelectedCategory] = useState<string>(allOption);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchProductData() {
    setIsLoading(true);
    const result = await reportsApi.getProductSales(startDate, endDate);
    if (result.data && result.status === 200) {
      setProductData(result.data);
    } else {
      showAlert('Failed to get report, please refresh and try again.', 'error');
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchProductData();
  }, []);

  function transformData(
    data: SalesByProductCategory[],
  ): TransformedDataItem[] {
    const transformedData: Record<string, TransformedDataItem> = {};

    data.forEach((category) => {
      if (
        selectedCategory === allOption ||
        selectedCategory === category.categoryName
      ) {
        category.products.forEach((product) => {
          const nameLengthLimit = 15;
          const truncatedName = truncateString(
            product.productName,
            nameLengthLimit,
          );
          if (!transformedData[truncatedName]) {
            transformedData[truncatedName] = {
              name: truncatedName,
              fullName: product.productName,
            };
          }
          transformedData[truncatedName][category.categoryName] =
            product.quantity;
        });
      }
    });

    return Object.values(transformedData);
  }

  function handleViewChange(
    _: React.MouseEvent<HTMLElement>,
    newView: ReportView.Chart | ReportView.Table | null,
  ) {
    if (newView !== null) {
      setView(newView);
    }
  }

  function handleCategoryChange(event: SelectChangeEvent<string>) {
    setSelectedCategory(event.target.value);
  }

  const chartData = transformData(productData);

  return (
    <>
      <ReportHeader
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        view={view}
        handleViewChange={handleViewChange}
        fetchData={fetchProductData}
      >
        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            value={selectedCategory}
            onChange={handleCategoryChange}
            label="Category"
          >
            <MenuItem value="All">All Categories</MenuItem>
            {productData.map((category) => (
              <MenuItem
                key={category.categoryName}
                value={category.categoryName}
              >
                {category.categoryName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ReportHeader>
      <LoadingSpinner isLoading={isLoading}>
        <>
          {view === ReportView.Chart ? (
            <StyledBarChartWrapper data={chartData}>
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                height={70}
                interval={0}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" height={36} />
              {productData.map((category, index) => (
                <Bar
                  key={category.categoryName}
                  dataKey={category.categoryName}
                  fill={getColor(index)}
                />
              ))}
            </StyledBarChartWrapper>
          ) : (
            <StyledTableContainer>
              <Table size="medium" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productData
                    .filter(
                      (category) =>
                        selectedCategory === allOption ||
                        selectedCategory === category.categoryName,
                    )
                    .flatMap((category) => category.products)
                    .sort((a, b) => b.quantity - a.quantity)
                    .map((product) => (
                      <TableRow key={product.productName}>
                        <TableCell component="th" scope="row">
                          {product.productName}
                        </TableCell>
                        <TableCell>{product.quantity}</TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Total
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {productData
                        .filter(
                          (category) =>
                            selectedCategory === allOption ||
                            selectedCategory === category.categoryName,
                        )
                        .reduce(
                          (total, category) =>
                            total +
                            category.products.reduce(
                              (sum, product) => sum + product.quantity,
                              0,
                            ),
                          0,
                        )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </>
      </LoadingSpinner>
    </>
  );
}
