import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface StyledSwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
  labelColor: string;
  disabled?: boolean;
}

export default function StyledSwitch({
  value,
  onChange,
  label,
  labelColor,
  disabled = false,
}: StyledSwitchProps) {
  const theme = useTheme();
  const colour = value
    ? theme.palette.primary.contrastText
    : theme.palette.secondary.contrastText;
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Switch
        checked={value}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
        sx={{
          padding: '0.2em',
          '& .MuiSwitch-track': {
            borderRadius: '8em',
            opacity: 1,
            backgroundColor: theme.palette.common.deepblue,
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: colour,
          },
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0,
              },
              '& .MuiSwitch-thumb': {
                backgroundColor: theme.palette.primary.contrastText,
              },
            },
          },
        }}
      />
      <Typography color={labelColor}>{label}</Typography>
    </Stack>
  );
}
