export const PasswordResetEndpoint = 'auth/password';
export const CustomerAuthEndpoint = 'customer/auth';
export const CustomerProfileEndpoint = 'customer/profile';
export const CustomerOrdersEndpoint = 'customer/orders';
export const CustomerEndpoint = 'customer';
export const StaffAuthEndpoint = 'staff/auth';
export const StaffEndpoint = 'staff';
export const TpgeComponentEndpoint = 'component';
export const TpgeComponentTypeEndpoint = 'componenttype';
export const OrderEndpoint = 'order';
export const PopularProductEndpoint = 'popularproduct';
export const SiteEndpoint = 'site';
export const ProductVariantEndpoint = 'productvariant';
export const ProductVariantTypesEndpoint = 'productvarianttypes';
export const ReportEndpoint = 'report';
export const StationEndpoint = 'station';
export const ProductEndpoint = 'product';
