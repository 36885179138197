import useLoadingModal from '../../hooks/useLoadingModal';
import LoadingModal from './LoadingModal';

export default function LoadingModalCoordinator() {
  const { loading } = useLoadingModal();

  if (!loading) {
    return null;
  }

  return <LoadingModal open={true} />;
}
