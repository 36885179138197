import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import icon from '../../../../assets/icon.jpg';
import ForgotPasswordLink from '../../../../components/account/ForgotPasswordLink';
import LoginForm from '../../../../components/account/LoginForm';

export interface StaffLoginProps {
  onLogin(form: { [key: string]: string }): Promise<void>;
  loading: boolean;
}

export default function StaffLoginCard({ onLogin, loading }: StaffLoginProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Stack spacing={2}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography variant="h2" color="primary" alignSelf="center">
          Log In
        </Typography>
        <Box sx={{ maxWidth: '10%' }}>
          <img src={icon} alt="Logo for TPGE" style={{ width: '100%' }} />
        </Box>
      </Stack>

      <LoginForm
        control={control}
        errors={errors}
        loading={loading}
        showForgotPassword={false}
      />

      <Stack spacing={1} sx={{ paddingTop: '0.5em' }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSubmit(onLogin)()}
          sx={{ padding: '1em 0' }}
          disabled={loading}
        >
          Log In
        </Button>

        <ForgotPasswordLink />
      </Stack>
    </Stack>
  );
}
