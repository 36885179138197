import Grid from '@mui/material/Grid2';
import { SxProps } from '@mui/system';

type UpperSectionProps = {
  sx?: SxProps;
  children?: string | JSX.Element | JSX.Element[];
};

export function PageSection({ children, sx }: UpperSectionProps) {
  return (
    <Grid container sx={sx} columns={12}>
      <Grid size={12}>{children}</Grid>
    </Grid>
  );
}
