import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Role } from '../../../../common/enums';
import { StaffAccount } from '../../../../common/types';
import {
  formatRoleDisplayName,
  validatePassword,
} from '../functions/helperFunctions';

type NewAccountDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (newAccount: StaffAccount & { newPassword: string }) => void;
};

type FormValues = StaffAccount & {
  newPassword: string;
  confirmPassword: string;
  confirmEmail: string;
};

export default function NewAccountModal({
  open,
  onClose,
  onSave,
}: NewAccountDialogProps) {
  const { control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      email: '',
      confirmEmail: '',
      roleName: Role.General,
      isActive: true,
      newPassword: '',
      confirmPassword: '',
    },
  });

  const newPassword = watch('newPassword');
  const email = watch('email');

  const staffRoles: Role[] = Object.values(Role).filter(
    (role) => role !== Role.Customer,
  );

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const newAccountData: StaffAccount & { newPassword: string } = { ...data };
    onSave(newAccountData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: 20, fontWeight: 'bold' }}>
        New Account
        <Divider />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="confirmEmail"
            control={control}
            rules={{
              required: 'Please confirm your email',
              validate: (value) => value === email || 'Emails do not match',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="dense"
                label="Confirm Email"
                type="email"
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="roleName"
            control={control}
            rules={{ required: 'Role is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                select
                margin="dense"
                label="Role"
                fullWidth
                error={!!error}
                helperText={error?.message}
              >
                {staffRoles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {formatRoleDisplayName(role)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters long',
              },
              validate: (value) => validatePassword(value),
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="dense"
                label="Password"
                type="newPassword"
                fullWidth
                error={!!error}
                helperText={
                  error?.message ||
                  'Must contain at least 8 characters, one capital letter, one number, and one symbol'
                }
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: 'Please confirm your password.',
              validate: (value) =>
                value === newPassword || 'Passwords do not match',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="dense"
                label="Confirm Password"
                type="newPassword"
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Create Account
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
