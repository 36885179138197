import { ReportEndpoint } from '../common/endpoints';
import {
  ComponentConsumption,
  RevenueCostProfitType,
  SaleByPaymentType,
  SalesByProductCategory,
  ServerResponse,
} from '../common/types';
import { getRequest } from './requests';

export async function getComponentConsumption(
  componentType: string,
  startDate: Date | null,
  endDate?: Date | null,
) {
  if (!componentType) {
    return { data: [], status: 200, statusText: 'OK' };
  }
  let url = `${ReportEndpoint}/componentconsumption/${componentType}`;
  const params = new URLSearchParams();
  if (startDate) params.append('startDate', startDate.toISOString());
  if (endDate) params.append('endDate', endDate.toISOString());
  if (params.toString()) url += `?${params.toString()}`;

  const result: ServerResponse<ComponentConsumption[]> = await getRequest(url);
  return result;
}

export async function getSalesByPaymentType(
  startDate: Date | null,
  endDate?: Date | null,
) {
  const startDateParameter = 'startDate';
  const endDateParameter = 'endDate';
  let url = `${ReportEndpoint}/sales-by-payment-type`;
  const params = new URLSearchParams();
  if (startDate) params.append(startDateParameter, startDate.toISOString());
  if (endDate) params.append(endDateParameter, endDate.toISOString());
  if (params.toString()) url += `?${params.toString()}`;

  const result: ServerResponse<SaleByPaymentType[]> = await getRequest(url);
  return result;
}

export async function getProductSales(
  startDate: Date | null,
  endDate?: Date | null,
) {
  const startDateParameter = 'startDate';
  const endDateParameter = 'endDate';
  let url = `${ReportEndpoint}/products-sold`;
  const params = new URLSearchParams();
  if (startDate) params.append(startDateParameter, startDate.toISOString());
  if (endDate) params.append(endDateParameter, endDate.toISOString());
  if (params.toString()) url += `?${params.toString()}`;

  const result: ServerResponse<SalesByProductCategory[]> =
    await getRequest(url);
  return result;
}

export async function getRevenueCostProfit(
  startDate: Date | null,
  endDate?: Date | null,
) {
  const startDateParameter = 'startDate';
  const endDateParameter = 'endDate';
  let url = `${ReportEndpoint}/revenue-cost-profit`;
  const params = new URLSearchParams();
  if (startDate) params.append(startDateParameter, startDate.toISOString());
  if (endDate) params.append(endDateParameter, endDate.toISOString());
  if (params.toString()) url += `?${params.toString()}`;

  const result: ServerResponse<RevenueCostProfitType> = await getRequest(url);
  return result;
}

export default {
  getComponentConsumption,
  getSalesByPaymentType,
  getRevenueCostProfit,
  getProductSales,
};
